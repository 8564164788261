import { Table, useMantineTheme } from "@mantine/core";
import { useEffect, useState } from "react";
import makeRequest from "../../common/makeRequest";
import Preloader from "../../components/common/Preloader";
import ms from "milsymbol";
import parse from "html-react-parser";
import styled from "@emotion/styled/macro";
import { Panel } from "../../AppPage";

const SymbolsPage: React.FC = () => {
	const [symbolsData, setSymbolsData] = useState<{ name: string; symbol: string }[]>();
	const theme = useMantineTheme();

	useEffect(() => {
		const downloadSymbols = async () => {
			const response = await makeRequest("get_systems_symbols", "GET");
			const data = await response.json();
			setSymbolsData(data.systems);
		};

		downloadSymbols();
	}, []);

	return symbolsData && symbolsData.length > 0 ? (
		<TablePanel>
			<StyledTable>
				<thead>
					<tr>
						<th>Name</th>
						<th>Symbol code</th>
						<th>Symbol</th>
					</tr>
				</thead>
				<tbody>
					{symbolsData.map((symbol) => {
						const symbolSvg = new ms.Symbol(symbol.symbol, {
							size: 30,
							colorMode: "Medium",
							iconColor: {
								Hostile: "red",
								Friend: theme.colorScheme === "dark" ? theme.colors.dark[2] : "black",
								Neutral: "yellow",
								Unknown: "gray",
								Civilian: "green",
							},
						}).asSVG();

						return (
							<tr key={symbol.name}>
								<td>{symbol.name}</td>
								<td>{symbol.symbol}</td>
								<td>{parse(symbolSvg)}</td>
							</tr>
						);
					})}
				</tbody>
			</StyledTable>
		</TablePanel>
	) : (
		<Preloader></Preloader>
	);
};

const StyledTable = styled(Table)`
	color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])};
	overflow: auto;

	& tr {
		th:last-of-type {
			text-align: center;
		}
		td:last-of-type {
			text-align: center;
		}
	}

	& th {
		color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2])} !important;
	}
`;

const TablePanel = styled(Panel)`
	overflow: auto;
`;

export default SymbolsPage;
