import * as Cesium from "cesium";

export const createAreas = (viewer: Cesium.Viewer, areasDataSource: Cesium.GeoJsonDataSource) => {
	const exists = viewer?.dataSources?.getByName("areas")[0];

	if (!exists) {
		areasDataSource.name = "areas";
		viewer.dataSources.add(areasDataSource);
		const entities = areasDataSource.entities.values as (Cesium.Entity & { type: string })[];

		entities.forEach((entity) => {
			var color = null;

			if (entity.properties) {
				const type = entity.properties.type._value;
				if (type.indexOf("Blue") !== -1) {
					color = new Cesium.ColorMaterialProperty(Cesium.Color.BLUE.withAlpha(0.4));
				} else if (type.indexOf("Red") !== -1) {
					color = new Cesium.ColorMaterialProperty(Cesium.Color.RED.withAlpha(0.4));
				}

				entity.type = "area";

				if (entity.polygon) {
					entity.polygon.material = color as Cesium.ColorMaterialProperty;
					entity.polygon.outlineColor = color as Cesium.ColorMaterialProperty;
				}
			}
		});
	}
};
