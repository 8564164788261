import { useContext } from "react";
import { AppInfoContext } from "../../../../../../components/contexts/AppInfoContext";
import { Tooltip } from "@mantine/core";
import { BehaviorParameter, WithRequiredProperty } from "../../../../../../types/filters";
import { prettifyPropertyName } from "../../../../../../common/prettifyPropertyName";

interface IInputTooltip {
	children: JSX.Element | JSX.Element[];
	disabled: boolean;
	elementOnWhichInputDepends?: WithRequiredProperty<BehaviorParameter, "property">;
}

const InputTooltip: React.FC<IInputTooltip> = ({ disabled, elementOnWhichInputDepends, children }) => {
	const { isSuperuser } = useContext(AppInfoContext);

	return (
		<Tooltip
			label={
				isSuperuser
					? `This input is disabled because it depends on ${
							(elementOnWhichInputDepends && prettifyPropertyName(elementOnWhichInputDepends.property)) || "another input"
					  }`
					: "You don't have rights to modify this setting"
			}
			disabled={disabled}
		>
			{children}
		</Tooltip>
	);
};

export default InputTooltip;
