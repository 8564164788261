//preloader component

import React from "react";
import styled from "@emotion/styled/macro";
import { useMantineTheme } from "@mantine/core";

interface IPreloader {
	additionalClasses?: string;
	customStyles?: string;
}

const Preloader: React.FC<IPreloader> = ({ customStyles, additionalClasses }) => {
	const theme = useMantineTheme();

	return (
		<PreloaderWrapper $customStyles={customStyles} $mainColor={theme.colors.appMainColor[4]} className={additionalClasses}>
			<span className="loader"></span>
		</PreloaderWrapper>
	);
};

const PreloaderWrapper = styled.div<{ $customStyles?: string; $mainColor: string | undefined }>`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	// height: 100%;
	flex: 1;
	${({ $customStyles }) => $customStyles}

	& .loader:after {
		color: ${({ $mainColor }) => ($mainColor ? $mainColor : "#ff4740")} !important;
	}
`;

export default Preloader;
