import { useEffect, useState } from "react";
import { Flex } from "@mantine/core";
import { ForcePackagesFiltersData, InputData, ForcePackageData, Optional, SystemSettingsTemplate } from "../../../types/filters";
import styled from "@emotion/styled/macro";
import Preloader from "../../../components/common/Preloader";
import ForcePackagesList from "./ForcePackagesList";
import SettingsPanel from "../../../components/settings-panel/SettingsPanel";

interface IForcePackagesPage {
	forcePackageData: ForcePackagesFiltersData;
	inputData: InputData;
	fpSettingsTemplate: SystemSettingsTemplate<ForcePackageData>;
	defaultSettings: Optional<ForcePackageData, "id">;
	initializeFilters: () => Promise<any>;
	downloadForcePackageSettings: (systemId: number) => Promise<any>;
	addForcePackage: (settings: Optional<ForcePackageData, "id">) => Promise<any>;
	saveForcePackage: (settings: Optional<ForcePackageData, "id">) => Promise<any>;
	deleteForcePackage: (settingsId: number) => Promise<any>;
	duplicateForcePackage: (settingsId: number) => Promise<any>;
}

const ForcePackagesPage: React.FC<IForcePackagesPage> = ({
	forcePackageData,
	inputData,
	initializeFilters,
	downloadForcePackageSettings,
	fpSettingsTemplate,
	defaultSettings,
	addForcePackage,
	saveForcePackage,
	deleteForcePackage,
	duplicateForcePackage,
}) => {
	const [listLoading, setListLoading] = useState<boolean>(false);
	const [forcePackagesLoading, setForcePackagesLoading] = useState<boolean>(false);
	const [forcePackageSettings, setForcePackageSettings] = useState<Optional<ForcePackageData, "id">>();
	const [isNewForcePackage, setIsNewForcePackage] = useState<boolean>(false);

	const getForcePackageSettings = async (fpId: number) => {
		setForcePackagesLoading(true);
		setIsNewForcePackage(false);
		let settings = { ...(await downloadForcePackageSettings(fpId)), id: fpId };
		settings = { ...settings, name: settings.fp.name };
		setForcePackageSettings(settings);
		setForcePackagesLoading(false);
		return settings;
	};

	const reloadList = async () => {
		setListLoading(true);
		const res = await initializeFilters();
		if (res) {
			setListLoading(false);
			setIsNewForcePackage(false);
		}
	};

	const handleDeleteForcePackage = async (settingId: number) => {
		const res = await deleteForcePackage(settingId);
		if (forcePackageSettings && forcePackageSettings.id === settingId) setForcePackageSettings(undefined);
		return res;
	};

	return (
		<PageContainer>
			<ListAndFiltersWrapper>
				<ForcePackagesList
					name=""
					list={forcePackageData}
					filtersLoading={listLoading}
					getForcePackageSettings={getForcePackageSettings}
					setForcePackagesLoading={(value: boolean) => setForcePackagesLoading(value)}
					reloadList={reloadList}
					initializeNewSystem={(settings: Optional<ForcePackageData, "id">) => {
						setForcePackageSettings(settings);
						setIsNewForcePackage(true);
					}}
					deleteSystem={handleDeleteForcePackage}
					duplicateSystem={duplicateForcePackage}
				/>
			</ListAndFiltersWrapper>
			<SystemSettingWrapper>
				{forcePackageSettings && Object.keys(forcePackageSettings).length > 0 ? (
					<SettingsPanel
						settings={forcePackageSettings as Optional<ForcePackageData, "id">}
						defaultSettings={defaultSettings}
						isSystemNew={isNewForcePackage}
						systemSettingsTemplate={fpSettingsTemplate}
						inputData={inputData}
						settingsLoading={forcePackagesLoading}
						filtersLoading={listLoading}
						reloadFilters={reloadList}
						removeSettings={() => setForcePackageSettings(undefined)}
						addSystem={addForcePackage}
						saveSystem={saveForcePackage}
						forcePackageFiltersData={forcePackageData}
						setSetting={(settingName: string | number | symbol, value: ForcePackageData[keyof ForcePackageData]) =>
							setForcePackageSettings((prev) => ({ ...prev, [settingName]: value } as ForcePackageData))
						}
					></SettingsPanel>
				) : forcePackagesLoading ? (
					<Preloader></Preloader>
				) : null}
			</SystemSettingWrapper>
		</PageContainer>
	);
};

export const PageContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	max-height: calc(100vh - var(--header-height) - var(--footer-height) - var(--content-padding) * 2 - var(--breadcrumbs-height));

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const ListAndFiltersWrapper = styled(Flex)`
	flex-direction: column;
	width: 35%;
	max-height: 100%;
	overflow-y: auto;

	@media (max-width: 768px) {
		max-height: 50vh;
		width: 100%;
	}
`;

const SystemSettingWrapper = styled(Flex)`
	flex-direction: column;
	width: 65%;
	max-height: 100%;
	margin-left: 1rem;
	flex: 1;
	position: relative;

	@media (max-width: 768px) {
		width: 100%;
		margin: 0;
		margin-top: 1rem;
	}
`;

export default ForcePackagesPage;
