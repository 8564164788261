import * as Cesium from "cesium";

// Get random position inside the area created with positions array
export const getRandomPositionFromCartesians = (positions: Cesium.Cartesian3[]) => {
	let firstIndex = Math.floor(Math.random() * positions.length);
	let secondIndex = Math.floor(Math.random() * positions.length);

	while (firstIndex === secondIndex) {
		secondIndex = Math.floor(Math.random() * positions.length);
	}

	const twoRandomPoints = [positions[firstIndex], positions[secondIndex]];

	// Random point between two points
	const random = Cesium.Cartesian3.lerp(twoRandomPoints[0], twoRandomPoints[1], Math.random(), new Cesium.Cartesian3());

	// Transform to Cesium.Cartographic
	const cartographic = Cesium.Cartographic.fromCartesian(random);

	return cartographic;
};
