import React, { useState, createContext, useMemo } from "react";
import { BehaviorParameter } from "../../../../../types/filters";
import * as Cesium from "cesium";

interface Props {
	viewer: Cesium.Viewer | null;
	children: React.ReactNode;
}

type EntityName = "polygon" | "line" | "circle" | "path";

type BehaviorEditiorContextType = {
	copiedSettings: BehaviorParameter[] | undefined;
	updateCopiedSettings: (value: BehaviorParameter[] | undefined) => void;
	allEntities: Record<string, Record<EntityName, Cesium.Entity | Cesium.Entity[]>>;
	updateAllEntities: (agentId: string, entityName: EntityName, value: Cesium.Entity | Cesium.Entity[]) => void;
	resetAgentIdEntities: (agentId: string, modifyState?: boolean) => void;
};

export const BehaviorEditiorContext = createContext<BehaviorEditiorContextType>({} as BehaviorEditiorContextType);

const BehaviorEditiorContextProvider: React.FC<Props> = ({ viewer, children }) => {
	const color = useMemo(() => new Cesium.ColorMaterialProperty(Cesium.Color.WHITE.withAlpha(0.2)), []);
	const [copiedSettings, setCopiedSettings] = useState<BehaviorParameter[]>();
	const [allEntities, setAllEntities] = useState<Record<string, Record<EntityName, Cesium.Entity | Cesium.Entity[]>>>({});

	const areEntitiesEqual = (prev: Cesium.Entity, next: Cesium.Entity) => {
		return (
			JSON.stringify(prev.polyline?.positions?.getValue(new Cesium.JulianDate(1, 1))) ===
			JSON.stringify(next.polyline?.positions?.getValue(new Cesium.JulianDate(1, 1)))
		);
	};

	const resetAgentIdEntities = (agentId: string, modifyState?: boolean) => {
		if (viewer && allEntities[agentId]) {
			Object.keys(allEntities[agentId]).forEach((entityName) => {
				if (entityName === "path") {
					(allEntities[agentId][entityName] as Cesium.Entity[]).forEach((line) => {
						viewer.entities.remove(line);
					});
				} else if (entityName === "polygon") {
					const polygon = allEntities[agentId][entityName] as Cesium.Entity;
					if (polygon.polygon) polygon.polygon.material = color;
				} else {
					viewer.entities.remove(allEntities[agentId][entityName as EntityName] as Cesium.Entity);
				}
			});

			modifyState &&
				setAllEntities((prev) => {
					const newEntities = { ...prev };
					delete newEntities[agentId];

					// If 1-2 key is removed, all above keys like 1-3, 1-4 should be renamed to 1-2 and 1-3 respectively
					const keys = Object.keys(newEntities);
					keys.forEach((key) => {
						const keyParts = key.split("-");
						if (agentId.split("-")[0] === keyParts[0]) {
							if (keyParts[1] > agentId.split("-")[1]) {
								newEntities[`${keyParts[0]}-${parseInt(keyParts[1]) - 1}`] = newEntities[key];
								delete newEntities[key];
							}
						}
					});

					return newEntities;
				});
		}
	};

	const updateAllEntities = (agentId: string, entityName: EntityName, value: Cesium.Entity | Cesium.Entity[]) => {
		setAllEntities((prev) => {
			if (viewer && prev[agentId] && prev[agentId][entityName]) {
				switch (entityName) {
					case "path":
						if ((prev[agentId][entityName] as Cesium.Entity[]) && (prev[agentId][entityName] as Cesium.Entity[]).length > 0) {
							if ((value as Cesium.Entity[]).length === 0) {
								(prev[agentId][entityName] as Cesium.Entity[]).forEach((line) => {
									viewer.entities.remove(line);
								});
							}
						}
						break;
					case "polygon":
						if ((prev[agentId][entityName] as Cesium.Entity).id !== (value as Cesium.Entity).id) {
							const polygon = (prev[agentId][entityName] as Cesium.Entity).polygon;
							// TODO: Add allEntitiesSearch if other agent has this polygon colored to avoid visual bugs
							if (polygon) polygon.material = color;
						}
						break;
					case "circle":
						if (
							!areEntitiesEqual(prev[agentId][entityName] as Cesium.Entity, value as Cesium.Entity) ||
							(prev[agentId][entityName] as Cesium.Entity).id !== (value as Cesium.Entity).id
						) {
							viewer.entities.remove(prev[agentId][entityName] as Cesium.Entity);
						}
						break;
				}
			}

			const prevValue = prev[agentId] || {};
			return {
				...prev,
				[agentId]: {
					...prevValue,
					[entityName]: value,
				},
			};
		});
	};

	const updateCopiedSettings = (value: BehaviorParameter[] | undefined) => {
		if (value) {
			setCopiedSettings(value);
		}
	};

	const defaultBehaviorEditiorContext = {
		copiedSettings,
		updateCopiedSettings,
		allEntities,
		updateAllEntities,
		resetAgentIdEntities,
	};

	return <BehaviorEditiorContext.Provider value={defaultBehaviorEditiorContext}>{children}</BehaviorEditiorContext.Provider>;
};
export default BehaviorEditiorContextProvider;
