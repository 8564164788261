import React, { useCallback, useContext, useEffect, useState } from "react";
import { EmitterData, FiltersData, ListForcePackageData, InputData, Optional } from "../../../types/filters";
import { defaultSettings, emittersSettingsTemplate } from "./defaultSettings";
import { toast } from "react-toastify";
import makeRequest from "../../../common/makeRequest";
import Preloader from "../../../components/common/Preloader";
import SystemPage from "../../../components/system-page/SystemPage";
import { AppInfoContext } from "../../../components/contexts/AppInfoContext";

const Emitters: React.FC = () => {
	const [filters, setFilters] = useState<FiltersData>();
	const [inputData, setInputData] = useState<InputData>();
	const { currentPid } = useContext(AppInfoContext);

	const initialize = useCallback(async (pid: number, fp: number | string, side: number | string) => {
		const filters = await downloadFilters(pid, fp, side);
		const archetypes = await downloadInputData();
		setFilters(filters);
		setInputData(archetypes);
		return true;
	}, []);

	const downloadFilters = async (pid: number, fp: number | string, side: number | string) => {
		const response = await makeRequest("emitters/get", "GET", {}, { pid: pid, fp: fp, side: side });
		const json = await response.json();
		return json as FiltersData;
	};

	const downloadSystemSettings = async (systemId: number) => {
		const response = await makeRequest(`emitters/${systemId}/get`, "GET");
		const json = await response.json();
		return json as EmitterData;
	};

	const downloadInputData = async () => {
		const response = await makeRequest("emitters/get_input_data", "GET");
		const json = await response.json();
		return json as InputData;
	};

	const addEmitter = async (settings: Optional<EmitterData, "id">) => {
		const res = await makeRequest("emitters/add", "POST", settings);
		if (res.ok) {
			toast.success("Emitter added successfully!");
			const json = await res.json();
			return json.emitter as EmitterData;
		}
		toast.error("Error adding emitter!");
		return null;
	};

	const deleteEmitter = async (settingsId: number) => {
		const res = await makeRequest(`emitters/${settingsId}/delete`, "POST", {});
		if (res.ok) {
			toast.success("Emitter deleted successfully!");
			const json = await res.json();
			return json;
		}
		toast.error("Error deleting emitter!");
		return null;
	};

	const saveEmitter = async (settings: Optional<EmitterData, "id">) => {
		const res = await makeRequest(`emitters/${settings.id}/save`, "POST", settings);
		if (res.ok) {
			toast.success("Emitter saved successfully!");
			const json = await res.json();
			return json as EmitterData;
		}
		toast.error("Error saving emitter!");
		return null;
	};

	const duplicateEmitter = async (settingsId: number) => {
		const res = await makeRequest(`emitters/${settingsId}/duplicate`, "POST", {});
		if (res.ok) {
			toast.success("Emitter duplicated successfully!");
			const json = await res.json();
			return json as EmitterData;
		}
		toast.error("Error duplicating emitter!");
		return null;
	};

	useEffect(() => {
		initialize(currentPid, "", "");
	}, [initialize, currentPid]);

	return filters && inputData ? (
		<SystemPage
			forcePackageData={filters.fps as ListForcePackageData[]}
			systemsData={filters.emitters as EmitterData[]}
			inputData={inputData}
			initializeFilters={initialize}
			downloadSystemSettings={downloadSystemSettings}
			systemSettingsTemplate={emittersSettingsTemplate}
			defaultSettings={defaultSettings}
			addSystem={addEmitter}
			saveSystem={saveEmitter}
			deleteSystem={deleteEmitter}
			duplicateSystem={duplicateEmitter}
		></SystemPage>
	) : (
		<Preloader></Preloader>
	);
};

export default Emitters;
