import { ForcePackageData, Optional, SystemSettingsTemplate } from "../../types/filters";

export const fpSettingsTemplate: SystemSettingsTemplate<ForcePackageData> = {
	sections: {
		"": {
			fp: "force-package",
			cost: { type: "text", disabled: true },
		},
	},
};

export const defaultSettings: Optional<ForcePackageData, "id"> = {
	cost: "0.00",
	name: "New package",
	fp: {
		cost: "0.00",
		name: "New package",
		echelons: [
			{
				name: "New package",
				echelons: [],
				type: 0,
				systems: [],
				is_lego: true,
				id: 1,
			},
		],
		type: "0",
	},
	type: 0,
	systems: {},
};
