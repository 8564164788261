import { EmitterData, Optional, SystemSettingsTemplate } from "../../../types/filters";

export const emittersSettingsTemplate: SystemSettingsTemplate<EmitterData> = {
	sections: {
		Marks: {
			base_range: { type: "slider", max_limit: 100000, step: 1000, unit: "m" },
			role: {
				type: "select",
				data: [
					{ value: "Comms link", label: "Comms link" },
					{ value: "Jammer", label: "Jammer" },
					{ value: "Radar Jammer", label: "Radar Jammer" },
					{ value: "SIGINT sensor", label: "SIGINT sensor" },
				],
			},
			is_relay: "checkbox",
			technology_level: {
				type: "select",
				label: "Generation",
				data: [
					{ value: "1", label: "1" },
					{ value: "2", label: "2" },
					{ value: "3", label: "3" },
					{ value: "4", label: "4" },
				],
			},
		},
		Confounders: {
			confounders: {
				type: "static-table",
				columns: { type: "Type", degradation_percent: "Degradation (%)" },
				use_archetypes: false,
				editable: false,
			},
		},
		Emitter: {
			side: {
				type: "select",
				data: [
					{ value: "2", label: "NONE" },
					{ value: "0", label: "BLUE" },
					{ value: "1", label: "RED" },
				],
			},
			cmo_id: "text",
			cost: "text",
			name: "text",
		},
	},
};

export const defaultSettings: Optional<EmitterData, "id"> = {
	base_range: 0,
	role: "Comms link",
	is_relay: false,
	technology_level: "1",
	confounders: [],
	cmo_id: -1,
	name: "New emitter",
	side: 0,
	cost: 0,
	count: 0,
};
