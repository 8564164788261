import { useContext, useEffect, useRef, useState } from "react";
import { Flex } from "@mantine/core";
import { BaseData, ListForcePackageData, InputData, SystemSettingsTemplate } from "../../types/filters";
import { Optional } from "../../types/filters";
import styled from "@emotion/styled/macro";
import Filters from "./Filters";
import ElementsList from "../elements-list/ElementsList";
import SettingsPanel from "../settings-panel/SettingsPanel";
import Preloader from "../common/Preloader";
import { AppInfoContext } from "../contexts/AppInfoContext";
import { IconsList } from "../../types/common";
import { useNavigate, useParams } from "react-router-dom";

type ISystemLibrary<T extends BaseData> = {
	forcePackageData: ListForcePackageData[];
	systemsData: T[];
	inputData: InputData;
	systemSettingsTemplate: SystemSettingsTemplate<T>;
	defaultSettings: Optional<T, "id">;
	initializeFilters: (pid: number, fp: number | string, side: number | string) => Promise<any>;
	downloadSystemSettings: (systemId: number) => Promise<any>;
	addSystem: (settings: Optional<T, "id">) => Promise<any>;
	saveSystem: (settings: Optional<T, "id">) => Promise<any>;
	deleteSystem: (settingsId: number) => Promise<any>;
	duplicateSystem: (settingsId: number) => Promise<any>;
	getSystemUsageInfo?: (systemId: number, pid: number) => Promise<any>;
};

const SystemPage = <T extends BaseData>({
	forcePackageData,
	systemsData,
	inputData,
	initializeFilters,
	downloadSystemSettings,
	systemSettingsTemplate,
	defaultSettings,
	addSystem,
	saveSystem,
	deleteSystem,
	duplicateSystem,
	getSystemUsageInfo,
}: ISystemLibrary<T>) => {
	const navigate = useNavigate();
	const [filtersLoading, setFiltersLoading] = useState<boolean>(false);
	const [settingsLoading, setSettingsLoading] = useState<boolean>(false);
	const [systemSettings, setSystemSettings] = useState<Optional<T, "id">>();
	const [isNewSystem, setIsNewSystem] = useState<boolean>(false);
	const { systemId } = useParams();

	const { currentPid } = useContext(AppInfoContext);
	const relativePid = useRef<number>(currentPid);
	const currentFp = useRef<number | "">("");
	const currentSide = useRef<number | "">("");

	useEffect(() => {
		if (systemId) {
			getSystemSettings(parseInt(systemId));
		}
		// it has to be called only once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSystemSettings = async (systemId: number) => {
		setSettingsLoading(true);
		let path = window.location.pathname
			.split("/")
			.filter((item, ix) => ix !== window.location.pathname.split("/").length - 1 || Number.isNaN(parseInt(item))) // remove previous systemId
			.join("/");
		if (path[path.length - 1] === "/") path = path.slice(0, -1);

		navigate(`${path}/${systemId}`);
		setIsNewSystem(false);
		const settings = { ...(await downloadSystemSettings(systemId)), id: systemId };
		setSystemSettings(settings);
		setSettingsLoading(false);
		return settings;
	};

	const reloadFilters = async () => {
		setFiltersLoading(true);
		const res = await initializeFilters(relativePid.current, currentFp.current, currentSide.current);
		if (res) {
			setFiltersLoading(false);
			setIsNewSystem(false);
		}
	};

	const handleDeleteSystem = async (settingId: number) => {
		const res = await deleteSystem(settingId);
		if (systemSettings && systemSettings.id === settingId) setSystemSettings(undefined);
		return res;
	};

	return (
		<PageContainer>
			<ListAndFiltersWrapper>
				<Filters
					fps={forcePackageData}
					initializeFilters={async (pid: number, fp: number | "", side: number | "") => {
						relativePid.current = pid;
						currentFp.current = fp;
						currentSide.current = side;

						const filters = await initializeFilters(pid, fp, side);
						return filters;
					}}
					setFiltersLoading={(value: boolean) => setFiltersLoading(value)}
				/>
				<ElementsList
					name=""
					list={systemsData}
					filtersLoading={filtersLoading}
					getElementSettings={getSystemSettings}
					setSettingsLoading={(value: boolean) => setSettingsLoading(value)}
					reloadFilters={reloadFilters}
					initializeNewSystem={() => {
						setSystemSettings(defaultSettings);
						setIsNewSystem(true);
					}}
					deleteSystem={handleDeleteSystem}
					duplicateSystem={duplicateSystem}
					getSystemUsageInfo={
						getSystemUsageInfo ? (systemId: number) => getSystemUsageInfo(systemId, relativePid.current) : undefined
					}
					iconsList={(["edit", "duplicate", "delete"] as IconsList).concat(getSystemUsageInfo ? ["usage-info"] : [])}
				/>
			</ListAndFiltersWrapper>
			<SystemSettingWrapper>
				{systemSettings && Object.keys(systemSettings).length > 0 ? (
					<SettingsPanel
						settings={systemSettings}
						defaultSettings={defaultSettings}
						isSystemNew={isNewSystem}
						systemSettingsTemplate={systemSettingsTemplate}
						inputData={inputData}
						settingsLoading={settingsLoading}
						filtersLoading={filtersLoading}
						reloadFilters={reloadFilters}
						removeSettings={() => setSystemSettings(undefined)}
						addSystem={addSystem}
						saveSystem={saveSystem}
						setSetting={(settingName: keyof T, value: T[keyof T]) =>
							setSystemSettings((prev) => ({ ...prev, [settingName]: value } as T))
						}
					/>
				) : settingsLoading ? (
					<Preloader></Preloader>
				) : null}
			</SystemSettingWrapper>
		</PageContainer>
	);
};

export const PageContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	max-height: calc(100vh - var(--header-height) - var(--footer-height) - var(--content-padding) * 2 - var(--breadcrumbs-height));

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const ListAndFiltersWrapper = styled(Flex)`
	flex-direction: column;
	width: 30%;
	max-height: 100%;
	overflow-y: auto;

	@media (max-width: 768px) {
		max-height: 50vh;
		width: 100%;
	}
`;

const SystemSettingWrapper = styled(Flex)`
	flex-direction: column;
	width: 70%;
	max-height: 100%;
	margin-left: 1rem;
	flex: 1;
	position: relative;

	@media (max-width: 768px) {
		width: 100%;
		margin: 0;
		margin-top: 1rem;
	}
`;

export default SystemPage;
