import { Optional, SensorData, SystemSettingsTemplate } from "../../../types/filters";

export const sensorsSettingsTemplate: SystemSettingsTemplate<SensorData> = {
	sections: {
		Marks: {
			max_z: { type: "slider", max_limit: 20000, step: 100, unit: "m" },
			sector_angle: { type: "slider", max_limit: 360, step: 10, unit: "degrees" },
			emitter: "checkbox",
			rotates: "checkbox",
			penetrates_canopy: "checkbox",
			perchable: "checkbox",
			technology_level: {
				type: "select",
				label: "Generation",
				data: [
					{ value: "1", label: "1" },
					{ value: "2", label: "2" },
					{ value: "3", label: "3" },
					{ value: "4", label: "4" },
				],
			},
			min_rcs: {
				type: "number-input",
				label: "Min Detectable RCS (dBsm)",
				unit: "dBsm",
			},
			detection_time: {
				type: "slider",
				label: "Detection Time (s)",
				unit: "s",
				min_limit: 0,
				max_limit: 60,
				step: 1,
			},
			tracking_time: {
				type: "slider",
				label: "Tracking Time (s)",
				unit: "s",
				min_limit: 0,
				max_limit: 60,
				step: 1,
			},
			cued_tracking_time: {
				type: "slider",
				label: "Cued Tracking Time (s)",
				unit: "s",
				min_limit: 0,
				max_limit: 60,
				step: 1,
				conditionalRenderProperty: "has_cueing",
				conditionalRenderValues: [true],
			},
			has_cueing: {
				type: "checkbox",
			},
		},
		"RCS Detection Probability": {
			rcs_probability: {
				type: "probability-table",
				conditionalRenderProperty: "emitter",
				conditionalRenderValues: [true],
			},
		},
		"Sensing performance": {
			sensing_performance: {
				type: "table",
				columns: { target: "Ranges to (m)", detect_range: "Detect", track_range: "Track", classify_range: "Classify" },
				use_archetypes: true,
				editable: true,
				value_type: "number",
			},
		},
		Confounders: {
			confounders: {
				type: "static-table",
				columns: { type: "Type", degradation_percent: "Degradation (%)" },
				use_archetypes: false,
				editable: false,
			},
		},
		Sensor: {
			side: {
				type: "select",
				data: [
					{ value: "2", label: "NONE" },
					{ value: "0", label: "BLUE" },
					{ value: "1", label: "RED" },
				],
			},
			cmo_id: "text",
			cost: "text",
			name: "text",
		},
	},
};

export const defaultSettings: Optional<SensorData, "id"> = {
	max_z: 0,
	sector_angle: 0,
	emitter: false,
	rotates: false,
	penetrates_canopy: false,
	perchable: false,
	technology_level: "1",
	rcs_probability: [],
	detection_time: 0,
	tracking_time: 0,
	cued_tracking_time: 0,
	has_cueing: false,
	sensing_performance: [
		{
			target: 11,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
		{
			target: 1,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
		{
			target: 2,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
		{
			target: 3,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
		{
			target: 4,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
		{
			target: 5,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
		{
			target: 9,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
		{
			target: 7,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
		{
			target: 6,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
		{
			target: 12,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
		{
			target: 10,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
		{
			target: 8,
			detect_range: 0,
			track_range: 0,
			classify_range: 0,
		},
	],
	confounders: [],
	cmo_id: -1,
	name: "New Sensor",
	side: 0,
	cost: 0,
};
