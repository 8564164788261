import * as Cesium from "cesium";

// invertDeletion is used to clear only polygons associated with the currentSelectedId 
// (if false it removes everything apart from the currentSelectedId)
export const clearPolygons = (viewer: Cesium.Viewer, currentSelectedId?: number, invertDeletion?: boolean) => {
	viewer?.dataSources?.getByName("geometry_voronois")[0]?.entities?.values.forEach((entity) => {
		if (entity.id.includes("spm-cell-")) {
			if (
				entity.polygon &&
				currentSelectedId !== undefined &&
				(!invertDeletion ? entity.id !== `spm-cell-${currentSelectedId}` : entity.id === `spm-cell-${currentSelectedId}`)
			) {
				entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.WHITE.withAlpha(0.2));
			} else if (entity.polygon && currentSelectedId === undefined) {
				entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.WHITE.withAlpha(0.2));
			}
		}
	});
};
