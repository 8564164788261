import { useMantineTheme } from "@mantine/core";
import styled from "@emotion/styled/macro";
import ms from "milsymbol";
import parse from "html-react-parser";

interface ICustomGraphNode {
	name: string;
	symbolCode: string;
	nodeSize: { x: number; y: number };
	isRoot?: boolean;
	toggleNode: () => void;
}

const CustomGraphNode: React.FC<ICustomGraphNode> = ({ name, symbolCode, nodeSize, isRoot = false, toggleNode }) => {
	const theme = useMantineTheme();

	var symbol = new ms.Symbol(symbolCode, {
		size: 30,
		colorMode: "Medium",
		iconColor: {
			Hostile: "red",
			Friend: theme.colorScheme === "dark" ? theme.colors.dark[2] : "black",
			Neutral: "yellow",
			Unknown: "gray",
			Civilian: "green",
		},
	}).asSVG();

	return symbol ? (
		<g>
			<foreignObject
				width={nodeSize.x}
				height={nodeSize.y}
				x={-nodeSize.x / 2}
				y={isRoot ? -50 : 0}
				style={{ textAlign: "center" }}
			>
				<Node>
					<div onClick={() => toggleNode()}>{parse(symbol)}</div>
					{name}
				</Node>
			</foreignObject>
		</g>
	) : null;
};

export default CustomGraphNode;

const Node = styled.div`
	// padding: 2rem;
	// background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.light[1])};
`;
