import React from "react";
import Header from "./components/header/Header";
import styled from "@emotion/styled/macro";
import Footer from "./components/footer/Footer";
import BreadcrumbsNavigation from "./components/breadcrumbs-navigation/BreadcrumbsNavigation";
import { ToastContainer } from "react-toastify";
import { Flex } from "@mantine/core";

interface IAppPage {
	children: React.ReactNode;
}

const AppPage: React.FC<IAppPage> = ({ children }) => {
	return (
		<AppPageContainer>
			<Header></Header>
			<Content>
				<BreadcrumbsNavigation />
				{children}
			</Content>
			<Footer></Footer>
			<ToastContainer
				position="bottom-left"
				className={"notification"}
				toastClassName={"toast-body"}
				bodyClassName={"toast-body"}
				progressClassName={"toast-progress"}
				limit={3}
				autoClose={2000}
			/>
		</AppPageContainer>
	);
};

const AppPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;

	@media (max-width: 768px) {
		height: auto;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.light[1])};
	height: 100%;
	max-height: 100%;
	width: 100%;
	overflow-y: auto;
	padding: var(--content-padding);

	@media (max-width: 768px) {
		padding: 2rem;
		min-height: calc(100vh - var(--mobile-header-height) - var(--footer-height));
	}
`;

export const Panel = styled(Flex)`
	flex-direction: column;
	width: 100%;
	border-radius: 0.5rem;
	padding: 2rem;
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0])};
	overflow-y: auto;
	scrollbar-gutter: stable;
`;

export default AppPage;
