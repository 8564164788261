import centerMapToPolygons from "./centerMapToPolygons";
import * as Cesium from "cesium";

export const createPolygons = (viewer: Cesium.Viewer, geometriesDataSource: Cesium.GeoJsonDataSource) => {
	const exists = viewer?.dataSources?.getByName("geometry_voronois")[0];

	if (!exists) {
		geometriesDataSource.name = "geometry_voronois";
		const entities = geometriesDataSource.entities.values;
		let allPolygonPositions: any = [];

		entities.forEach((entity: Cesium.Entity) => {
			if (Cesium.defined(entity.polygon)) {
				entity.polygon.arcType = new Cesium.ConstantProperty(Cesium.ArcType.GEODESIC);
			}
		});

		viewer.dataSources.add(geometriesDataSource);

		// First and last entity
		allPolygonPositions = allPolygonPositions.concat(entities[0]?.polygon?.hierarchy?.getValue(new Cesium.JulianDate(1, 1)).positions);
		allPolygonPositions = allPolygonPositions.concat(
			entities[entities.length - 1]?.polygon?.hierarchy?.getValue(new Cesium.JulianDate(1, 1)).positions
		);
		allPolygonPositions.filter((position: any) => position !== undefined);

		centerMapToPolygons(viewer, allPolygonPositions);
	}
};
