import { Echelon } from "../types/filters";

/**
 * Recursively updates an Echelon object based on a given echelonPath and updatedEchelon.
 * @param echelon The Echelon object to update.
 * @param echelonPath An array of indices representing the path to the sub-echelon to update.
 * @param updatedEchelon The updated Echelon object to replace the old sub-echelon with.
 * @param duplicate Whether to duplicate the updatedEchelon and add it to the end of the echelons array.
 * @returns A new Echelon object with the updated echelons array.
 */
function updateEchelon(echelon: Echelon, echelonPath: number[], updatedEchelon: Echelon | null, duplicate?: boolean): Echelon {
	// If the echelonPath has a length of 1, update or remove the echelon at the specified index
	if (echelonPath.length === 1) {
		if (updatedEchelon === null) {
			// Remove the echelon at the specified index
			return {
				...echelon,
				echelons: echelon.echelons.filter((_, index) => index !== echelonPath[0]),
			};
		}

		// Update the echelon at the specified index
		return duplicate
			? { ...echelon, echelons: [...echelon.echelons, updatedEchelon] }
			: {
					...echelon,
					echelons: [
						...echelon.echelons.map((subEchelon, index) => {
							if (index === echelonPath[0]) {
								return updatedEchelon;
							}
							return subEchelon;
						}),
					],
			  };
	}

	// If the echelonPath has a length greater than 1, recursively call the function with the sub-echelon at the specified index
	const subEchelonIndex = echelonPath[0];
	if (subEchelonIndex < 0 || subEchelonIndex >= echelon.echelons.length) {
		// If the index is out of bounds, return the original echelon
		return echelon;
	}

	const updatedEchelons = [...echelon.echelons];
	updatedEchelons[subEchelonIndex] = updateEchelon(updatedEchelons[subEchelonIndex], echelonPath.slice(1), updatedEchelon, duplicate);

	// Return a new Echelon object with the updated echelons array
	return {
		...echelon,
		echelons: updatedEchelons,
	};
}

export default updateEchelon;
