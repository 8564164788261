import { useEffect, useRef, useState } from "react";
import * as Cesium from "cesium";
import { BehaviorSelect } from "../force-package-accordions/BehaviorPanel";

interface IDeploymentZoneLayer {
	viewer: Cesium.Viewer;
	defaultValue: string | null;
	previewMode?: boolean;
	onChange: (value: string | null) => void;
}

const DeploymentZoneLayer: React.FC<IDeploymentZoneLayer> = ({ viewer, defaultValue, previewMode, onChange }) => {
	const [areasList, setAreasList] = useState<any[]>();
	const [value, setValue] = useState<string | null>(defaultValue);
	const valueRef = useRef<string | null>(defaultValue);

	const toggleArea = (areaId: string, toggle: boolean) => {
		if (viewer && viewer.dataSources) {
			const areasDataSource = viewer.dataSources.getByName("areas")[0] as Cesium.GeoJsonDataSource | undefined;
			const areasEntities = areasDataSource?.entities.values;
			const side = areasDataSource?.entities?.values[0]?.properties?.type.getValue(new Cesium.JulianDate(1, 1)).includes("Blue")
				? 0
				: 1;

			if (areasEntities) {
				areasEntities
					.filter((entity) => entity?.properties?.id.getValue(new Cesium.JulianDate(1, 1)) === areaId)
					.forEach((entity) => {
						const originalColor = side ? Cesium.Color.RED.withAlpha(0.4) : Cesium.Color.BLUE.withAlpha(0.4);
						if (entity.polygon && entity?.polygon?.material) {
							entity.polygon.outlineColor = new Cesium.ConstantProperty(originalColor);
							entity.polygon.material = toggle
								? new Cesium.ColorMaterialProperty(Cesium.Color.GOLDENROD.withAlpha(1))
								: new Cesium.ColorMaterialProperty(originalColor);
						}
					});
			}
		}
	};

	useEffect(() => {
		const areasDataSource = viewer.dataSources.getByName("areas")[0] as Cesium.GeoJsonDataSource | undefined;
		const areasEntities = areasDataSource?.entities.values;

		if (areasEntities) {
			setAreasList(
				areasEntities
					.filter((area) => area?.properties?.type.getValue(new Cesium.JulianDate(1, 1)).includes("staging"))
					.map((area) => {
						const id = area?.properties?.id.getValue(new Cesium.JulianDate(1, 1));
						const type = area?.properties?.type.getValue(new Cesium.JulianDate(1, 1));
						return {
							value: id,
							label: type + " " + id,
						};
					})
			);
		}

		return () => {
			valueRef.current !== null && toggleArea(valueRef.current, false);
		};

		// We only need to run it at the beginning
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (value !== null) {
			toggleArea(value, true);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return !previewMode && areasList ? (
		<BehaviorSelect
			defaultValue={defaultValue}
			onChange={(newValue) => {
				if (value !== null) toggleArea(value, false);
				valueRef.current = newValue;
				setValue(newValue);
				onChange(newValue);
			}}
			value={value}
			data={areasList}
			clearable
		></BehaviorSelect>
	) : null;
};

export default DeploymentZoneLayer;
