import styled from "@emotion/styled/macro";
import { AssessmentScenarioData, BehaviorParameter, Echelon, InputData, Optional } from "../../../../../types/filters";
import { Accordion, AccordionControlProps, ActionIcon, Button, Flex, Modal, Text, createStyles } from "@mantine/core";
import BehaviorPanel from "./BehaviorPanel";
import { IconClipboardCopy, IconCopy } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { BehaviorEditiorContext } from "../contexts/BehaviorEditiorContext";
import _ from "lodash";
import { toast } from "react-toastify";
import { useHover } from "@mantine/hooks";

interface IBehaviorSystemElement {
	color: string;
	inputData: InputData;
	systemId: number;
	agentId: number;
	systemIx: number;
	scenarioSettings: Optional<AssessmentScenarioData, "id">;
	isCommander: boolean;
	behaviorSettings: Record<number, BehaviorParameter[]>;
	echelon: Echelon;
	setSetting: (value: Record<number, BehaviorParameter[]> | null, duplicate?: boolean, changeTopLevelName?: boolean) => void;
	updateSelectedSymbolId: (id: number | null) => void;
}

const BehaviorSystemElement: React.FC<IBehaviorSystemElement> = ({
	color,
	inputData,
	systemId,
	agentId,
	systemIx,
	scenarioSettings,
	isCommander,
	behaviorSettings,
	echelon,
	setSetting,
	updateSelectedSymbolId,
}) => {
	const { hovered, ref } = useHover();
	const { classes } = useStyles();
	const { allEntities, copiedSettings, updateCopiedSettings, resetAgentIdEntities } = useContext(BehaviorEditiorContext);
	const [isPasteConfirmationOpened, setIsPasteConfirmationOpened] = useState(false);

	// Add event listener to ctrl+c and ctrl+v
	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			if (hovered) {
				if ((e.ctrlKey || e.metaKey) && e.key === "c") {
					updateCopiedSettings(behaviorSettings[agentId]);
					toast.success("Settings copied to clipboard");
				} else if ((e.ctrlKey || e.metaKey) && e.key === "v") {
					if (copiedSettings) setIsPasteConfirmationOpened(true);
					else toast.error("No settings in clipboard");
				}
			}
		};

		window.addEventListener("keydown", onKeyDown);
		return () => window.removeEventListener("keydown", onKeyDown);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hovered, copiedSettings, behaviorSettings, agentId]);

	return (
		<AccordionItem value={`${echelon.name}_system_${systemIx}_${agentId}`}>
			<AccordionControl $isCommander={isCommander} color={color}>
				<Flex style={{ alignItems: "center", width: "100%", justifyContent: "space-between" }}>
					<Text
						ref={ref}
						className={classes.titleText}
						color={color}
						onClick={(e) => {
							e.stopPropagation();
							updateSelectedSymbolId(agentId);
						}}
						w={"fit-content"}
					>
						{scenarioSettings.systems[systemId].name}
					</Text>
					<Flex>
						<ActionIcon
							component="div"
							color={"gray"}
							title="Paste settings from clipboard"
							onClick={(e) => {
								e.stopPropagation();
								if (copiedSettings) setIsPasteConfirmationOpened(true);
								else toast.error("No settings in clipboard");
							}}
						>
							<IconClipboardCopy size="1.1rem"></IconClipboardCopy>
						</ActionIcon>
						<ActionIcon
							component="div"
							color={"gray"}
							title="Copy settings to clipboard"
							onClick={(e) => {
								e.stopPropagation();
								updateCopiedSettings(behaviorSettings[agentId]);
								toast.success("Settings copied to clipboard");
							}}
						>
							<IconCopy size="1.1rem"></IconCopy>
						</ActionIcon>
					</Flex>
				</Flex>
				<Modal
					title={"Paste confirmation"}
					className={classes.modal}
					opened={isPasteConfirmationOpened}
					onClose={() => setIsPasteConfirmationOpened(false)}
					centered
				>
					<Text>
						{`Are you sure you want to paste settings from the clipboard to `}
						<Text fw={700} c={"red"} style={{ display: "inline-block" }}>{`${scenarioSettings.systems[systemId].name}?`}</Text>
					</Text>
					<Flex w={"100%"} mt={"1rem"} style={{ justifyContent: "flex-end" }}>
						<Button
							onClick={(e) => {
								e.stopPropagation();
								if (copiedSettings) {
									const queueInputKeys = Object.keys(allEntities).filter(
										(key) => key.split("-")[0] === agentId.toString()
									);
									queueInputKeys.forEach((key) => {
										resetAgentIdEntities(key, true);
									});

									const newSettings = _.cloneDeep({
										...behaviorSettings,
										[agentId]: _.cloneDeep(copiedSettings),
									});
									setSetting(newSettings);
									updateCopiedSettings(undefined);
								}
								setIsPasteConfirmationOpened(false);
							}}
						>
							Paste
						</Button>
						<Button
							ml={"0.5rem"}
							onClick={(e) => {
								e.stopPropagation();
								setIsPasteConfirmationOpened(false);
							}}
						>
							Cancel
						</Button>
					</Flex>
				</Modal>
			</AccordionControl>
			<AccordionPanel>
				<BehaviorPanel
					entityId={agentId}
					behaviorSettings={behaviorSettings}
					inputData={inputData}
					scenarioSettings={scenarioSettings}
					setSetting={setSetting}
					systemId={systemId}
					isEditionModeOn={false}
					preview
				></BehaviorPanel>
			</AccordionPanel>
		</AccordionItem>
	);
};

export default BehaviorSystemElement;

const useStyles = createStyles((theme) => ({
	modal: {
		".mantine-Modal-body": {
			padding: "1rem",
			color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
			backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.light[1],
		},
	},
	titleText: {
		"&:hover": {
			textDecoration: "underline",
		},
	},
}));

const AccordionPanel = styled(Accordion.Panel)`
	display: flex;
	width: 100%;
	flex-direction: row;

	& > div {
		width: 100%;
	}
`;

const AccordionItem = styled(Accordion.Item)`
	// border-bottom: 0.0625rem solid ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.light[4])};
	border: ${({ theme }) => `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.light[4]}`};
	margin-top: 1rem;
	&:hover {
		border-bottom: 0.0625rem solid ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.light[5])};
	}

	& .mantine-Accordion-content {
		display: flex;
		width: 100%;
	}
`;

const AccordionControl = styled(({ $isCommander, ...rest }: AccordionControlProps & { $isCommander: boolean }) => (
	<Accordion.Control {...rest} />
))`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.light[4])};
	border: 2px solid ${(props) => (props.$isCommander ? props.theme.colors.accentRed[8] : "transparent")};

	&:hover {
		background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.light[5])};
	}
`;

