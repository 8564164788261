import React from "react";
import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router-dom";
import { Button, createStyles } from "@mantine/core";

const ProjectMenu: React.FC = () => {
	const { classes } = useStyles();
	const navigate = useNavigate();

	return (
		<MenuContainer>
			<Button className={classes.menuBtn} onClick={() => navigate("systems-library")}>
				{"Systems library"}
			</Button>
			<Button className={classes.menuBtn} onClick={() => navigate("force-packages")}>
				{"Force packages"}
			</Button>
			<Button className={classes.menuBtn} onClick={() => navigate("assessments")}>
				{"Assessments"}
			</Button>
		</MenuContainer>
	);
};

const useStyles = createStyles((theme) => ({
	menuBtn: {
		margin: "0.5rem",
	},
}));

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
	flex-wrap: wrap;
	padding: 0 10rem;
	justify-content: center;

	& button {
		width: 12rem;
		height: 12rem;
	}

	@media (max-width: 768px) {
		padding: 0;
		& button {
			width: 12rem;
			height: 12rem;
		}
	}
}`;

export default ProjectMenu;
