import { Optional, PlatformData, SystemSettingsTemplate } from "../../../types/filters";

export const platformsSettingsTemplate: SystemSettingsTemplate<PlatformData> = {
	sections: {
		Categories: {
			mobility: {
				type: "select",
			},
			subtype: {
				type: "select",
			},
			sensor_elevation: {
				type: "slider",
				max_limit: 1000,
				step: 5,
				unit: "m",
			},
			endurance: {
				type: "slider",
				max_limit: 2880,
				step: 20,
				unit: "min",
			},
			base_speed: {
				type: "slider",
				max_limit: 3000,
				step: 50,
				unit: "km/h",
			},
			dash_speed: {
				type: "slider",
				max_limit: 3000,
				step: 50,
				unit: "km/h",
			},
			max_roughness: {
				type: "slider",
				max_limit: 100,
			},
			rest_period: {
				type: "slider",
				max_limit: 2880,
				step: 20,
				unit: "min",
			},
			min_altitude: {
				type: "slider",
				max_limit: 12000,
				step: 100,
				unit: "m",
			},
			max_altitude: {
				type: "slider",
				max_limit: 12000,
				step: 100,
				unit: "m",
			},
			technology_level: {
				type: "select",
				label: "Generation",
				data: [
					{ value: "1", label: "1" },
					{ value: "2", label: "2" },
					{ value: "3", label: "3" },
					{ value: "4", label: "4" },
				],
			},
			rcs: {
				type: "number-input",
				label: "Radar Cross-Section (dBsm)",
				unit: "dBsm",
			},
		},
		Confounders: {
			confounders: {
				type: "static-table",
				columns: { type: "Type", degradation_percent: "Degradation (%)" },
				use_archetypes: false,
				editable: false,
			},
		},
		Platform: {
			side: {
				type: "select",
				data: [
					{ value: "2", label: "NONE" },
					{ value: "0", label: "BLUE" },
					{ value: "1", label: "RED" },
				],
			},
			cmo_id: "text",
			cost: "text",
			type_name: "text",
			specific_name: "text",
		},
	},
};

export const defaultSettings: Optional<PlatformData, "id"> = {
	cmo_id: -1,
	specific_name: "New platform",
	side: 0,
	cost: 0,
	count: 0,
	mobility: "11",
	subtype: "1",
	sensor_elevation: 0,
	endurance: 0,
	base_speed: 0,
	dash_speed: 0,
	max_roughness: 0,
	technology_level: "1",
	rest_period: 0,
	min_altitude: 0,
	max_altitude: 0,
	confounders: [],
	type_name: "New platform type",
};
