//tsx boilerplate
import React, { useEffect, useState, createContext } from "react";
import { Project, ProjectsGetResponse } from "../../types/responses";
import makeRequest from "../../common/makeRequest";
import { useParams } from "react-router-dom";

interface Props {
	children: React.ReactNode;
}

type AppInfoContextType = {
	projects: Project[] | undefined;
	isSuperuser: boolean;
	currentPid: number;
	setPid: (pid: number) => void;
	token: string | undefined;
	setToken: (token: string) => void;
};

export const AppInfoContext = createContext<AppInfoContextType>({} as AppInfoContextType);

const AppInfoContextProvider: React.FC<Props> = ({ children }) => {
	const { pid } = useParams();
	const [projects, setProjects] = useState<Project[]>();
	const [isSuperuser, setIsSuperuser] = useState<boolean>(false);
	const [token, setToken] = useState<string | undefined>(localStorage.getItem("token") || undefined);
	const [currentPid, setCurrentPid] = useState<number>(pid ? parseInt(pid) : 0);

	useEffect(() => {
		if (token) {
			makeRequest("projects/get", "GET").then(async (res) => {
				const json: ProjectsGetResponse = await res.json();
				setProjects(json.projects);
			});
			makeRequest("is_superuser", "GET").then(async (res) => {
				const json: { is_superuser: boolean } = await res.json();
				setIsSuperuser(json.is_superuser);
			});
		}
	}, [token]);

	useEffect(() => {
		if (pid) {
			setPid(parseInt(pid));
		}
	}, [pid]);

	const setPid = (pid: number) => {
		localStorage.setItem("pid", pid.toString());
		setCurrentPid(pid);
	};

	const defaultAppInfoContext = {
		projects,
		isSuperuser,
		currentPid,
		setPid,
		token,
		setToken,
	};

	return <AppInfoContext.Provider value={defaultAppInfoContext}>{children}</AppInfoContext.Provider>;
};
export default AppInfoContextProvider;
