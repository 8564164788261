import {
	Archetype,
	Echelon,
	ForcePackageData,
	ForcePackagesFiltersData,
	InputData,
	ListForcePackageData,
	Optional,
} from "../../../types/filters";
import { NativeSelect, createStyles, TextInput, Flex, Autocomplete } from "@mantine/core";
import { useCallback, useMemo, useRef, useState } from "react";
import { InputWrapper, PopupBody } from "../../../components/common/tables/SettingStaticTable";
import { downloadForcePackageSettings } from "../downloadForcePackageSettings";
import Popup from "../../../components/common/Popup";
import RadioGroup from "../../../components/common/RadioGroup";
import { toast } from "react-toastify";
import { useLiveRef } from "../../../hooks/useLiveRef";
import CustomSlider from "../../../components/common/CustomSlider";
import { findHighestAgentId } from "../functions/findHighestAgentId";
import { updateEchelonIds } from "../functions/updateEchelonIds";

interface IForcePackagesModal {
	settings: Optional<ForcePackageData, "id">;
	inputData: InputData;
	modalButtonText: string;
	type: number;
	echelon: Echelon;
	forcePackageFiltersData: ForcePackagesFiltersData;
	setEchelon: (value: Echelon | null, duplicate?: boolean, changeTopLevelName?: boolean) => void;
	setSetting: (settingName: string, value: ForcePackageData[keyof ForcePackageData]) => void;
}

const ForcePackagesModal: React.FC<IForcePackagesModal> = ({
	settings,
	inputData,
	modalButtonText,
	type,
	echelon,
	forcePackageFiltersData,
	setEchelon,
	setSetting,
}) => {
	const { classes } = useStyles();
	const selectedLegoId = useRef<number | null>(null);
	const selectedSystemId = useRef<number | null>(null);
	const [count, setCount] = useState<number>(1);
	const countRef = useLiveRef<number>(count);
	const [searchSystemSearch, setSearchSystemSearch] = useState<string>("");
	const [modalSelectedType, setModalSelectedType] = useState<string>();
	const [newEmptyEchelon, setNewEmptyEchelon] = useState<Echelon>({
		name: "",
		type: 0,
		systems: [],
		echelons: [],
		is_lego: true,
		id: 1,
	});

	const echelonTypesData = useMemo<Record<number, string>>(
		() =>
			Object.fromEntries(
				Object.entries({
					0: "System",
					1: "Platoon",
					2: "Company",
					3: "Battalion",
					4: "Brigade",
				}).filter((key) => parseInt(key[0]) < type)
			),
		[type]
	);

	const saveLego = useCallback(async () => {
		if (selectedLegoId.current) {
			const newFpSettings = await downloadForcePackageSettings(selectedLegoId.current as number);
			if (!newFpSettings) return false;

			// to prevent echelon id duplicates
			const updatedLegoEchelon = updateEchelonIds(newFpSettings.fp.echelons[0], findHighestAgentId(settings.fp.echelons))[0];
			const newEchelon = {
				...echelon,
				echelons: [...echelon.echelons, ...Array(count).fill(updatedLegoEchelon, 0)],
			};
			const newSystems = { ...settings.systems, ...newFpSettings.systems };

			setEchelon(newEchelon, false, false);
			setSetting("systems", newSystems);
			setModalSelectedType(undefined);
			setCount(1);
			return true;
		} else {
			toast.info("Please select a lego");
			return false;
		}
	}, [echelon, settings, setEchelon, setSetting, selectedLegoId, count]);

	const saveSystem = useCallback(async () => {
		if (inputData.archetypes && selectedSystemId.current) {
			const hightestAgentId = findHighestAgentId(settings.fp.echelons) + 1;
			const systemsArray = [];

			for (let i = 0; i < count; i++) {
				systemsArray.push({ system_id: selectedSystemId.current, agent_id: hightestAgentId + i });
			}

			const newEchelon = {
				...echelon,
				systems: [...echelon.systems, ...systemsArray],
			};

			const archetypeWithSystem = inputData.archetypes.find((archetype) =>
				archetype.systems?.some((system) => system.id === selectedSystemId.current)
			);
			const newSystem = archetypeWithSystem?.systems?.find((system) => system.id === selectedSystemId.current);

			setEchelon(newEchelon, false, false);
			setSetting("systems", {
				...settings.systems,
				[selectedSystemId.current]: newSystem as ListForcePackageData & { cost: number; symbol: string },
			});
			setModalSelectedType(undefined);
			setCount(1);
			setSearchSystemSearch("");
			return true;
		} else {
			toast.info("Please select a system");
			return false;
		}
	}, [echelon, inputData, selectedSystemId, settings, setEchelon, setSetting, count]);

	const saveOtherEchelon = useCallback(async () => {
		if (newEmptyEchelon.name !== "") {
			const newEchelon = { ...echelon, echelons: [...echelon.echelons, newEmptyEchelon] };
			setEchelon(newEchelon, false, false);
			setModalSelectedType(undefined);
			setNewEmptyEchelon({
				name: "",
				type: 0,
				systems: [],
				echelons: [],
				is_lego: true,
				id: 1,
			});
			return true;
		} else {
			toast.info("Please enter a name");
			return false;
		}
	}, [echelon, newEmptyEchelon, setEchelon]);

	const handleSubmit = useCallback(async () => {
		if (modalSelectedType === "lego") {
			return saveLego();
		} else if (modalSelectedType === "0") {
			return saveSystem();
		} else if (modalSelectedType !== "-1" && modalSelectedType !== undefined) {
			return saveOtherEchelon();
		}
		return false;
	}, [saveLego, saveSystem, saveOtherEchelon, modalSelectedType]);

	const getLegoSettings = useCallback(() => {
		const groups = Object.keys(echelonTypesData)
			.slice(1)
			.map((key) => {
				return {
					name: forcePackageFiltersData[parseInt(key)].name,
					options: forcePackageFiltersData[parseInt(key)].fps,
				};
			});

		return (
			<Flex style={{ flexDirection: "column", justifyContent: "space-between", height: "100%" }} w={"100%"}>
				<RadioGroup
					groups={groups}
					onChange={(value) => {
						selectedLegoId.current = value;
					}}
					selected={selectedLegoId.current}
				></RadioGroup>
				<InputWrapper mt={"1rem"} style={{ flex: "0" }}>
					<h4>Count</h4>
					<CustomSlider
						className={classes.inputComponent}
						min={1}
						max={10}
						value={countRef.current}
						onChangeEnd={(value) => setCount(value)}
					></CustomSlider>
				</InputWrapper>
			</Flex>
		);
	}, [echelonTypesData, forcePackageFiltersData, countRef, classes.inputComponent]);

	const getSystemSettings = useCallback(() => {
		const groups = inputData.archetypes
			? (inputData.archetypes as Archetype[]).map((category) => {
					return {
						name: category.name,
						options: category.systems
							? category.systems.map((systems) => {
									return {
										name: `${systems.name}`,
										id: systems.id,
										color: systems.side === 0 ? "blue" : "red",
									};
							  })
							: [],
					};
			  })
			: [];

		return (
			<Flex style={{ flexDirection: "column", position: "relative", height: "100%", justifyContent: "space-between" }} w={"100%"}>
				<Autocomplete
					className={classes.autocomplete}
					placeholder="Search system"
					data={groups.reduce((acc: string[], curr) => [...acc, ...curr.options.map((option) => option.name)], [])}
					mb={"1rem"}
					value={searchSystemSearch}
					onChange={(value) => {
						value ? setSearchSystemSearch(value) : setSearchSystemSearch("");
					}}
				></Autocomplete>
				<RadioGroup
					groups={groups
						.filter((group) =>
							group.options.some((option) => option.name.toLowerCase().includes(searchSystemSearch.toLowerCase()))
						)
						.map((group) => {
							return {
								...group,
								options: group.options.filter((option) =>
									option.name.toLowerCase().includes(searchSystemSearch.toLowerCase())
								),
							};
						})}
					onChange={(value) => {
						selectedSystemId.current = value;
					}}
					selected={null}
				></RadioGroup>
				<InputWrapper mt={"1rem"} style={{ flex: "0" }}>
					<h4>Count</h4>
					<CustomSlider
						className={classes.inputComponent}
						min={1}
						max={10}
						value={countRef.current}
						onChangeEnd={(value) => setCount(value)}
					></CustomSlider>
				</InputWrapper>
			</Flex>
		);
	}, [inputData, countRef, classes.inputComponent, searchSystemSearch, classes.autocomplete]);

	const getOtherEchelonsSettings = useCallback(() => {
		if (modalSelectedType !== "-1" && modalSelectedType !== undefined)
			return (
				<InputWrapper>
					<h4>Name</h4>
					<TextInput
						w={"70%"}
						onBlur={(e) => {
							setNewEmptyEchelon({
								...newEmptyEchelon,
								name: e.target.value,
								type: parseInt(modalSelectedType),
								id: findHighestAgentId(settings.fp.echelons) + 1,
							});
						}}
					></TextInput>
				</InputWrapper>
			);
		else return null;
	}, [modalSelectedType, newEmptyEchelon, settings]);

	const getModalSetting = useCallback(() => {
		if (modalSelectedType === "lego") {
			return getLegoSettings();
		} else if (modalSelectedType === "0") {
			return getSystemSettings();
		} else {
			return getOtherEchelonsSettings();
		}
	}, [modalSelectedType, getLegoSettings, getSystemSettings, getOtherEchelonsSettings]);

	const fixedModalSizeStyles = `
        height: 90%;
        position: relative;

        & .mantine-Modal-body {
            height: calc(100% - 2.375rem);
            position: relative;
            display: flex;
            flex-direction: column;
            
            & > div:last-of-type {
                // padding-bottom: 1rem;
            }
        }
    `;

	return inputData.archetypes ? (
		<Popup
			title={""}
			modalButtonText={modalButtonText}
			handleSubmit={handleSubmit}
			additionalButtonClass={classes.button}
			buttonPosition="left"
			additionalContentStyles={modalSelectedType === "0" || modalSelectedType === "lego" ? fixedModalSizeStyles : ""}
		>
			<PopupBody style={{ height: "calc(100% - 4.25rem)", position: "relative" }}>
				<InputWrapper style={{ flex: "0" }}>
					<div>
						<h4>Force package</h4>
					</div>
					<NativeSelect
						data={[
							{ value: "-1", label: "Select package" },
							...Object.keys(echelonTypesData).map((key) => ({
								value: key.toString(),
								label: echelonTypesData[parseInt(key)],
							})),
							...(type > 1 ? [{ value: "lego", label: "Lego" }] : []),
						]}
						required
						w={"70%"}
						placeholder="Select package"
						value={modalSelectedType}
						onChange={(e) => {
							if (e.target.value !== "-1" && inputData.archetypes) {
								setModalSelectedType(e.target.value);
							}
						}}
					></NativeSelect>
				</InputWrapper>
				<InputWrapper style={{ height: "100%", maxHeight: "100%", position: "relative", overflow: "auto" }}>
					{getModalSetting()}
				</InputWrapper>
			</PopupBody>
		</Popup>
	) : null;
};

const useStyles = createStyles((theme) => ({
	button: {
		marginTop: "1rem",
		padding: "0.5rem",
	},

	inputComponent: {
		width: "70%",
		justifySelf: "flex-start",
		color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3],
	},

	autocomplete: {
		color: theme.colorScheme === "dark" ? "white !important" : theme.colors.light[3],

		// "& .mantine-Autocomplete-wrapper .mantine-Autocomplete-input .mantine-Autocomplete-dropdown": {
		// 	color: theme.colorScheme === "dark" ? "white !important" : theme.colors.light[3],
		// },
	},
}));

export default ForcePackagesModal;
