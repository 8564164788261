import Popup from "../Popup";
import { NativeSelect } from "@mantine/core";
import { useState } from "react";
import { BaseData, Optional } from "../../../types/filters";
import { ISystemModal, InputWrapper, PopupBody } from "../tables/SettingStaticTable";
import { toast } from "react-toastify";

const DismountModal = <T extends Optional<BaseData, "id">>({
	inputData,
	settingName,
	tableData,
	modalButtonText,
	setSetting,
}: ISystemModal<T>) => {
	const [modalSelectedType, setModalSelectedType] = useState<string>();

	const handleSubmit = () => {
		if (modalSelectedType !== undefined)
			setSetting(settingName as keyof T, [...tableData, parseInt(modalSelectedType)] as T[keyof T]);
		else {
			toast.info("Please select dismount");
			return false;
		}

		return true;
	};

	return inputData.dismounts ? (
		<Popup title={""} modalButtonText={modalButtonText} handleSubmit={handleSubmit}>
			<PopupBody>
				<InputWrapper>
					<h4>Dismount</h4>
					<NativeSelect
						required
						w={"70%"}
						placeholder="Select dismount"
						data={[
							{ value: "-1", label: "Select dismount" },
							...inputData.dismounts.map((dismount, i) => ({
								value: i.toString(),
								label: dismount.name,
							})),
						]}
						onChange={(e) => {
							e.target.value !== "-1" && inputData.dismounts
								? setModalSelectedType(inputData.dismounts[e.target.value as unknown as number].id.toString())
								: setModalSelectedType(undefined);
						}}
						defaultChecked={false}
					/>
				</InputWrapper>
			</PopupBody>
		</Popup>
	) : null;
};

export default DismountModal;
