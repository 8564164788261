import React from "react";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, Group, ModalProps } from "@mantine/core";
import { ButtonsContainer } from "./tables/SettingStaticTable";
import styled from "@emotion/styled/macro";

interface IPopup {
	children: React.ReactNode;
	title: string;
	variant?: "outline" | "filled";
	modalButtonText: string;
	additionalButtonClass?: string;
	buttonPosition?: "center" | "left" | "right";
	customButton?: React.ReactNode;
	disabled?: boolean;
	additionalContentStyles?: string;
	handleSubmit: (() => boolean) | (() => Promise<boolean>);
}

const Popup: React.FC<IPopup> = ({
	children,
	title,
	modalButtonText,
	variant,
	additionalButtonClass,
	additionalContentStyles,
	buttonPosition = "center",
	customButton,
	disabled,
	handleSubmit,
}) => {
	const [opened, { open, close }] = useDisclosure(false);

	return (
		<>
			<ModalComponent
				$hasTitle={title.length > 0}
				$additionalContentStyles={additionalContentStyles ? additionalContentStyles : ""}
				opened={opened}
				onClose={close}
				title={title}
				centered
				size={"lg"}
			>
				{children}
				<ButtonsContainer>
					<Button
						w={"fit-content"}
						onClick={async () => {
							const succeeded = await handleSubmit();
							if (succeeded) close();
						}}
					>
						{modalButtonText}
					</Button>
				</ButtonsContainer>
			</ModalComponent>

			<Group position={buttonPosition}>
				{customButton ? (
					<div onClick={() => !disabled && open()}>{customButton}</div>
				) : (
					<Button onClick={open} variant={variant ? variant : "filled"} className={additionalButtonClass}>
						{modalButtonText}
					</Button>
				)}
			</Group>
		</>
	);
};

const ModalComponent = styled(({ $hasTitle, $additionalContentStyles, ...rest }: ModalProps & { $hasTitle: boolean; $additionalContentStyles: string }) => (
	<Modal {...rest} opened={rest.opened} onClose={rest.onClose} />
))`
	width: 50%;
	& .mantine-Overlay-root {
		width: 100vw;
		height: 100%;
	}

	& .mantine-Modal-root {
		padding: 0;
	}

	& .mantine-Modal-content {
		background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0])};
		padding: 1rem 1rem ${(props) => (props.$hasTitle ? "1rem" : "0")} 1rem;
		${(props) => props.$additionalContentStyles}
	}

	& .mantine-Modal-header {
		background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0])};
		padding: ${(props) => (props.$hasTitle ? "1rem" : "0")} 1rem 1rem 1rem;
	}
`;

export default Popup;
