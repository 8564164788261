import React, { useCallback, useContext, useEffect, useState } from "react";
import makeRequest from "../../../common/makeRequest";
import Preloader from "../../../components/common/Preloader";
import SystemPage from "../../../components/system-page/SystemPage";
import { EffectorData, FiltersData, ListForcePackageData, InputData, Optional } from "../../../types/filters";
import { defaultSettings, effectorsSettingsTemplate } from "./defaultSettings";
import { toast } from "react-toastify";
import { AppInfoContext } from "../../../components/contexts/AppInfoContext";

const Effectors: React.FC = () => {
	const [filters, setFilters] = useState<FiltersData>();
	const [inputData, setInputData] = useState<InputData>();
	const { currentPid } = useContext(AppInfoContext);

	const adjustSettings = (settings: Optional<EffectorData, "id">) => {
		const newSettings = { ...settings };
		if (newSettings.indirect_fire !== undefined) newSettings.indirect_fire = newSettings.fire_type === "INDIRECT" ? true : false;

		return newSettings;
	};

	const initialize = useCallback(async (pid: number, fp: number | string, side: number | string) => {
		const filters = await downloadFilters(pid, fp, side);
		const input = await downloadInputData();
		setFilters(filters);
		setInputData(input);
		return true;
	}, []);

	const downloadFilters = async (pid: number, fp: number | string, side: number | string) => {
		const response = await makeRequest("effectors/get", "GET", {}, { pid: pid, fp: fp, side: side });
		const json = await response.json();
		return json as FiltersData;
	};

	const downloadSystemSettings = async (systemId: number) => {
		const response = await makeRequest(`effectors/${systemId}/get`, "GET");
		const json = await response.json();
		return json as EffectorData;
	};

	const downloadInputData = async () => {
		const response = await makeRequest("effectors/get_input_data", "GET");
		const json = await response.json();
		return json as InputData;
	};

	const addEffector = async (settings: Optional<EffectorData, "id">) => {
		const adjustedSettings: Optional<EffectorData, "id"> = adjustSettings(settings);
		const res = await makeRequest("effectors/add", "POST", adjustedSettings);
		if (res.ok) {
			toast.success("Effector added successfully!");
			const json = await res.json();
			return json.effector as EffectorData;
		}
		toast.error("Error adding effector!");
		return null;
	};

	const deleteEffector = async (settingsId: number) => {
		const res = await makeRequest(`effectors/${settingsId}/delete`, "POST", {});
		if (res.ok) {
			toast.success("Effector deleted successfully!");
			const json = await res.json();
			return json;
		}
		toast.error("Error deleting effector!");
		return null;
	};

	const saveEffector = async (settings: Optional<EffectorData, "id">) => {
		const adjustedSettings: Optional<EffectorData, "id"> = adjustSettings(settings);
		const res = await makeRequest(`effectors/${adjustedSettings.id}/save`, "POST", adjustedSettings);
		if (res.ok) {
			toast.success("Effector saved successfully!");
			const json = await res.json();
			return json as EffectorData;
		}
		toast.error("Error saving effector!");
		return null;
	};

	const duplicateEffector = async (settingsId: number) => {
		const res = await makeRequest(`effectors/${settingsId}/duplicate`, "POST", {});
		if (res.ok) {
			toast.success("Effector duplicated successfully!");
			const json = await res.json();
			return json as EffectorData;
		}
		toast.error("Error duplicating effector!");
		return null;
	};

	useEffect(() => {
		initialize(currentPid, "", "");
	}, [initialize, currentPid]);

	return filters && inputData ? (
		<SystemPage
			forcePackageData={filters.fps as ListForcePackageData[]}
			systemsData={filters.effectors as EffectorData[]}
			inputData={inputData}
			initializeFilters={initialize}
			downloadSystemSettings={downloadSystemSettings}
			systemSettingsTemplate={effectorsSettingsTemplate}
			defaultSettings={defaultSettings}
			addSystem={addEffector}
			saveSystem={saveEffector}
			deleteSystem={deleteEffector}
			duplicateSystem={duplicateEffector}
		></SystemPage>
	) : (
		<Preloader></Preloader>
	);
};

export default Effectors;
