import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Echelon, FiltersData, ForcePackageData, ForcePackagesFiltersData, InputData, Optional } from "../../types/filters";
import { AppInfoContext } from "../../components/contexts/AppInfoContext";
import makeRequest from "../../common/makeRequest";
import Preloader from "../../components/common/Preloader";
import { toast } from "react-toastify";
import ForcePackagesPage from "./force-package-page/ForcePackagesPage";
import { defaultSettings, fpSettingsTemplate } from "./defaultSettings";
import { Text } from "@mantine/core";
import { downloadForcePackageSettings } from "./downloadForcePackageSettings";

const ForcePackages: React.FC = () => {
	const [filters, setFilters] = useState<FiltersData>();
	const [inputData, setInputData] = useState<InputData>();
	const { currentPid } = useContext(AppInfoContext);
	const malformedPackagePath = useRef<string[]>([]);

	const downloadFilters = useCallback(async () => {
		const response = await makeRequest("force_packages/get", "GET", {}, { pid: currentPid });
		const json = await response.json();
		return json as FiltersData;
	}, [currentPid]);

	const initialize = useCallback(async () => {
		const filters = await downloadFilters();
		const inpData = await downloadInputData();
		setFilters(filters);
		setInputData(inpData);
		return true;
	}, [downloadFilters]);

	const downloadInputData = async () => {
		const response = await makeRequest("force_packages/get_input_data", "GET");
		const json = await response.json();
		return json as InputData;
	};

	const addForcePackage = async (settings: Optional<ForcePackageData, "id">) => {
		if (validateSettings(settings.fp.echelons)) {
			const res = await makeRequest("force_packages/add", "POST", settings, { pid: currentPid });
			if (res.ok) {
				toast.success("Force package added successfully!");
				const json = await res.json();
				return json.force_package as ForcePackageData;
			}
			toast.error("Error adding force package!");
			return null;
		} else {
			toast.warning(
				<>
					<Text weight={"bold"}>{`Missing commanders: `}</Text>
					<Text>{malformedPackagePath.current.join(" -> ")}</Text>
				</>
			);
		}
	};

	const deleteForcePackage = async (settingsId: number) => {
		const res = await makeRequest(`force_packages/${settingsId}/delete`, "POST", {});
		if (res.ok) {
			toast.success("Force package deleted successfully!");
			const json = await res.json();
			return json;
		}
		toast.error("Error deleting force package!");
		return null;
	};

	const saveForcePackage = async (settings: Optional<ForcePackageData, "id">) => {
		if (validateSettings(settings.fp.echelons)) {
			const res = await makeRequest(`force_packages/${settings.id}/save`, "POST", settings, { pid: currentPid });
			if (res.ok) {
				toast.success("Force package saved successfully!");
				const json = await res.json();
				return json as ForcePackageData;
			}
			toast.error("Error saving force package!");
			return null;
		} else {
			toast.warning(
				<>
					<Text weight={"bold"}>{`Missing commanders: `}</Text>
					<Text>{malformedPackagePath.current.join(" -> ")}</Text>
				</>
			);
		}
	};

	const duplicateForcePackage = async (settingsId: number) => {
		const res = await makeRequest(`force_packages/${settingsId}/duplicate`, "POST", {});
		if (res.ok) {
			toast.success("Force package duplicated successfully!");
			const json = await res.json();
			return json as ForcePackageData;
		}
		toast.error("Error duplicating force package!");
		return null;
	};

	const validateSettings = (settings: Echelon[], path: string[] = []) => {
		malformedPackagePath.current = [];

		for (const echelon of settings) {
			if (echelon.type > 1 && echelon.systems.length === 0) {
				malformedPackagePath.current = [...path, echelon.name];
				return false;
			}
			if (echelon.echelons.length > 0) {
				if (!validateSettings(echelon.echelons, [...path, echelon.name])) return false;
			}
		}

		return true;
	};

	useEffect(() => {
		initialize();
	}, [initialize]);

	return filters && inputData ? (
		<ForcePackagesPage
			forcePackageData={filters.force_packages as ForcePackagesFiltersData}
			inputData={inputData}
			initializeFilters={initialize}
			downloadForcePackageSettings={downloadForcePackageSettings}
			fpSettingsTemplate={fpSettingsTemplate}
			defaultSettings={defaultSettings}
			addForcePackage={addForcePackage}
			saveForcePackage={saveForcePackage}
			deleteForcePackage={deleteForcePackage}
			duplicateForcePackage={duplicateForcePackage}
		></ForcePackagesPage>
	) : (
		<Preloader></Preloader>
	);
};

export default ForcePackages;
