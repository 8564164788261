import * as Cesium from "cesium";

// invertDeletion is used to remove only lines associated with the currentSelectedAgentId 
// (if false it removes everything apart from the currentSelectedAgentId)
export const clearLines = (viewer: Cesium.Viewer, currentSelectedAgentId?: number, invertDeletion?: boolean) => {
	const entitiesToBeRemoved: Cesium.Entity[] = [];

	viewer.entities.values.forEach((entity) => {
		if (
			entity.id.includes("line") &&
			currentSelectedAgentId !== undefined &&
			(!invertDeletion ? !entity.id.includes(`line-${currentSelectedAgentId}`) : entity.id.includes(`line-${currentSelectedAgentId}`))
		) {
			entitiesToBeRemoved.push(entity);
		} else if (entity.id.includes("line") && currentSelectedAgentId === undefined) {
			entitiesToBeRemoved.push(entity);
		}
	});

	entitiesToBeRemoved.forEach((entity) => viewer.entities.remove(entity));
};
