import React, { useContext, useState } from "react";
import makeRequest from "../../common/makeRequest";
import { Box, Button, Flex, PasswordInput, TextInput, createStyles } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AppInfoContext } from "../../components/contexts/AppInfoContext";
import { ReactComponent as OptimaLogo } from "../../assets/optima.svg";
import Preloader from "../../components/common/Preloader";

const Login: React.FC = () => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const { setToken } = useContext(AppInfoContext);
	const [isLoggingIn, setIsLoggingIn] = useState(false);

	const form = useForm({
		initialValues: { username: "", password: "" },
	});

	const handleSubmit = async (
		values: {
			username: string;
			password: string;
		},
		e: React.FormEvent<HTMLFormElement>
	) => {
		e.preventDefault();
		setIsLoggingIn(true);
		const res = await makeRequest("login/", "POST", values);
		if (res.status === 401) {
			toast.error("Invalid username or password");
			setIsLoggingIn(false);
			return;
		} else {
			const json = await res.json();
			if (json.token) {
				localStorage.setItem("token", json.token);
				setToken(json.token);
				setIsLoggingIn(false);
				navigate("/");
			}
		}
	};

	return (
		<Box className={classes.formContainer}>
			<form onSubmit={form.onSubmit(handleSubmit)} className={classes.form}>
				<Flex className={classes.logoWrapper}>
					<OptimaLogo />
				</Flex>
				<TextInput label="Username" placeholder="Enter username" {...form.getInputProps("username")} />
				<PasswordInput mt="sm" label="Password" placeholder="Enter password" {...form.getInputProps("password")} />
				<Button type="submit" mt="lg" w={"100%"} disabled={isLoggingIn}>
					Login
				</Button>
			</form>
			{isLoggingIn ? (
				<Flex className={classes.overlay}>
					<Preloader additionalClasses={classes.loginPreloader}></Preloader>
				</Flex>
			) : null}
			<ToastContainer
				position="bottom-left"
				className={"notification"}
				toastClassName={"toast-body"}
				bodyClassName={"toast-body"}
				progressClassName={"toast-progress"}
				limit={3}
				autoClose={2000}
			/>
		</Box>
	);
};

const useStyles = createStyles((theme) => ({
	formContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: "100vh",
		width: "100vw",
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.light[1],
	},

	form: {
		position: "relative",
		maxWidth: "320px",
		width: "100%",

		"& .mantine-InputWrapper-label": {
			color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
		},

		"& .mantine-TextInput-input": {
			color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3],
		},

		"& .mantine-PasswordInput-innerInput": {
			color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3],
		},
	},

	logoWrapper: {
		position: "absolute",
		top: "-8rem",
		maxWidth: "320px",
		width: "320px",
	},

	loginPreloader: {
		// position: "absolute",
		// bottom: "20%",
	},

	overlay: {
		position: "absolute",
		alignItems: "center",
		justifyContent: "center",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.light[9],
		opacity: 0.8,
		zIndex: 100,
	},
}));

export default Login;
