import { Button, Flex, createStyles, useMantineTheme } from "@mantine/core";
import * as Cesium from "cesium";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AppInfoContext } from "../../../../../components/contexts/AppInfoContext";
import { getCenterFromCartesians } from "../functions/getCenterFromCartesians";
import { BehaviorParameter, ListForcePackageData } from "../../../../../types/filters";
import CustomSlider from "../../../../../components/common/CustomSlider";
import { clearLines } from "../functions/clearLines";
import { clearPolygons } from "../functions/clearPolygons";
import { clearCircles } from "../functions/clearCircles";
import { BehaviorEditiorContext } from "../contexts/BehaviorEditiorContext";

interface IRadiusLayer {
	viewer: Cesium.Viewer;
	defaultValue: number;
	systemSettings: ListForcePackageData & {
		cost: string | number;
		symbol: string;
	};
	voronoisDataSource: Cesium.GeoJsonDataSource | null | undefined;
	systemIds: { agent_id: number; system_id: number; is_commander: boolean };
	isEditionModeOn: boolean;
	elementOnWhichInputDepends?: BehaviorParameter;
	previewMode?: boolean;
	queueInputIndex?: number; // if belongs to queue input
	onChange: (value: number) => void;
	setLayerControls?: (value: JSX.Element | null) => void;
}

const RadiusLayer: React.FC<IRadiusLayer> = ({
	viewer,
	defaultValue,
	systemSettings,
	voronoisDataSource,
	isEditionModeOn,
	systemIds,
	elementOnWhichInputDepends,
	previewMode,
	queueInputIndex,
	onChange,
	setLayerControls,
}) => {
	const theme = useMantineTheme();
	const { isSuperuser } = useContext(AppInfoContext);
	const { updateAllEntities } = useContext(BehaviorEditiorContext);
	const { classes } = useStyles();
	const [currentValue, setCurrentValue] = useState(defaultValue);
	const [currentlySelecting, setCurrentlySelecting] = useState(false);

	const chosenColor = useMemo(
		() => new Cesium.ColorMaterialProperty(Cesium.Color.fromCssColorString(theme.colors.appMainColor[5]).withAlpha(0.8)),
		[theme]
	);

	const updateCircle = useCallback(
		(radius: number) => {
			const exists = viewer.entities.getById("circle" + systemIds.agent_id + (queueInputIndex ? `-${queueInputIndex}` : ""));

			if (elementOnWhichInputDepends) {
				const destinationEntity = voronoisDataSource?.entities.values.find(
					(entity) => entity.id === `spm-cell-${elementOnWhichInputDepends.value}`
				);
				const positions: Cesium.Cartesian3[] = destinationEntity?.polygon?.hierarchy
					? destinationEntity?.polygon?.hierarchy.getValue(new Cesium.JulianDate(1, 1)).positions
					: undefined;
				const areaCenter = positions ? getCenterFromCartesians(positions) : undefined;

				if (exists) {
					viewer.entities.remove(exists);
				}

				if (positions && areaCenter) {
					const circle = viewer.entities.add({
						id: "circle" + systemIds.agent_id + (queueInputIndex ? `-${queueInputIndex}` : ""),
						position: Cesium.Cartesian3.fromRadians(areaCenter.longitude, areaCenter.latitude, areaCenter.height),
						ellipse: {
							semiMinorAxis: radius,
							semiMajorAxis: radius,
							material: chosenColor,
						},
					});
					updateAllEntities(systemIds.agent_id.toString() + (queueInputIndex ? `-${queueInputIndex}` : ""), "circle", circle);
				}
			}
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[viewer, elementOnWhichInputDepends, voronoisDataSource, chosenColor, systemIds, queueInputIndex]
	);

	const toggleEditionMode = useCallback(() => {
		if (!previewMode && !currentlySelecting) {
			setCurrentlySelecting(true);
			clearLines(viewer, systemIds.agent_id);
			if (elementOnWhichInputDepends && elementOnWhichInputDepends.value) {
				clearPolygons(viewer, elementOnWhichInputDepends.value as number);
			}
			clearCircles(viewer, systemIds.agent_id.toString());
		}
		if (currentlySelecting) {
			setCurrentlySelecting(false);
		}
	}, [previewMode, currentlySelecting, elementOnWhichInputDepends, systemIds, viewer]);

	useEffect(() => {
		if (voronoisDataSource && viewer) {
			updateCircle(defaultValue);
		}
	}, [viewer, voronoisDataSource, defaultValue, updateCircle]);

	// update circle if elementOnWhichInputDepends is changed (polygon changed -> need to reposition circle center)
	useEffect(() => {
		if (elementOnWhichInputDepends && elementOnWhichInputDepends.value) {
			updateCircle(defaultValue);
		}
	}, [elementOnWhichInputDepends, defaultValue, updateCircle]);

	// Setup layer controls
	useEffect(() => {
		if (currentlySelecting && setLayerControls) {
			setLayerControls(
				<Flex
					style={{
						position: "absolute",
						bottom: "0",
						right: "0",
						padding: "0.7rem",
						backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
						width: "100%",
						justifyContent: "space-between",
					}}
				>
					<CustomSlider
						className={classes.inputComponent}
						min={0}
						max={100000}
						step={10}
						value={currentValue}
						onChange={(value) => {
							setCurrentValue(value);
						}}
						onChangeEnd={(value) => {
							updateCircle(value);
						}}
						unit={"m"}
					></CustomSlider>

					<Button
						onClick={() => {
							onChange(currentValue);
							setCurrentlySelecting(false);
						}}
						disabled={!isSuperuser}
					>
						Confirm
					</Button>
				</Flex>
			);
		} else if (setLayerControls) {
			setLayerControls(null);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuperuser, currentlySelecting, currentValue, updateCircle]);

	return !previewMode ? (
		<Button
			disabled={
				(elementOnWhichInputDepends !== undefined && elementOnWhichInputDepends.value === -1) ||
				(isEditionModeOn && !currentlySelecting)
			}
			onClick={toggleEditionMode}
		>
			{currentlySelecting ? "Stop setting" : "Start setting"}
		</Button>
	) : null;
};

const useStyles = createStyles((theme) => ({
	inputComponent: {
		width: "70%",
		justifySelf: "flex-start",
	},
}));

export default RadiusLayer;
