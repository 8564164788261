import { toast } from "react-toastify";
import makeRequest from "../../common/makeRequest";
import { ForcePackageData } from "../../types/filters";

export const downloadForcePackageSettings = async (systemId: number) => {
	const response = await makeRequest(`force_packages/${systemId}/get`, "GET");
	if (response.ok) {
		const json = await response.json();
		return json as ForcePackageData;
	}
	toast.error("Error downloading force package settings!");
	return null;
};
