import React, { useEffect, useState } from "react";
import { Button, CopyButton, Flex, Table } from "@mantine/core";
import { Archetype, BaseData, InputData, Optional, SystemSettingsObjectType } from "../../../types/filters";
import styled from "@emotion/styled/macro";
import { handleOnlyNumberKeyDown } from "../../../common/handleOnlyNumberKeyDown";
import { toast } from "react-toastify";
import { useTimeout } from "@mantine/hooks";

interface ISettingTable<T extends Optional<BaseData, "id">> {
	// The name of the setting being edited
	settingName: keyof T;
	// Whether the table cells are editable
	editable: boolean;
	// The data to display in the table
	tableData: Array<Record<string, number | string>> | Record<string, number | string>;
	// The template for the system settings
	template: SystemSettingsObjectType;
	// Input data for the setting
	inputData: InputData;
	// The type of values in the table
	valueType: "number" | "string";
	// A function to update the setting with the new data
	setSetting: (settingName: keyof T, value: T[keyof T]) => void;
}

const SettingTable = <T extends Optional<BaseData, "id">>({
	settingName,
	editable,
	tableData,
	template,
	inputData,
	setSetting,
	valueType = "number",
}: ISettingTable<T>) => {
	const [formatedTableData, setFormatedTableData] = useState<Array<Record<string, number | string>>>([]);
	const [pasted, setPasted] = useState(false);
	const { start } = useTimeout(() => setPasted(false), 1000);

	// Sometimes data is not array so we need to embed it in one
	useEffect(() => {
		if (Array.isArray(tableData)) setFormatedTableData(tableData);
		else setFormatedTableData([tableData]);
	}, [tableData]);

	return (
		<TableWrapper>
			<StyledTable striped withBorder withColumnBorders>
				<thead>
					<tr>
						{template.columns && [
							...Object.keys(template.columns).map((key) => (
								<th id={key} key={key}>
									{template.columns ? template.columns[key] : "UnknownHeader"}
								</th>
							)),
						]}
					</tr>
				</thead>
				<tbody>
					{formatedTableData.map((row, rowIndex) => {
						return (
							<tr key={`Row${rowIndex}`}>
								{[
									...[
										Object.keys(template.columns || row).map((cellKey) => {
											let value: string | number | undefined = row[cellKey as keyof typeof row];
											let hasArchetype = false;

											// If the cell corresponds to an archetype, look up the archetype and display its name
											if (!template.use_archetypes || (cellKey !== "target" && cellKey !== "value")) {
												value = row[cellKey as keyof typeof row];
											} else if (inputData.archetypes) {
												const archetype = inputData.archetypes.find(
													(archetype: Archetype) => archetype.id === row[cellKey as keyof typeof row]
												);
												if (archetype) hasArchetype = true;
												value = archetype ? archetype.name : row[cellKey as keyof typeof row];
											}

											// Handle blur event to update the table data and call the setSetting prop
											const handleBlur = (e: React.FocusEvent<HTMLTableCellElement>) => {
												let newValue =
													valueType === "number"
														? parseInt(e.currentTarget.innerText)
														: e.currentTarget.innerText;

												if (valueType === "string") {
													newValue = (newValue as string).replaceAll(",", ".");
												}

												const newTableData = Array.isArray(tableData)
													? formatedTableData.map((dataRow, dataRowIndex) => {
															if (dataRowIndex === rowIndex) {
																dataRow[cellKey as keyof typeof row] = newValue;
															}
															return dataRow;
													  })
													: { ...tableData, [cellKey]: newValue };

												setSetting(settingName, newTableData as T[keyof T]);
											};

											// Set contentEditable attribute to allow editing if the cell is editable and does not correspond to an archetype
											const contentEditable = !hasArchetype && editable;

											return (
												<td
													headers={cellKey}
													key={cellKey}
													onKeyDown={(e) => handleOnlyNumberKeyDown(e, [".", ","])}
													contentEditable={contentEditable}
													suppressContentEditableWarning
													inputMode="numeric"
													onBlur={handleBlur}
												>
													{value}
												</td>
											);
										}),
									],
								]}
							</tr>
						);
					})}
				</tbody>
			</StyledTable>

			<Flex w={"100%"} justify={"center"} align={"center"} mt={"1rem"} gap={"1rem"}>
				<CopyButton value={JSON.stringify({ [settingName]: formatedTableData })}>
					{({ copied, copy }) => (
						<Button color={copied ? "teal" : "gray"} onClick={copy} variant="outline" size="xs">
							{copied ? "Copied" : "Copy"}
						</Button>
					)}
				</CopyButton>
				<Button
					variant="outline"
					color={pasted ? "teal" : "gray"}
					size="xs"
					onClick={() => {
						navigator.clipboard.readText().then((text) => {
							try {
								const parsedText = JSON.parse(text);
								if (parsedText[settingName]) {
									setPasted(true);
									setSetting(settingName, parsedText[settingName] as T[keyof T]);
									start();
								} else {
									toast.error("Data type does not match");
								}
							} catch (e) {
								console.error("Invalid JSON");
							}
						});
					}}
				>
					{pasted ? "Pasted" : "Paste"}
				</Button>
			</Flex>
		</TableWrapper>
	);
};

const StyledTable = styled(Table)`
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[1])};
	border-collapse: separate;
	border-radius: 0.25rem;
	border-spacing: 0;
	width: 100%;

	& th,
	td {
		color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])} !important;
		border-left: none !important;
	}
`;

const TableWrapper = styled(Flex)`
	width: 100%;
	flex-direction: column;
`;

export default SettingTable;
