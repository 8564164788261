import { Accordion, createStyles, Text, useMantineTheme } from "@mantine/core";
import { Echelon, ForcePackageData, ForcePackagesFiltersData, InputData, Optional, ParentEchelon } from "../../../../types/filters";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppInfoContext } from "../../../../components/contexts/AppInfoContext";
import styled from "@emotion/styled/macro";
import NestedAccordionsControl from "./NestedAccordionControl";
import SystemElement from "./SystemElement";
import updateEchelon from "../../../../common/updateEchelon";
import ForcePackagesModal from "../ForcePackagesModal";

interface IForcePackageAccordions {
	forcePackage: ParentEchelon | Echelon;
	settings: Optional<ForcePackageData, "id">;
	pathToElement: number[];
	inputData: InputData;
	isTopLevel?: boolean;
	forcePackageFiltersData: ForcePackagesFiltersData;
	setSetting: (settingName: string, value: ForcePackageData[keyof ForcePackageData]) => void;
}

const NestedAccordions: React.FC<IForcePackageAccordions> = ({
	forcePackage,
	settings,
	isTopLevel = false,
	pathToElement,
	inputData,
	forcePackageFiltersData,
	setSetting,
}) => {
	const theme = useMantineTheme();
	const { classes } = useStyles();
	const { isSuperuser } = useContext(AppInfoContext);
	const transitionDuration = useMemo(() => 500, []);
	const [echelonName, setEchelonName] = useState<string>(forcePackage.name);
	const [value, setValue] = useState<string[]>(isTopLevel ? [echelonName + "0"] : []);

	// After closing the item there would be jump because of conditional rendering immediately removing the echelon name from the value state
	// so we setTimeout for setting itemsRenderPanelValues corresponding echelon name property to false for the transition duration to prevent this
	const [itemsRenderPanelValues, setItemsRenderPanelValues] = useState<Record<string, boolean>>(
		forcePackage.echelons.reduce((acc, echelon, ix) => {
			acc[`${echelon.name}${ix}`] = isTopLevel ? true : false;
			return acc;
		}, {} as Record<string, boolean>)
	);

	useEffect(() => {
		setEchelonName(forcePackage.name);
		isTopLevel && setValue([forcePackage.name + "0"]);
	}, [forcePackage, isTopLevel]);

	const setEchelonSetting = (value: Echelon | null, elementIndex: number, duplicate?: boolean, changeTopLevelName?: boolean) => {
		const { name, type } = settings.fp;
		const newSetting = {
			fp: {
				echelons: updateEchelon(settings.fp as Echelon, [...pathToElement, elementIndex], value, duplicate).echelons,
				name: changeTopLevelName && value ? value.name : name,
				type: type,
			},
		};

		if (changeTopLevelName && value) setSetting("name", value.name);
		setSetting("fp", newSetting.fp);
	};

	const handleOnChange = (val: string[]) => {
		if (isTopLevel) return;
		if (val.length < value.length) {
			const name = value.filter((name) => !val.includes(name))[0];
			setTimeout(() => {
				setItemsRenderPanelValues((current) => {
					const newItemsRenderPanelValues = { ...current };
					newItemsRenderPanelValues[name] = false;
					return newItemsRenderPanelValues;
				});
			}, transitionDuration);
		} else {
			const name = val.filter((name) => !value.includes(name))[0];
			setItemsRenderPanelValues((current) => {
				const newItemsRenderPanelValues = { ...current };
				newItemsRenderPanelValues[name] = true;
				return newItemsRenderPanelValues;
			});
		}
		setValue(val);
	};

	return (
		<Accordion
			className={classes.accordionContainer}
			defaultValue={value}
			value={value}
			mt={isTopLevel ? "0" : "1rem"}
			chevronSize={isTopLevel ? "0" : "1rem"}
			transitionDuration={transitionDuration}
			multiple
			onChange={handleOnChange}
		>
			{forcePackage.echelons.map((echelon, echelonIx) => {
				return (
					<AccordionItem
						key={echelonIx}
						value={`${echelon.name}${echelonIx}`}
						className={isTopLevel ? classes.topLevelAccordionItem : ""}
					>
						<NestedAccordionsControl
							echelon={echelon}
							isTopLevel={isTopLevel}
                            settings={settings}
							setSetting={(value, duplicate?, changeTopLevelName?) =>
								setEchelonSetting(value, echelonIx, duplicate, changeTopLevelName)
							}
						/>
						<AccordionPanel>
							{/* Conditional panel rendering to prevent from loading all accordions at once */}
							{value.includes(`${echelon.name}${echelonIx}`) || itemsRenderPanelValues[`${echelon.name}${echelonIx}`] ? (
								<>
									<NestedAccordions
										forcePackage={echelon as Echelon}
										settings={settings}
										isTopLevel={false}
										setSetting={setSetting}
										pathToElement={[...pathToElement, echelonIx]}
										inputData={inputData}
										forcePackageFiltersData={forcePackageFiltersData}
									></NestedAccordions>

									{echelon.type !== 1 && echelon.systems.length > 0 && (
										<Text className={classes.commanderText}>
											Commanders<hr></hr>
										</Text>
									)}

									{echelon.systems.map((system, systemIx) => {
										const isDark = theme.colorScheme === "dark";

										const color =
											(settings.systems[system.system_id].side as number) === 0
												? isDark
													? theme.colors.blue[4]
													: theme.colors.blue[8]
												: isDark
												? theme.colors.accentRed[4]
												: theme.colors.accentRed[5];

										return (
											<SystemElement
												color={color}
												key={systemIx}
												systemId={system.system_id}
												agentId={system.agent_id}
												settings={settings}
												isCommander={echelon.type !== 1}
												echelon={echelon}
												setSetting={(value, duplicate?, changeTopLevelName?) =>
													setEchelonSetting(value, echelonIx, duplicate, changeTopLevelName)
												}
											/>
										);
									})}

									{isSuperuser && (
										<ForcePackagesModal
											settings={settings}
											inputData={inputData}
											modalButtonText={"Add new"}
											type={echelon.type}
											echelon={echelon}
											setEchelon={(value, duplicate?, changeTopLevelName?) =>
												setEchelonSetting(value, echelonIx, duplicate, changeTopLevelName)
											}
											setSetting={setSetting}
											forcePackageFiltersData={forcePackageFiltersData}
										/>
									)}
								</>
							) : null}
						</AccordionPanel>
					</AccordionItem>
				);
			})}
		</Accordion>
	);
};

export default NestedAccordions;

const useStyles = createStyles((theme) => ({
	accordionContainer: {
		width: "100%",
		borderBottom: "none",

		"& > :not(:first-of-type)": {
			marginTop: "1rem",
		},
	},

	topLevelAccordionItem: {
		backgroundColor: theme.colors.appMainColor[5],
	},

	commanderText: {
		textAlign: "center",
		marginTop: "1rem",
		color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.dark[3],

		"& > hr": {
			margin: "0.5rem 0",
			backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[5],
			border: "none",
			padding: "1px",
		},
	},
}));

const AccordionItem = styled(Accordion.Item)`
	border: ${({ theme }) => `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[5]}`};
`;

const AccordionPanel = styled(Accordion.Panel)`
	& .mantine-Accordion-content {
		padding: 0 1rem 1rem 1rem;
	}
`;
