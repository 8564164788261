import { Accordion, AccordionControlProps, Text, createStyles } from "@mantine/core";
import { AssessmentScenarioData, BehaviorParameter, Echelon, InputData, Optional } from "../../../../../types/filters";
import styled from "@emotion/styled/macro";
import { useEffect, useState } from "react";

interface AdditionalProps {
	parentEchelon: Echelon;
	echelon: Echelon;
	isTopLevel: boolean;
	settings: Optional<AssessmentScenarioData, "id">;
	inputData: InputData;
	behaviorSettingsProperty: "blue_behavior_settings" | "red_behavior_settings";
	updateSelectedEchelonId: (id: number | null) => void;
	setSetting: (value: Record<number, BehaviorParameter[]> | null, duplicate?: boolean, changeTopLevelName?: boolean) => void;
}

type IAccordionsControl = AccordionControlProps & AdditionalProps;

const BehaviorNestedAccordionControl: React.FC<IAccordionsControl> = ({
	isTopLevel,
	parentEchelon,
	echelon,
	settings,
	inputData,
	behaviorSettingsProperty,
	updateSelectedEchelonId,
	setSetting,
	...nativeProps
}) => {
	const { classes } = useStyles();
	const [allSystems, setAllSystems] = useState<{ agent_id: number; system_id: number }[]>();

	useEffect(() => {
		const getSystemsIds = (parentEchelon: Echelon): { agent_id: number; system_id: number }[] => {
			const systems: { agent_id: number; system_id: number }[] = [];

			if (parentEchelon.systems) {
				parentEchelon.systems.forEach((system) => {
					systems.push(system);
				});
			}

			if (parentEchelon.echelons) {
				parentEchelon.echelons.forEach((echelon) => {
					systems.push(...getSystemsIds(echelon));
				});
			}

			return systems;
		};

		if (isTopLevel && !allSystems) {
			const systems = getSystemsIds(parentEchelon);
			setAllSystems(systems);
		}
	}, [parentEchelon, isTopLevel, allSystems]);

	return (
		<AccordionControl
			{...nativeProps}
			sx={{ display: "flex", alignItems: "center" }}
			className={isTopLevel ? classes.topLevelAccordionControl : classes.accordionControl}
			autoCorrect="false"
			spellCheck="false"
			value={echelon.name}
		>
			<Text
				className={classes.accordionText}
				onClick={(e) => {
					e.stopPropagation();
					updateSelectedEchelonId(echelon.id);
				}}
				w={"fit-content"}
			>
				{echelon.name}
			</Text>
		</AccordionControl>
	);
};

const useStyles = createStyles((theme) => ({
	buttonsContainer: {
		display: "inline-flex !important",
		borderRight: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.light[5]}`,
		backgroundColor: "inherit",
		height: "100%",
		paddingRight: "0.5rem",
	},

	topLevelAccordionControl: {
		backgroundColor: `${theme.colors.appMainColor[5]} !important`,
		color: `${theme.colors.accentRed[0]} !important`,

		":hover": {
			backgroundColor: `${theme.colors.appMainColor[6]} !important`,
			cursor: "pointer !important",
		},
	},

	accordionControl: {},

	accordionText: {
		width: "fit-content",
		display: "inline",
		border: `0.125rem solid transparent`,
		padding: ".1rem 0.5rem",
		borderRadius: "0.25rem",

		"&:focus-visible": {
			outline: "none",
			border: `0.125rem solid ${theme.colors.accentRed[6]}`,
		},

		"&:hover": {
			textDecoration: "underline",
		},
	},
}));

const AccordionControl = styled(Accordion.Control)`
	border-bottom: none;
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[1])};
	text-align: left;
	justify-content: space-between;
	cursor: pointer;

	& .mantine-Accordion-label {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	& .mantine-Accordion-chevron {
		margin-left: 1rem;
	}
`;

export default BehaviorNestedAccordionControl;
