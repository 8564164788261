import React from "react";
import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router-dom";
import { Button, createStyles } from "@mantine/core";
import glossary from "../../assets/glossary.pdf";
import optima_sdd from "../../assets/optima_sdd.pdf";
import optima_ai from "../../assets/optima_ai.pdf";

const ResourcesMenu: React.FC = () => {
	const { classes } = useStyles();
	const navigate = useNavigate();

	return (
		<MenuContainer>
			<Button className={classes.menuBtn} component="a" href={glossary} target="_blank">
				{"Glossary"}
			</Button>
			<Button className={classes.menuBtn} component="a" href={optima_sdd} target="_blank">
				{"Softwre design document"}
			</Button>
			<Button className={classes.menuBtn} component="a" href={optima_ai} target="_blank">
				{"Report: OPTIMA, Artificial Intelligence and Trends in Milsim"}
			</Button>
			<Button className={classes.menuBtn} onClick={() => navigate("symbols")}>
				{"Military symbols"}
			</Button>
		</MenuContainer>
	);
};

const useStyles = createStyles((theme) => ({
	menuBtn: {
		margin: "0.5rem",

		"& .mantine-Button-label": {
			whiteSpace: "break-spaces",
			textAlign: "center",
		},
	},
}));

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
	flex-wrap: wrap;
	padding: 0 10rem;
	justify-content: center;

	& button, a {
		width: 12rem;
		height: 12rem;
	}

	@media (max-width: 768px) {
		padding: 0;
		& button, a {
			width: 12rem;
			height: 12rem;
		}
	}
}`;

export default ResourcesMenu;
