import React, { useContext, useRef, useState } from "react";
import styled from "@emotion/styled/macro";
import { FiltersData, ListForcePackageData } from "../../types/filters";
import { Accordion, Select, useMantineTheme } from "@mantine/core";
import { AppInfoContext } from "../contexts/AppInfoContext";

interface IFilters {
	fps: ListForcePackageData[] | null;
	initializeFilters: (pid: number, fp: number | "", side: number | "") => Promise<FiltersData>;
	setFiltersLoading: (value: boolean) => void;
}

const Filters: React.FC<IFilters> = ({ fps, initializeFilters, setFiltersLoading }) => {
	const [value, setValue] = useState<string | null>("Filters");
	const { projects, currentPid } = useContext(AppInfoContext);
	const theme = useMantineTheme();

	const relativePid = useRef<number>(currentPid);
	const currentFp = useRef<number | "">("");
	const currentSide = useRef<number | "">("");

	return (
		<Accordion radius={"0.5rem"} value={value} onChange={setValue}>
			<AccordionItem value="Filters">
				<Accordion.Control
					style={{
						borderBottomLeftRadius: value ? "0" : "0.5rem",
						borderBottomRightRadius: value ? "0" : "0.5rem",
						...(value && {
							backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[4],
						}),
					}}
				>
					<h4>{"Filters"}</h4>
				</Accordion.Control>
				<Accordion.Panel>
					<SelectComponent
						label="Project"
						placeholder="Select project"
						defaultValue={relativePid.current.toString()}
						data={
							projects
								? projects.map((project) => {
										return { value: project.id.toString(), label: project.name };
								  })
								: []
						}
						clearable
						onChange={async (value) => {
							relativePid.current = value ? parseInt(value as string) : 0;
							setFiltersLoading(true);
							initializeFilters(relativePid.current, currentFp.current, currentSide.current).then(() => {
								setFiltersLoading(false);
							});
						}}
					/>
					<SelectComponent
						label="Force package"
						placeholder="Select force package"
						data={
							fps
								? fps.map((project) => {
										return { value: project.id.toString(), label: project.name };
								  })
								: []
						}
						allowDeselect
						clearable
						searchable
						onChange={async (value) => {
							currentFp.current = value ? parseInt(value) : "";
							setFiltersLoading(true);
							initializeFilters(relativePid.current, currentFp.current, currentSide.current).then(() => {
								setFiltersLoading(false);
							});
						}}
					/>

					<SelectComponent
						label="Side"
						placeholder="Select side"
						data={[
							{ value: "0", label: "BLUE" },
							{ value: "1", label: "RED" },
							{ value: "2", label: "UNASSIGNED" },
						]}
						onChange={(value) => {
							currentSide.current = value ? parseInt(value) : "";
							setFiltersLoading(true);
							initializeFilters(relativePid.current, currentFp.current, currentSide.current).then(() => {
								setFiltersLoading(false);
							});
						}}
						allowDeselect
						clearable
					/>
				</Accordion.Panel>
			</AccordionItem>
		</Accordion>
	);
};

const AccordionItem = styled(Accordion.Item)`
	border-radius: 0.5rem;
	border-bottom: none;

	& .mantine-Accordion-control {
		border-radius: 0.5rem;
	}

	& .mantine-Accordion-control:hover {
		background-color: ${({ theme }) =>
			theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.light[4]} !important;
	}

	& .mantine-Accordion-content {
		// border-top: 1px solid ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.light[4])};
		// padding: 0 2rem 2rem 2rem;
		padding: 2rem;
	}
`;

const SelectComponent = styled(Select)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])};
	margin-top: 0.5rem;

	&:first-of-type {
		margin-top: 0;
	}

	& .mantine-Select-label {
		color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 30%;
	}

	@media (max-width: 768px) {
		width: 100%;
		& > div {
			width: 100%;
		}

		& .mantine-Select-label {
			display: none;
			word-break: normal;
			margin-right: 0.5rem;
		}
	}
`;

export default Filters;
