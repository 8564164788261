import { Checkbox, Group } from "@mantine/core";
import React from "react";

interface IMultiCheckbox {
	keyProp: string;
	options: string[];
	checkedList: string[];
	additionalClassName?: string;
	onChange: (checkedList: string[]) => void;
}


const MultiCheckbox: React.FC<IMultiCheckbox> = ({ keyProp, options, checkedList, additionalClassName, onChange }) => {
	return (
		<Group key={keyProp} className={additionalClassName}>
			{(options as Array<any>).map((element, i) => (
				<Checkbox
					key={i}
					label={element.split("_").join(" ")}
					defaultChecked={checkedList.includes(element)}
					onChange={(value) => {
						if (value.target.checked) {
							onChange([...checkedList, element]);
						} else onChange(checkedList.filter((checkboxName) => checkboxName !== element));
					}}
				></Checkbox>
			))}
		</Group>
	);
};

export default MultiCheckbox;
