import { Button, useMantineTheme } from "@mantine/core";
import * as Cesium from "cesium";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppInfoContext } from "../../../../../components/contexts/AppInfoContext";
import Symbol from "milsymbol";
import { getCenterFromCartesians } from "../functions/getCenterFromCartesians";
import { BehaviorParameter, ListForcePackageData } from "../../../../../types/filters";
import { getRandomPositionFromCartesians } from "../functions/getRandomPositionFromCartesians";
import { toast } from "react-toastify";
import { clearCircles } from "../functions/clearCircles";
import { BehaviorEditiorContext } from "../contexts/BehaviorEditiorContext";

interface IVoronoisLayer {
	viewer: Cesium.Viewer;
	defaultValue: number;
	systemSettings: ListForcePackageData & {
		cost: string | number;
		symbol: string;
	};
	systemIds: { agent_id: number; system_id: number; is_commander: boolean };
	isEditionModeOn: boolean;
	previewMode?: boolean;
	elementOnWhichInputDepends?: BehaviorParameter;
	queueInputIndex?: number; // if belongs to queue input. If it does, it will be used to identify the entity in allEntities list (cause there can be multiple same inputs and we need to distiquish them)
	onChange: (value: number) => void;
	setLayerControls?: (value: JSX.Element | null) => void;
}

const VoronoisLayer: React.FC<IVoronoisLayer> = ({
	viewer,
	defaultValue,
	systemSettings,
	previewMode,
	systemIds,
	isEditionModeOn,
	queueInputIndex,
	elementOnWhichInputDepends,
	onChange,
	setLayerControls,
}) => {
	const theme = useMantineTheme();
	const { isSuperuser } = useContext(AppInfoContext);
	const { allEntities, updateAllEntities, resetAgentIdEntities } = useContext(BehaviorEditiorContext);
	const [currentlySelecting, setCurrentlySelecting] = useState(false);

	const selectedEntityRef = useRef<Cesium.Entity | null>(null);
	const [currentEntityId, setCurrentEntityId] = useState<number | null>(defaultValue === -1 ? null : defaultValue);
	const [currentArea, setCurrentArea] = useState<(Cesium.Entity & { properties: Record<string, any> }) | null>(null);

	const color = useMemo(() => new Cesium.ColorMaterialProperty(Cesium.Color.WHITE.withAlpha(0.2)), []);
	const chosenColor = useMemo(
		() => new Cesium.ColorMaterialProperty(Cesium.Color.fromCssColorString(theme.colors.appMainColor[5]).withAlpha(0.8)),
		[theme]
	);

	const drawLine = useCallback(() => {
		const exists = viewer.entities.getById("line-" + systemIds.agent_id + (queueInputIndex ? `-${queueInputIndex}` : ""));
		if (exists) {
			viewer.entities.remove(exists);
		}

		if (selectedEntityRef.current?.polygon?.hierarchy && viewer.entities.getById("symbol" + systemIds.agent_id)) {
			let startingPosition = Cesium.Cartographic.fromCartesian(
				viewer.entities.getById("symbol" + systemIds.agent_id)?.position?.getValue(new Cesium.JulianDate(1, 1)) as Cesium.Cartesian3
			);

			if (elementOnWhichInputDepends && elementOnWhichInputDepends.value) {
				if (elementOnWhichInputDepends.property === "PATH") {
					const lastPosition = (elementOnWhichInputDepends.value as number[])[
						(elementOnWhichInputDepends.value as number[]).length - 1
					];
					startingPosition = getCenterFromCartesians(
						viewer?.dataSources
							?.getByName("geometry_voronois")[0]
							.entities.values.find((entity) => entity.id === `spm-cell-${lastPosition}`)
							?.polygon?.hierarchy?.getValue(new Cesium.JulianDate(1, 1)).positions
					);
				} else if (elementOnWhichInputDepends.property === "POSITION") {
					startingPosition = getCenterFromCartesians(
						viewer?.dataSources
							?.getByName("geometry_voronois")[0]
							.entities.values.find((entity) => entity.id === `spm-cell-${elementOnWhichInputDepends.value}`)
							?.polygon?.hierarchy?.getValue(new Cesium.JulianDate(1, 1)).positions
					);
				}
			}

			const selectedEntityCenter = getCenterFromCartesians(
				selectedEntityRef.current?.polygon?.hierarchy.getValue(new Cesium.JulianDate(1, 1)).positions
			);

			const line = viewer.entities.add({
				id: "line-" + systemIds.agent_id + (queueInputIndex ? `-${queueInputIndex}` : ""),
				polyline: {
					positions: [
						Cesium.Cartesian3.fromRadians(startingPosition.longitude, startingPosition.latitude, startingPosition.height),
						Cesium.Cartesian3.fromRadians(
							selectedEntityCenter.longitude,
							selectedEntityCenter.latitude,
							selectedEntityCenter.height
						),
					],
					width: 10,
					material: new Cesium.PolylineArrowMaterialProperty(Cesium.Color.BLACK.withAlpha(1)),
				},
			});

			updateAllEntities(systemIds.agent_id.toString() + (queueInputIndex ? `-${queueInputIndex}` : ""), "line", line);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewer, systemIds, elementOnWhichInputDepends, queueInputIndex]);

	const updateCurrentArea = useCallback(() => {
		const areas = viewer.dataSources?.getByName("areas")[0]?.entities?.values as (Cesium.Entity & {
			properties: Record<string, any>;
		})[];

		if (areas) {
			const currentArea = areas.find((x) => x.properties.type._value.toLowerCase().includes("staging")) || null;
			setCurrentArea(currentArea);
		}
	}, [viewer]);

	const selectPolygon = useCallback(
		(id: number | null) => {
			// Unselect previous entity
			clearCircles(viewer, systemIds.agent_id.toString() + (queueInputIndex ? `-${queueInputIndex}` : ""), true);
			if (selectedEntityRef.current && selectedEntityRef.current.polygon) {
				selectedEntityRef.current.polygon.material = color;
			}

			// Select new entity
			const entities = viewer.dataSources?.getByName("geometry_voronois")[0]?.entities?.values;
			setCurrentEntityId(id);
			selectedEntityRef.current = id !== null ? entities?.find((x) => x.id === `spm-cell-${id}`) || null : null;

			if (selectedEntityRef.current && selectedEntityRef.current.polygon) {
				selectedEntityRef.current.polygon.material = chosenColor;
				updateAllEntities(
					systemIds.agent_id.toString() + (queueInputIndex ? `-${queueInputIndex}` : ""),
					"polygon",
					selectedEntityRef.current
				);
				drawLine();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[chosenColor, color, viewer, drawLine, queueInputIndex, systemIds]
	);

	const toggleEditionMode = () => {
		if (!previewMode && !currentlySelecting) {
			setCurrentlySelecting(true);
			const queueInputKeys = Object.keys(allEntities).filter(
				(key) => key !== systemIds.agent_id.toString() && key.split("-")[0] !== systemIds.agent_id.toString()
			);
			queueInputKeys.forEach((key) => {
				resetAgentIdEntities(key, false);
			});
		}
		if (currentlySelecting) {
			selectPolygon(defaultValue === -1 ? null : defaultValue);
			setCurrentlySelecting(false);
		}
	};

	// useEffect on elementOnWhichInputDepends.value to remove last line
	useEffect(() => {
		if (queueInputIndex) {
			const exists = viewer.entities.getById("line-" + systemIds.agent_id + (queueInputIndex ? `-${queueInputIndex + 1}` : ""));
			if (exists) {
				viewer.entities.remove(exists);
			}
		}
	}, [queueInputIndex, viewer, systemIds]);

	// Update current area
	useEffect(() => {
		updateCurrentArea();
	}, [updateCurrentArea]);

	// Create symbol and draw line
	useEffect(() => {
		if (currentArea) {
			drawLine();
		}
	}, [currentArea, drawLine]);

	// Set default value
	useEffect(() => {
		selectPolygon(defaultValue === -1 ? null : defaultValue);
	}, [defaultValue, selectPolygon]);

	// Setup layer controls
	useEffect(() => {
		if (currentlySelecting && setLayerControls) {
			setLayerControls(
				<Button
					style={{ position: "absolute", bottom: "0", right: "0", margin: "1rem" }}
					onClick={() => {
						if (currentEntityId !== null) {
							selectPolygon(currentEntityId);
							onChange(currentEntityId);
							setCurrentlySelecting(false);
						} else {
							toast.warning("Please select a node or stop selecting.");
						}
					}}
					disabled={!isSuperuser}
				>
					Confirm
				</Button>
			);
		} else if (setLayerControls) {
			setLayerControls(null);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentEntityId, isSuperuser, currentlySelecting, selectPolygon]);

	// Setup event listener for selectedEntityChanged (handling cells clicking in edition mode)
	useEffect(() => {
		const setSelectedNodeId = (selectedEntity: Cesium.Entity) => {
			if (selectedEntity) {
				selectPolygon(parseInt(selectedEntity.id.split("spm-cell-")[1]));
			} else {
				selectPolygon(null);
			}
		};

		!previewMode && currentlySelecting && viewer.selectedEntityChanged.addEventListener(setSelectedNodeId);

		return () => {
			!previewMode && currentlySelecting && viewer.selectedEntityChanged.removeEventListener(setSelectedNodeId);
		};
	}, [viewer, previewMode, currentlySelecting, selectPolygon]);

	return !previewMode ? (
		<Button disabled={isEditionModeOn && !currentlySelecting} onClick={toggleEditionMode}>
			{currentlySelecting ? "Stop selecting" : "Start selecting"}
		</Button>
	) : null;
};

export default VoronoisLayer;
