import React, { useCallback, useContext, useEffect, useState } from "react";
import makeRequest from "../../../common/makeRequest";
import Preloader from "../../../components/common/Preloader";
import SystemPage from "../../../components/system-page/SystemPage";
import { FiltersData, ListForcePackageData, InputData, Optional, SensorData } from "../../../types/filters";
import { defaultSettings, sensorsSettingsTemplate } from "./defaultSettings";
import { toast } from "react-toastify";
import { AppInfoContext } from "../../../components/contexts/AppInfoContext";

const Sensors: React.FC = () => {
	const [filters, setFilters] = useState<FiltersData>();
	const [inputData, setInputData] = useState<InputData>();
	const { currentPid } = useContext(AppInfoContext);

	const initialize = useCallback(async (pid: number, fp: number | string, side: number | string) => {
		const filters = await downloadFilters(pid, fp, side);
		const inpData = await downloadInputData();
		setFilters(filters);
		setInputData(inpData);
		return true;
	}, []);

	const downloadFilters = async (pid: number, fp: number | string, side: number | string) => {
		const response = await makeRequest("sensors/get", "GET", {}, { pid: pid, fp: fp, side: side });
		const json = await response.json();
		return json as FiltersData;
	};

	const downloadSensorSettings = async (systemId: number) => {
		const response = await makeRequest(`sensors/${systemId}/get`, "GET");
		const json = await response.json();
		return json as SensorData;
	};

	const downloadInputData = async () => {
		const response = await makeRequest("sensors/get_input_data", "GET");
		const json = await response.json();
		return json as InputData;
	};

	const addSensor = async (settings: Optional<SensorData, "id">) => {
		const res = await makeRequest("sensors/add", "POST", settings);
		if (res.ok) {
			toast.success("Sensor added successfully!");
			const json = await res.json();
			return json.sensor as SensorData;
		}
		toast.error("Error adding sensor!");
		return null;
	};

	const deleteSensor = async (settingsId: number) => {
		const res = await makeRequest(`sensors/${settingsId}/delete`, "POST", {});
		if (res.ok) {
			toast.success("Sensor deleted successfully!");
			const json = await res.json();
			return json;
		}
		toast.error("Error deleting sensor!");
		return null;
	};

	const saveSensor = async (settings: Optional<SensorData, "id">) => {
		if (!validateSettings(settings)) return null;

		const res = await makeRequest(`sensors/${settings.id}/save`, "POST", settings);
		if (res.ok) {
			toast.success("Sensor saved successfully!");
			const json = await res.json();
			return json as SensorData;
		}
		toast.error("Error saving sensor!");
		return null;
	};

	const duplicateSensor = async (settingsId: number) => {
		const res = await makeRequest(`sensors/${settingsId}/duplicate`, "POST", {});
		if (res.ok) {
			toast.success("Sensor duplicated successfully!");
			const json = await res.json();
			return json as SensorData;
		}
		toast.error("Error duplicating sensor!");
		return null;
	};

	const validateSettings = (settings: Optional<SensorData, "id">) => {
		if (settings?.rcs_probability?.some((el) => el.range < 0)) {
			toast.error("You haven't set all the range values!");
			return false;
		}
		return true;
	};

	useEffect(() => {
		initialize(currentPid, "", "");
	}, [initialize, currentPid]);

	return filters && inputData ? (
		<SystemPage
			forcePackageData={filters.fps as ListForcePackageData[]}
			systemsData={filters.sensors as SensorData[]}
			inputData={inputData}
			initializeFilters={initialize}
			downloadSystemSettings={downloadSensorSettings}
			systemSettingsTemplate={sensorsSettingsTemplate}
			defaultSettings={defaultSettings}
			addSystem={addSensor}
			saveSystem={saveSensor}
			deleteSystem={deleteSensor}
			duplicateSystem={duplicateSensor}
		></SystemPage>
	) : (
		<Preloader></Preloader>
	);
};

export default Sensors;
