import { createGlobalStyle } from "styled-components";
import preloaderStyles from "./styles/preloader";
import fadeInOut from "./styles/fadeInOut";
import { Global, rem } from "@mantine/core";

export function MantineGlobal() {
	return (
		<Global
			styles={(theme) => ({
				"*": {
					margin: 0,
					padding: 0,
					outline: 0,
					boxSizing: "border-box",
					fontFamily: `Open Sans, sans-serif`,
					transition: "background-color 0.3s ease-in-out",
				},

				"html, body": {
					height: "100%",
					fontSize: "16px",
				},

				"#root": {
					margin: "0 auto",
					height: "100%",
					"--header-height": "5rem",
					"--mobile-header-height": "3.5rem",
					"--footer-height": "3rem",
					"--content-padding": "1rem",
					"--breadcrumbs-height": "3rem",
				},

				a: {
					color: "inherit",
					fontWeight: "bold",
				},

				"g.logo-text": {
					fill: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3],
				},

				"h1, h2, h3, h4, h5": {
					color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
					"&:not(:first-of-type)": {
						paddingTop: rem(2),
					},
				},

				// Select, NativeSelect

				".mantine-Select-label, .mantine-NativeSelect-label": {
					color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
				},

				".mantine-Select-input, .mantine-NativeSelect-input, .mantine-Autocomplete-input": {
					color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
					border: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[4]}`,
					backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
				},

				".mantine-Select-input:focus": {
					border: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2]}`,
				},

				".mantine-Select-item": {
					color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
				},

				// Slider

				".mantine-Slider-track": {
					backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
				},

				// Checkbox

				".mantine-Checkbox-input:hover": {
					cursor: "pointer",
				},

				".mantine-Checkbox-label": {
					color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
				},

				// Text

				".mantine-TextInput-input": {
					color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
					backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
				},

				".mantine-NumberInput-input": {
					color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
					backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
				},

				".mantine-NumberInput-control": {
					color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
					backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
				},

				".mantine-PasswordInput-innerInput": {
					color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
					backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
				},

				// Toast

				".toast-body": {
					backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[1],
					color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[3],
				},

				".toast-progress": {
					background: theme.colors.accentRed[5],
					height: "0.25rem",
				},

				// Radio

				".mantine-Radio-labelWrapper": {
					color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2],
				},

				// Accordion

				".mantine-Accordion-item, .mantine-Accordion-control": {
					backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
					color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3],
				},

				// Graph

				".link": {
					stroke: `${theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3]} !important`,
				},

				".node": {
					fill: `${theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3]} !important`,
					color: `${theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3]} !important`,
				},

				// Button

				".mantine-Button-inner": {
					fontSize: "1rem",
				},

				".mantine-Autocomplete-dropdown, .mantine-Autocomplete-dropdown *": {
					color: `${theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2]}  !important`,
				},

				// Modal

				".mantine-Modal-header": {
					backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0],
				},
			})}
		/>
	);
}

export const GlobalStyle = createGlobalStyle`
    ${preloaderStyles}
    ${fadeInOut}
 `;
