import { NativeSelect, Slider } from "@mantine/core";
import { useState } from "react";
import { BaseData, Confounder, Optional } from "../../../types/filters";
import { ISystemModal, InputWrapper, PopupBody } from "../tables/SettingStaticTable";
import { toast } from "react-toastify";
import Popup from "../Popup";

const ConfounderModal = <T extends Optional<BaseData, "id">>({ inputData, settingName, modalButtonText, tableData, setSetting }: ISystemModal<T>) => {
	const [modalSelectedType, setModalSelectedType] = useState<string>();
	const [modalDegradationValue, setModalDegradationValue] = useState<number>(0);

	const handleSubmit = () => {
		if (modalSelectedType !== undefined)
			setSetting(
				settingName as keyof T,
				[
					...tableData,
					{
						type: modalSelectedType,
						degradation_percent: modalDegradationValue,
					} as Confounder,
				] as T[keyof T]
			);
		else {
			toast.info("Please select confounder");
			return false;
		}

		return true;
	};

	return inputData.confounders ? (
		<Popup title="" modalButtonText={modalButtonText} handleSubmit={handleSubmit}>
			<PopupBody>
				<InputWrapper>
					<h4>Confounder</h4>
					<NativeSelect
						required
						w={"70%"}
						placeholder="Select confounder"
						data={[
							{ value: "-1", label: "Select confounder" },
							...inputData.confounders.map((confounder, i) => ({
								value: i.toString(),
								label: confounder,
							})),
						]}
						onChange={(e) => {
							e.target.value !== "-1" && inputData.confounders
								? setModalSelectedType(inputData.confounders[e.target.value as unknown as number])
								: setModalSelectedType(undefined);
						}}
						defaultChecked={false}
					/>
				</InputWrapper>
				<InputWrapper>
					<h4>{"Degradation (%)"}</h4>
					<Slider
						max={100}
						w={"70%"}
						onChange={(value) => {
							setModalDegradationValue(value);
						}}
					/>
				</InputWrapper>
			</PopupBody>
		</Popup>
	) : null;
};

export default ConfounderModal;
