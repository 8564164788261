import * as Cesium from "cesium";

// invertDeletion is used to remove only circles associated with the currentSelectedAgentId
// (if false it removes everything apart from the currentSelectedAgentId)
export const clearCircles = (viewer: Cesium.Viewer, currentSelectedAgentId?: string, invertDeletion?: boolean) => {
	const entitiesToBeRemoved: Cesium.Entity[] = [];

	viewer.entities.values.forEach((entity) => {
		if (
			entity.id.includes("circle") &&
			currentSelectedAgentId !== undefined &&
			(!invertDeletion
				? !entity.id.includes(`circle${currentSelectedAgentId}`)
				: entity.id.includes(`circle${currentSelectedAgentId}`))
		) {
			entitiesToBeRemoved.push(entity);
		} else if (entity.id.includes("circle") && currentSelectedAgentId === undefined) {
			entitiesToBeRemoved.push(entity);
		}
	});

	entitiesToBeRemoved.forEach((entity) => viewer.entities.remove(entity));
};
