import { Accordion, Group, Radio, useMantineTheme } from "@mantine/core";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";

interface IRadioGroup {
	groups: { name: string; options: { name: string; id: number; color?: string }[] | undefined }[];
	selected: number | null;
	onChange: (selectedId: number) => void;
}

const RadioGroup: React.FC<IRadioGroup> = ({ groups, selected, onChange }) => {
	const theme = useMantineTheme();
	const [selectedId, setSelectedId] = useState<number | null>(selected === null && groups.length > 0 ? groups[0].options![0].id : selected);
	const [selectedGroupName, setSelectedGroupName] = useState<string | null>(null);

	useEffect(() => {
		if (selectedId !== null && selectedId !== selected) onChange(selectedId);
	}, [selectedId, selected, onChange]);

	return (
		<RadioGroupContainer defaultValue={selectedGroupName}>
			{groups.map((group, groupIndex) => (
				<Accordion.Item value={group.name} key={groupIndex}>
					<Accordion.Control>{group.name}</Accordion.Control>
					<Accordion.Panel>
						<Group>
							{group.options?.map((option, i) => {
								if (selectedId === option.id && selectedGroupName === null) setSelectedGroupName(group.name);
								return (
									<RadioElement
										key={i}
										label={option.name}
										checked={selectedId === option.id}
										onChange={(e) => {
											e.currentTarget.checked ? setSelectedId(option.id) : setSelectedId(null);
										}}
										sx={{
											"& .mantine-Radio-label": {
												color: option.color
													? option.color === "blue"
														? theme.colors.accentBlue
														: theme.colors.accentRed[5]
													: undefined,
											},
										}}
									></RadioElement>
								);
							})}
						</Group>
					</Accordion.Panel>
				</Accordion.Item>
			))}
		</RadioGroupContainer>
	);
};

const RadioGroupContainer = styled(Accordion)`
	display: flex;
	flex-direction: column;
	width: 100%;

	// fp modal
	position: relative;
	overflow: auto;
	height: 100%;

	& .mantine-Accordion-label {
		padding: 0.5rem 0;
	}

	& .mantine-Accordion-item:last-of-type {
		border-bottom: none;
	}
`;

const RadioElement = styled(Radio)`
	& :hover {
		cursor: pointer;
	}
`;

export default RadioGroup;
