import { useCallback, useContext } from "react";
import { ActionIcon, Anchor, Flex, Table } from "@mantine/core";
import { BaseData, InputData, Optional, SystemSettingsObjectType } from "../../../types/filters";
import { AppInfoContext } from "../../contexts/AppInfoContext";
import styled from "@emotion/styled/macro";
import ConfounderModal from "../modals/ConfounderModal";
import EffectorModal from "../modals/EffectorModal";
import SensorModal from "../modals/SensorModal";
import EmitterModal from "../modals/EmitterModal";
import DismountModal from "../modals/DismountModal";
import { IconTrash } from "@tabler/icons-react";

export interface ISystemModal<T extends Optional<BaseData, "id">> {
	inputData: InputData;
	modalButtonText: string;
	settingName: string;
	tableData: Array<Record<string, number | string>>;
	mode?: "add" | "edit";
	variant?: "outline" | "filled";
	currentSelectedRow?: number;
	setSetting: (settingName: keyof T, value: T[keyof T]) => void;
}

interface ISettingStaticTable<T extends Optional<BaseData, "id">> {
	// The name of the setting being edited
	settingName: keyof T;
	// The data to display in the table
	tableData: Array<Record<string, number | string>>;
	// The template for the system settings
	template: SystemSettingsObjectType;
	// Input data for the setting
	inputData: InputData;
	// A function to update the setting with the new data
	setSetting: (settingName: keyof T, value: T[keyof T]) => void;
}

const SettingStaticTable = <T extends Optional<BaseData, "id">>({
	settingName,
	tableData,
	template,
	inputData,
	setSetting,
}: ISettingStaticTable<T>) => {
	const { isSuperuser } = useContext(AppInfoContext);

	const getModal = useCallback(
		(text: string, mode: "add" | "edit", variant?: "outline" | "filled", currentSelectedRow?: number) => {
			const components: Record<string, JSX.Element> = {
				sensors: (
					<SensorModal
						modalButtonText={text}
						inputData={inputData}
						settingName={settingName as string}
						setSetting={setSetting}
						tableData={tableData}
						mode={mode}
					/>
				),
				emitters: (
					<EmitterModal
						modalButtonText={text}
						inputData={inputData}
						settingName={settingName as string}
						setSetting={setSetting}
						tableData={tableData}
						mode={mode}
					/>
				),
				effectors: (
					<EffectorModal
						modalButtonText={text}
						inputData={inputData}
						settingName={settingName as string}
						setSetting={setSetting}
						tableData={tableData}
						mode={mode}
						variant={variant}
						currentSelectedRow={currentSelectedRow}
					/>
				),
				dismounts: (
					<DismountModal
						modalButtonText={text}
						inputData={inputData}
						settingName={settingName as string}
						setSetting={setSetting}
						tableData={tableData}
						mode={mode}
					/>
				),
				confounders: (
					<ConfounderModal
						modalButtonText={text}
						inputData={inputData}
						settingName={settingName as string}
						setSetting={setSetting}
						tableData={tableData}
						mode={mode}
					/>
				),
			};

			return components[settingName as string];
		},
		[inputData, settingName, setSetting, tableData]
	);

	return (
		<TableWrapper>
			<StyledTable striped withBorder withColumnBorders>
				<thead>
					<tr>
						{template.columns && [
							...Object.keys(template.columns).map((key) => (
								<th id={key} key={key}>
									{template.columns ? template.columns[key] : "UnknownHeader"}
								</th>
							)),
							isSuperuser ? (
								<th id={"delete-column"} key={"delete-column"} style={{ width: "15%", textAlign: "center" }}>
									{"Actions"}
								</th>
							) : null,
						]}
					</tr>
				</thead>
				<tbody>
					{tableData.map((row, rowIndex) => {
						let value: string | number | undefined;
						return (
							<tr key={`Row${rowIndex}`}>
								{[
									...[
										Object.keys(template.columns || row).map((cellKey) => {
											value = row[cellKey as keyof typeof row];

											if (settingName === "effectors") {
												const correspondingSystem = (inputData[settingName as keyof InputData] as Array<any>).find(
													(system) => system.id === row.effector_id
												);
												value = correspondingSystem ? correspondingSystem.info.name : null;
											} else if (settingName === "dismounts") {
												const correspondingSystem = (inputData[settingName as keyof InputData] as Array<any>).find(
													(system) => system.id === row
												);
												value = correspondingSystem ? correspondingSystem.name : null;
											} else if (settingName !== "confounders") {
												const correspondingSystem = (inputData[settingName as keyof InputData] as Array<any>).find(
													(system) => system.id === row
												);
												value = correspondingSystem ? correspondingSystem.info.name : null;
											}

											const addressName =
												(settingName as string) === "dismounts" ? "systems" : (settingName as string);
											const addresId = (settingName as string) === "effectors" ? row.effector_id : row;

											return value !== undefined && value !== null ? (
												<TableCell
													key={`${cellKey}${row.id}`}
													headers={cellKey}
													suppressContentEditableWarning
													inputMode="numeric"
												>
													{(settingName as string) !== "confounders" ? (
														<Anchor
															href={`${window.location.origin}/systems-library/${addressName}/${addresId}`}
															target="_blank"
														>
															{value}
														</Anchor>
													) : (
														value
													)}
												</TableCell>
											) : null;
										}),

										isSuperuser && value !== undefined && value !== null ? (
											<TableCell
												key={`${row.id}`}
												style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
											>
												<ActionIcon
													color="primary"
													onClick={() => {
														const newTableData = tableData.filter(
															(dataRow, dataRowIndex) => dataRowIndex !== rowIndex
														);
														setSetting(settingName, newTableData as T[keyof T]);
													}}
												>
													<IconTrash size={16} />
												</ActionIcon>
												{settingName === "effectors" ? getModal("Edit", "edit", "outline", rowIndex) : null}
											</TableCell>
										) : null,
									],
								]}
							</tr>
						);
					})}
				</tbody>
			</StyledTable>

			{/* Popup for adding new systems to table */}
			{isSuperuser ? getModal("Add another", "add", "filled") : null}
		</TableWrapper>
	);
};

const StyledTable = styled(Table)`
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.light[1])};
	width: 100%;
	border-radius: 0.25rem;
	border-collapse: separate;
	border-spacing: 0;

	& th,
	td {
		color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])} !important;
		border-left: none !important;
	}
`;

const TableWrapper = styled(Flex)`
	width: 100%;
	flex-direction: column;

	& .mantine-Group-root {
		margin-top: 1rem;
	}
`;

export const ButtonsContainer = styled(Flex)`
	padding-top: 1rem;
	justify-content: flex-end;
`;

export const PopupBody = styled(Flex)`
	flex-direction: column;

	& > :nth-of-type(n) {
		padding: 0.5rem 0;
	}
`;

export const InputWrapper = styled(Flex)`
	justify-content: space-between;
	flex: 1;
	align-items: center;

	& > h4 {
		font-weight: 500;
		width: 30%;
	}
`;

const TableCell = styled.td`
	color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])};

	& .mantine-Group-root {
		margin-top: 0;
		margin-left: 1rem;
	}
`;

export default SettingStaticTable;
