import Popup from "../Popup";
import { ActionIcon, NativeSelect, RangeSlider, createStyles } from "@mantine/core";
import { useEffect, useState } from "react";
import { BaseData, Optional } from "../../../types/filters";
import { ISystemModal, InputWrapper, PopupBody } from "../tables/SettingStaticTable";
import { toast } from "react-toastify";
import CustomSlider from "../CustomSlider";
import { IconEdit } from "@tabler/icons-react";

const EffectorModal = <T extends Optional<BaseData, "id">>({
	inputData,
	settingName,
	modalButtonText,
	tableData,
	variant,
	mode,
	currentSelectedRow,
	setSetting,
}: ISystemModal<T>) => {
	const { classes } = useStyles();
	const [modalSelectedType, setModalSelectedType] = useState<string | undefined>();
	const [elevationRange, setElevationRange] = useState<[number, number]>([-10, 10]);
	const [unitsOfFire, setUnitsOfFire] = useState<number>(0);

	useEffect(() => {
		if (currentSelectedRow !== undefined) {
			const effector = tableData[currentSelectedRow as number];
			if (effector) {
				setModalSelectedType(effector.effector_id.toString());
				setElevationRange([effector.min_elevation as number, effector.max_elevation as number]);
				setUnitsOfFire(effector.units_of_fire as number);
			}
		}
	}, [currentSelectedRow, tableData]);

	const handleSubmit = () => {
		if (modalSelectedType !== undefined && mode === "add") {
			setSetting(
				settingName as keyof T,
				[
					...tableData,
					{
						effector_id: parseInt(modalSelectedType),
						min_elevation: elevationRange[0],
						max_elevation: elevationRange[1],
						units_of_fire: unitsOfFire,
					},
				] as T[keyof T]
			);
		} else if (modalSelectedType !== undefined && mode === "edit") {
			setSetting(
				settingName as keyof T,
				[
					...tableData.map((effector, i) => {
						if (effector.effector_id === parseInt(modalSelectedType) && i === currentSelectedRow) {
							return {
								...effector,
								min_elevation: elevationRange[0],
								max_elevation: elevationRange[1],
								units_of_fire: unitsOfFire,
							};
						} else {
							return effector;
						}
					}),
				] as T[keyof T]
			);
		} else {
			toast.info("Please select effector");
			return false;
		}

		return true;
	};

	return inputData.effectors ? (
		<Popup
			title=""
			modalButtonText={modalButtonText}
			variant={variant}
			handleSubmit={handleSubmit}
			customButton={
				mode === "edit" ? (
					<ActionIcon>
						<IconEdit size={16}></IconEdit>
					</ActionIcon>
				) : undefined
			}
		>
			<PopupBody>
				<InputWrapper>
					<h4>Effector</h4>
					<NativeSelect
						required
						w={"70%"}
						placeholder="Select effector"
						defaultValue={
							currentSelectedRow !== undefined
								? inputData.effectors
										.findIndex(
											(effector) => effector.id === (tableData[currentSelectedRow as number].effector_id as number)
										)
										.toString()
								: "-1"
						}
						data={[
							{ value: "-1", label: "Select effector" },
							...inputData.effectors.map((effector, i) => ({
								value: i.toString(),
								label: effector.info ? effector.info.name : effector.id.toString(),
							})),
						]}
						onChange={(e) => {
							e.target.value !== "-1" && inputData.effectors
								? setModalSelectedType(inputData.effectors[e.target.value as unknown as number].id.toString())
								: setModalSelectedType(undefined);
						}}
						defaultChecked={false}
					/>
				</InputWrapper>
				<InputWrapper>
					<h4>{"Elevation"}</h4>
					<RangeSlider
						w={"70%"}
						min={-90}
						max={90}
						defaultValue={[-10, 10]}
						value={elevationRange}
						onChange={setElevationRange}
					/>
				</InputWrapper>
				<InputWrapper>
					<h4>{"Quantity"}</h4>
					<CustomSlider
						key={"effector_modal_units_of_fire"}
						className={classes.sliderComponent}
						min={1}
						max={2000}
						value={unitsOfFire}
						step={1}
						onChange={setUnitsOfFire}
						onChangeEnd={setUnitsOfFire}
					></CustomSlider>

					{/* <Slider max={2000} w={"70%"} defaultValue={1} value={unitsOfFire} onChange={setUnitsOfFire} /> */}
				</InputWrapper>
			</PopupBody>
		</Popup>
	) : null;
};

const useStyles = createStyles((theme) => ({
	sliderComponent: {
		width: "70%",
		justifySelf: "flex-start",
	},
}));

export default EffectorModal;
