import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { GlobalStyle, MantineGlobal } from "./globals";
import Router from "./components/router/Router";
import "react-toastify/dist/ReactToastify.css";
import { MantineProvider, ColorSchemeProvider, ColorScheme, Tuple } from "@mantine/core";
import { getAPIEndpoint } from "./common/getAPIEndpoint";

const App: React.FC = () => {
	const [mainColorscale, setMainColorscale] = useState<Tuple<string, 10>>();
	const [colorScheme, setColorScheme] = useState<ColorScheme>((localStorage.getItem("colorScheme") as ColorScheme) || "dark");
	const toggleColorScheme = (value?: ColorScheme) => {
		localStorage.setItem("colorScheme", value || (colorScheme === "dark" ? "light" : "dark"));
		setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
	};

	useEffect(() => {
		getAppMainColor();
	}, []);

	const getAppMainColor = async () => {
		const mainColorscale = await fetch(`${getAPIEndpoint()}get_main_colorscale`);
		const mainColorscaleJson = await mainColorscale.json();
		setMainColorscale(mainColorscaleJson);
	};

	return (
		<ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
			<MantineProvider
				withGlobalStyles
				withNormalizeCSS
				theme={{
					colorScheme: colorScheme,
					colors: {
						dark: [
							"#222428", // 0 primary
							"#282c34", // 1 background
							"#d5d5d5", // 2 primaryFont
							"#878787", // 3 secondaryFont
							"#4F5154",
							"#47494D",
							"#404247",
							"#393C41",
							"#33363C",
							"#2D3138",
						],
						light: [
							"#f5f5f5", // 0 primary
							"#fff", // 1 background
							"#7a7a7a", // 2 primaryFont
							"#7a7a7a", // 3 secondaryFont
							"#DDDDDD",
							"#C6C6C6",
							"#B3B3B3",
							"#A1A1A1",
							"#919191",
							"#828282",
						],
						appMainColor: mainColorscale,
						accentRed: [
							"#F2E1E1",
							"#EAC0BE",
							"#E99C99",
							"#EF7470",
							"#FF4740",
							"#E93D36",
							"#D23731",
							"#B03D39",
							"#94413E",
							"#7D423F",
						],
						accentBlue: ["#4287f5"],
					},
					primaryColor: "appMainColor",
					primaryShade: 5,
				}}
			>
				<>
					<MantineGlobal />
					<GlobalStyle />
					{mainColorscale ? (
						<AppContainer>
							<Router></Router>
						</AppContainer>
					) : null}
				</>
			</MantineProvider>
		</ColorSchemeProvider>
	);
};

const AppContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.background};
	height: 100%;
	width: 100%;
`;

export default App;
