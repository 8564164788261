import React, { useEffect, useState } from "react";
import { Box, Flex, Slider, Table, Text, createStyles } from "@mantine/core";
import { handleOnlyNumberKeyDown } from "../../common/handleOnlyNumberKeyDown";

interface ICustomSlider {
	min: number;
	value: number;
	onChange?: (value: number) => void;
	onChangeEnd: (value: number) => void;
	className?: string;
	max?: number | undefined;
	step?: number | undefined;
	unit?: string | undefined;
}

const CustomSlider: React.FC<ICustomSlider> = ({ className, min, max, value, step, onChangeEnd, onChange, unit }) => {
	const { classes } = useStyles();
	const [currentValue, setCurrentValue] = useState(value);

	useEffect(() => {
		setCurrentValue(value);
	}, [value]);

	return (
		<Flex className={`${classes.wrapper} ${className}`}>
			<Slider
				style={{ flex: 1 }}
				min={min}
				max={max}
				value={currentValue}
				step={step}
				onChange={(val) => {
					setCurrentValue(val);
					if (onChange) onChange(val);
				}}
				onChangeEnd={onChangeEnd}
				label={(value: number) => `${value} ${unit || ""}`}
			></Slider>
			<Box className={classes.labelBox}>
				<Table w={"fit-content"}>
					<tbody style={{ padding: 0, fontSize: "1rem" }}>
						<tr>
							<td
								className={classes.textInput}
								onBlur={(event) => onChangeEnd(parseInt(event.currentTarget.innerText))}
								onKeyDown={handleOnlyNumberKeyDown}
								contentEditable
								suppressContentEditableWarning
							>
								{currentValue ? currentValue : 0}
							</td>
						</tr>
					</tbody>
				</Table>
				<Text className={classes.text}>{`${unit ? " " + unit : ""}`}</Text>
			</Box>
		</Flex>
	);
};

const useStyles = createStyles((theme) => ({
	wrapper: {
		alignItems: "center",
	},

	labelBox: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		width: "6rem",
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[1],
		textAlign: "center",
		borderRadius: "0.25rem",
		marginLeft: "0.5rem",
		padding: "0.1rem 0.5rem",
		position: "relative",
	},

	textInput: {
		color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3],
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[1],
		textAlign: "center",
		// padding: "0 !important",
		fontSize: "1rem !important",
		padding: "0 0.2rem !important",
	},

	text: {
		color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3],
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[1],
	},
}));

export default CustomSlider;
