import { EffectorData, Optional, SystemSettingsTemplate } from "../../../types/filters";

export const effectorsSettingsTemplate: SystemSettingsTemplate<EffectorData> = {
	sections: {
		"": {
			expl_radius: { type: "slider", max_limit: 100, step: 5, unit: "m" },
		},
		Marks: {
			max_z: { type: "slider", max_limit: 20000, step: 500, unit: "m" },
			max_target_speed: {
				type: "slider",
				max_limit: 3000,
				step: 50,
				unit: "km/h",
			},
			reload_time: {
				type: "slider",
				max_limit: 600,
				step: 1,
				unit: "s",
			},
			fire_type: {
				type: "select",
				data: [
					{ value: "DIRECT", label: "DIRECT" },
					{ value: "INDIRECT", label: "INDIRECT" },
					{ value: "STORAGE", label: "STORAGE" },
				],
			},
		},
		"Hit probabilities": {
			phit_distances: {
				type: "table",
				columns: { dshort: "Short", dmedium: "Medium", dlong: "Long" },
				use_archetypes: false,
				editable: true,
				value_type: "string",
				conditionalRenderProperty: "fire_type",
				conditionalRenderValues: ["DIRECT", "INDIRECT"],
			},
			phit_probabilities: {
				type: "table",
				columns: { target: "Ranges to (m)", dshort: "Short", dmedium: "Medium", dlong: "Long" },
				use_archetypes: true,
				editable: true,
				value_type: "string",
				conditionalRenderProperty: "fire_type",
				conditionalRenderValues: ["DIRECT", "INDIRECT"],
			},
		},
		"Kill probabilities": {
			pkill: {
				type: "table",
				columns: { target: "Armor", none: "None", active: "Active", passive: "Passive", reactive: "Reactive" },
				use_archetypes: true,
				editable: true,
				value_type: "string",
				conditionalRenderProperty: "fire_type",
				conditionalRenderValues: ["DIRECT", "INDIRECT"],
			},
		},
		Effector: {
			side: {
				type: "select",
				data: [
					{ value: "2", label: "NONE" },
					{ value: "0", label: "BLUE" },
					{ value: "1", label: "RED" },
				],
			},
			cmo_id: "text",
			cost: "text",
			cost_per_shot: "text",
			name: "text",
		},
	},
};

export const defaultSettings: Optional<EffectorData, "id"> = {
	cmo_id: -1,
	name: "New effector",
	side: 0,
	cost: 0,
	count: 0,
	cost_per_shot: 0,
	expl_radius: 0,
	indirect_fire: false,
	fire_type: "DIRECT",
	max_target_speed: 0,
	max_z: 0,
	reload_time: 0,
	phit_distances: {
		dlong: "0",
		dmedium: "0",
		dshort: "0",
	},
	phit_probabilities: [
		{
			target: 11,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
		{
			target: 1,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
		{
			target: 2,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
		{
			target: 3,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
		{
			target: 4,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
		{
			target: 5,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
		{
			target: 9,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
		{
			target: 7,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
		{
			target: 6,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
		{
			target: 12,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
		{
			target: 10,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
		{
			target: 8,
			dshort: "0.00",
			dmedium: "0.00",
			dlong: "0.00",
		},
	],
	pkill: [
		{
			target: 11,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
		{
			target: 1,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
		{
			target: 2,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
		{
			target: 3,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
		{
			target: 4,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
		{
			target: 5,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
		{
			target: 9,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
		{
			target: 7,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
		{
			target: 6,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
		{
			target: 12,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
		{
			target: 10,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
		{
			target: 8,
			none: "0.00",
			active: "0.00",
			passive: "0.00",
			reactive: "0.00",
		},
	],
};
