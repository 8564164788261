import { useRef } from "react";

// Mantine has problems when passing state to value property of controlled components, so we need to use live ref

export function useLiveRef<T>(value: T) {
	const ref = useRef(value);
	ref.current = value;

	return ref;
}
