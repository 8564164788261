import { Button, useMantineTheme } from "@mantine/core";
import * as Cesium from "cesium";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AppInfoContext } from "../../../../../components/contexts/AppInfoContext";
import { getCenterFromCartesians } from "../functions/getCenterFromCartesians";
import { BehaviorParameter, ListForcePackageData } from "../../../../../types/filters";
import { clearLines } from "../functions/clearLines";
import { clearPolygons } from "../functions/clearPolygons";
import { clearCircles } from "../functions/clearCircles";
import { BehaviorEditiorContext } from "../contexts/BehaviorEditiorContext";

interface IPathLayer {
	viewer: Cesium.Viewer;
	defaultValue: any;
	systemSettings: ListForcePackageData & {
		cost: string | number;
		symbol: string;
	};
	isEditionModeOn: boolean;
	systemIds: { agent_id: number; system_id: number; is_commander: boolean };
	previewMode?: boolean;
	elementOnWhichInputDepends?: BehaviorParameter;
	queueInputIndex?: number; // If belongs to queue input
	onChange: (value: any) => void;
	setLayerControls?: (value: JSX.Element | null) => void;
}

const PathLayer: React.FC<IPathLayer> = ({
	viewer,
	defaultValue,
	systemSettings,
	previewMode,
	isEditionModeOn,
	systemIds,
	queueInputIndex,
	elementOnWhichInputDepends,
	onChange,
	setLayerControls,
}) => {
	const theme = useMantineTheme();
	const { isSuperuser } = useContext(AppInfoContext);
	const { updateAllEntities, resetAgentIdEntities } = useContext(BehaviorEditiorContext);

	const [currentArea, setCurrentArea] = useState<(Cesium.Entity & { properties: Record<string, any> }) | null>(null);
	const [selectedNodes, setSelectedNodes] = useState<number[]>(defaultValue.length === 0 ? [] : defaultValue); // Store selected node IDs
	const [pathLines, setPathLines] = useState<Cesium.Entity[]>([]); // Store path line entities
	const [currentlySelecting, setCurrentlySelecting] = useState(false);

	const color = useMemo(() => new Cesium.ColorMaterialProperty(Cesium.Color.WHITE.withAlpha(0.2)), []);
	const chosenColor = useMemo(
		() => new Cesium.ColorMaterialProperty(Cesium.Color.fromCssColorString(theme.colors.appMainColor[5]).withAlpha(0.8)),
		[theme]
	);

	const updateCurrentArea = useCallback(() => {
		const areas = viewer.dataSources?.getByName("areas")[0]?.entities?.values as (Cesium.Entity & {
			properties: Record<string, any>;
		})[];

		if (areas) {
			const currentArea = areas.find((x) => x.properties.type._value.toLowerCase().includes("staging")) || null;
			setCurrentArea(currentArea);
		}
	}, [viewer]);

	const drawPath = useCallback(() => {
		const newLines: Cesium.Entity[] = [];

		if (selectedNodes.length >= 1) {
			const symbolEntity = viewer.entities.getById("symbol" + systemIds.agent_id);
			let startingPosition = Cesium.Cartographic.fromCartesian(
				symbolEntity?.position?.getValue(new Cesium.JulianDate(1, 1)) as Cesium.Cartesian3
			);
			let previousNodeId = symbolEntity?.id;

			if (elementOnWhichInputDepends && elementOnWhichInputDepends.value) {
				const lastPosition =
					elementOnWhichInputDepends.property === "PATH"
						? (elementOnWhichInputDepends.value as number[])[(elementOnWhichInputDepends.value as number[]).length - 1]
						: elementOnWhichInputDepends.value;

				startingPosition = getCenterFromCartesians(
					viewer?.dataSources
						?.getByName("geometry_voronois")[0]
						.entities.values.find((entity) => entity.id === `spm-cell-${lastPosition}`)
						?.polygon?.hierarchy?.getValue(new Cesium.JulianDate(1, 1)).positions
				);

				previousNodeId = lastPosition.toString();
			}

			let previousPosition = Cesium.Cartesian3.fromRadians(
				startingPosition.longitude,
				startingPosition.latitude,
				startingPosition.height
			);

			selectedNodes.forEach((nodeId, index) => {
				const exists = viewer.entities.getById(
					`line-${systemIds.agent_id}-${index}-from${previousNodeId}-to${nodeId}${queueInputIndex ? `-${queueInputIndex}` : ""}`
				);
				if (exists) {
					viewer.entities.remove(exists);
				}

				const currentNode = viewer.dataSources.getByName("geometry_voronois")[0].entities.getById(`spm-cell-${nodeId}`);

				if (currentNode?.polygon?.hierarchy) {
					const currentEntityCenter = getCenterFromCartesians(
						currentNode.polygon.hierarchy.getValue(new Cesium.JulianDate(1, 1)).positions
					);
					const currentPosition = Cesium.Cartesian3.fromRadians(
						currentEntityCenter.longitude,
						currentEntityCenter.latitude,
						currentEntityCenter.height
					);

					const line = viewer.entities.add({
						id: `line-${systemIds.agent_id}-${index}-from${previousNodeId}-to${nodeId}${
							queueInputIndex ? `-${queueInputIndex}` : ""
						}`,
						polyline: {
							positions: [previousPosition, currentPosition],
							width: 10,
							material: new Cesium.PolylineArrowMaterialProperty(Cesium.Color.BLACK.withAlpha(1)),
						},
					});

					newLines.push(line);
					previousNodeId = nodeId.toString();
					previousPosition = currentPosition; // Set the end position of the current line as the start position for the next line
				}
			});
		}

		updateAllEntities(systemIds.agent_id.toString() + (queueInputIndex ? `-${queueInputIndex}` : ""), "path", newLines);
		setPathLines(newLines);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewer, selectedNodes, systemIds, queueInputIndex, elementOnWhichInputDepends]);

	const setSelectedNodeId = useCallback((selectedEntity: Cesium.Entity) => {
		if (selectedEntity) {
			const entityId = parseInt(selectedEntity.id.split("spm-cell-")[1]);
			setSelectedNodes((prev) => [...prev, entityId]);
		}
	}, []);

	const toggleEditionMode = () => {
		if (!previewMode && !currentlySelecting) {
			setCurrentlySelecting(true);
			clearLines(viewer, systemIds.agent_id);
			clearPolygons(viewer);
			clearCircles(viewer, systemIds.agent_id.toString());
		}
		if (currentlySelecting) {
			setCurrentlySelecting(false);
		}
	};

	// useEffect on elementOnWhichInputDepends.value to remove first line from path (leftover if we remove some input from the queue)
	useEffect(() => {
		if (queueInputIndex) {
			resetAgentIdEntities(systemIds.agent_id.toString() + (queueInputIndex ? `-${queueInputIndex + 1}` : ""));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queueInputIndex, viewer, systemIds]);

	useEffect(() => {
		updateCurrentArea();
	}, [updateCurrentArea]);

	useEffect(() => {
		!previewMode && currentlySelecting && viewer.selectedEntityChanged.addEventListener(setSelectedNodeId);

		return () => {
			!previewMode && currentlySelecting && viewer.selectedEntityChanged.removeEventListener(setSelectedNodeId);
		};
	}, [viewer, currentlySelecting, color, chosenColor, defaultValue, previewMode, setSelectedNodeId]);

	useEffect(() => {
		if (currentArea) {
			resetAgentIdEntities(systemIds.agent_id.toString() + (queueInputIndex ? `-${queueInputIndex}` : ""));
			drawPath();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentArea, selectedNodes, drawPath, queueInputIndex, systemIds]);

	useEffect(() => {
		setSelectedNodes(defaultValue);
	}, [defaultValue]);

	// Setup layer controls
	useEffect(() => {
		const resetPath = () => {
			setSelectedNodes([]);
			pathLines.forEach((line) => viewer.entities.remove(line));
			setPathLines([]);
		};

		if (currentlySelecting && setLayerControls) {
			setLayerControls(
				<div style={{ position: "absolute", bottom: "0", right: "0", margin: "1rem", display: "flex", gap: "1rem" }}>
					<Button
						onClick={() => {
							onChange(selectedNodes);
							setCurrentlySelecting(false);
						}}
						disabled={!isSuperuser}
					>
						Confirm
					</Button>
					<Button onClick={resetPath}>Reset</Button>
				</div>
			);
		} else if (setLayerControls) {
			setLayerControls(null);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedNodes, isSuperuser, currentlySelecting, pathLines]);

	return !previewMode ? (
		<Button disabled={isEditionModeOn && !currentlySelecting} onClick={toggleEditionMode}>
			{currentlySelecting ? "Stop setting" : "Start setting"}
		</Button>
	) : null;
};

export default PathLayer;
