import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface IProtectedRoute {
	children: React.ReactNode;
}

const ProtectedRoute: React.FunctionComponent<IProtectedRoute> = ({ children }) => {
	const [isAuthorized] = useState(localStorage.getItem("token"));
	let location = useLocation();

	return <>{!isAuthorized ? <Navigate to="/login" state={location.state} /> : children}</>;
};

export default ProtectedRoute;
