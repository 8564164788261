import {
    BaseData,
    SystemSettingsBaseTypes,
    SystemSettingsTemplate,
    SystemSettingsObjectType,
    Archetype,
    InputData,
    ForcePackageData,
    ParentEchelon,
    ForcePackagesFiltersData,
    AssessmentScenarioData,
    SensorData,
} from "../../types/filters";
import { Checkbox, Flex, Select, TextInput, createStyles, Text, SelectItem, Button, NumberInput } from "@mantine/core";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AppInfoContext } from "../contexts/AppInfoContext";
import { Optional } from "../../types/filters";
import { Panel } from "../../AppPage";
import Preloader from "../common/Preloader";
import SettingTable from "../common/tables/SettingTable";
import MultiCheckbox from "../common/MultiCheckbox";
import SettingStaticTable from "../common/tables/SettingStaticTable";
import RadioGroup from "../common/RadioGroup";
import NestedAccordions from "../../pages/ForcePackages/force-package-page/force-package-accordions/NestedAccordions";
import CustomSlider from "../common/CustomSlider";
import BehaviorsEditor from "../../pages/Assessments/assessments-page/behaviors-editor/BehaviorsEditor";
import ProbabilityTable from "../common/tables/ProbabilityTable";
import _ from "lodash";

// You can see how settings structure looks like in some defaultSettings.ts

interface ISettingsPanel<T extends BaseData> {
	settings: Optional<T, "id">;
	defaultSettings: Optional<T, "id">;
	inputData: InputData;
	isSystemNew: boolean;
	systemSettingsTemplate: SystemSettingsTemplate<T>;
	settingsLoading: boolean;
	filtersLoading: boolean;
	forcePackageFiltersData?: ForcePackagesFiltersData;
	setSetting: (settingName: keyof T, value: T[keyof T]) => void;
	removeSettings: () => void;
	reloadFilters: () => void;
	addSystem: (settings: Optional<T, "id">) => Promise<T | null>;
	saveSystem: (settings: Optional<T, "id">) => Promise<any>;
}

const SettingsPanel = <T extends BaseData>({
	settings,
	defaultSettings,
	inputData,
	isSystemNew,
	systemSettingsTemplate,
	settingsLoading,
	filtersLoading,
	forcePackageFiltersData,
	setSetting,
	removeSettings,
	reloadFilters,
	addSystem,
	saveSystem,
}: ISettingsPanel<T>) => {
	const [isAddingSystem, setIsAddingSystem] = useState<boolean>(false);
	const { classes } = useStyles();
	const { isSuperuser } = useContext(AppInfoContext);
	const settingsWithoutLabel = useMemo(
		() => ["table", "static-table", "radio-group", "force-package", "force-package-behaviors", "probability-table"],
		[]
	);

	const handleSaveButton = async () => {
		setIsAddingSystem(true);
		if (isSystemNew) {
			const res = await addSystem(settings);
			if (res) {
				setSetting("id", res.id as T["id"]);
				reloadFilters();
			}
			setIsAddingSystem(false);
		} else {
			const res = await saveSystem(settings);
			if (res) reloadFilters();
			setIsAddingSystem(false);
		}
	};

	const getNotRenderedInfo = (renderProperty: string | undefined) => {
		const name = renderProperty
			? renderProperty
					.split("_")
					.map((word) => {
						word = word[0].toUpperCase() + word.slice(1).toLowerCase();
						return word;
					})
					.join(" ")
			: "Unknown";

		return (
			<Flex w={"100%"} style={{ justifyContent: "center" }}>
				<Text style={{ fontSize: "14px" }}>
					Change <b>{name}</b> to see this setting
				</Text>
			</Flex>
		);
	};

	const shouldRenderComponent = useCallback(
		(renderProperty: string | undefined, renderValues: string[] | number[] | boolean[] | undefined) => {
			if (renderProperty && renderValues) {
				return (renderValues as T[keyof T][]).includes(settings[renderProperty as keyof Optional<T, "id">] as T[keyof T]);
			}
			return true;
		},
		[settings]
	);

	// This function returns the appropriate input component based on the setting type
	const getComponent = useCallback(
		(settingName: keyof Optional<T, "id">, settingConfig: SystemSettingsBaseTypes | SystemSettingsObjectType) => {
			const key = `${(settingName as string) + settings[settingName]}`;
			const components: Partial<Record<SystemSettingsBaseTypes, JSX.Element>> = {
				slider: (
					<CustomSlider
						key={key}
						className={classes.inputComponent}
						min={(settingConfig as SystemSettingsObjectType).min_limit || 0}
						max={(settingConfig as SystemSettingsObjectType).max_limit}
						value={(settings[settingName] as number) || (defaultSettings[settingName] as number)}
						step={(settingConfig as SystemSettingsObjectType).step}
						onChangeEnd={(value) => {
							setSetting(settingName, value as T[keyof T]);
						}}
						unit={(settingConfig as SystemSettingsObjectType).unit}
					></CustomSlider>
				),
				text: (
					<TextInput
						key={key}
						disabled={(settingConfig as SystemSettingsObjectType).disabled}
						className={classes.inputComponent}
						defaultValue={(settings[settingName] as string | number) || (defaultSettings[settingName] as string | number)}
						autoCorrect="off"
						onBlur={(value) => {
							if (!(settingConfig as SystemSettingsObjectType).disabled)
								setSetting(settingName, value.target.value as T[keyof T]);
						}}
					></TextInput>
				),
				"number-input": (
					<NumberInput
						key={key}
						disabled={(settingConfig as SystemSettingsObjectType).disabled}
						className={classes.inputComponent}
						defaultValue={(settings[settingName] as number) || (defaultSettings[settingName] as number) || 0}
						onBlur={(event) => {
							const isCorrect = !isNaN(parseInt(event.target.value));
							if (isCorrect && !(settingConfig as SystemSettingsObjectType).disabled)
								setSetting(settingName, parseInt(event.target.value) as T[keyof T]);
						}}
					></NumberInput>
				),
				checkbox: (
					<Checkbox
						key={key}
						className={classes.inputComponent}
						defaultChecked={typeof settings[settingName] === "boolean" ? (settings[settingName] as boolean) : false}
						onChange={(value) => {
							setSetting(settingName, value.target.checked as T[keyof T]);
						}}
					></Checkbox>
				),
				select: (
					<Flex className={classes.inputComponent}>
						<Select
							key={key}
							style={{ flex: "1" }}
							data={(settingConfig as SystemSettingsObjectType).data as readonly SelectItem[]}
							defaultValue={
								typeof settings[settingName] === "string"
									? (settings[settingName] as string)
									: settings[settingName] !== undefined && settings[settingName] !== null
									? (settings[settingName] as number).toString()
									: (defaultSettings[settingName] as string)
							}
							onChange={(value) => {
								if (
									settingName === "side" ||
									settingName === "blue_force_package" ||
									settingName === "red_force_package" ||
									settingName === "subtype"
								)
									setSetting(settingName, parseInt(value as string) as T[keyof T]);
								else setSetting(settingName, value as T[keyof T]);
							}}
						></Select>
						{(settingConfig as SystemSettingsObjectType).withAnchorButton &&
						(settingConfig as SystemSettingsObjectType).anchorLink ? (
							<Button
								ml={"1rem"}
								component="a"
								href={`${(settingConfig as SystemSettingsObjectType).anchorLink}/${settings[settingName]}`}
								target="_blank"
							>
								Go to
							</Button>
						) : null}
					</Flex>
				),

				table: (
					<SettingTable
						key={key}
						tableData={settings[settingName] as Array<Record<string, number>>}
						template={settingConfig as SystemSettingsObjectType}
						inputData={inputData}
						settingName={settingName}
						editable={((settingConfig as SystemSettingsObjectType).editable && isSuperuser) || false}
						setSetting={setSetting}
						valueType={(settingConfig as SystemSettingsObjectType).value_type || "number"}
					></SettingTable>
				),
				"static-table": (
					<SettingStaticTable
						key={key}
						tableData={settings[settingName] as Array<Record<string, number>>}
						template={settingConfig as SystemSettingsObjectType}
						inputData={inputData}
						settingName={settingName}
						setSetting={setSetting}
					></SettingStaticTable>
				),
				"multi-checkbox": (
					<MultiCheckbox
						keyProp={key}
						additionalClassName={classes.inputComponent}
						options={inputData[settingName as keyof InputData] ? (inputData[settingName as keyof InputData] as Array<any>) : []}
						checkedList={settings[settingName] as Array<string>}
						onChange={(value) => {
							setSetting(settingName, value as T[keyof T]);
						}}
					></MultiCheckbox>
				),
				"radio-group": (
					<RadioGroup
						groups={
							inputData.archetypes
								? (inputData.archetypes as Archetype[]).map((category) => {
										return {
											name: category.name,
											options: category.platforms
												? category.platforms.map((platform) => {
														return {
															name: `${platform.type_name} ${platform.specific_name}`,
															id: platform.id,
														};
												  })
												: [],
										};
								  })
								: []
						}
						selected={settings[settingName] as number}
						onChange={(selectedId: number) => setSetting(settingName, selectedId as T[keyof T])}
					></RadioGroup>
				),
				"force-package": forcePackageFiltersData && (
					<NestedAccordions
						forcePackage={settings[settingName] as ParentEchelon}
						settings={settings as Optional<ForcePackageData, "id">}
						isTopLevel={true}
						setSetting={setSetting as (settingName: string, value: ForcePackageData[keyof ForcePackageData]) => void}
						pathToElement={[]}
						inputData={inputData}
						forcePackageFiltersData={forcePackageFiltersData}
					></NestedAccordions>
				),
				"force-package-behaviors": settings[settingName] ? (
					<BehaviorsEditor
						title={"Behaviors editor"}
						buttonText={"Open editor"}
						geography={(settings as Optional<AssessmentScenarioData, "id">).geography}
						resolution={(settings as Optional<AssessmentScenarioData, "id">).resolution}
						defaultValue={""}
						scenarioSettings={settings as Optional<AssessmentScenarioData, "id">}
						disabled={false}
						voronoisDataSource={inputData.geometriesDataSource}
						onChange={() => {}}
						behaviorSettingsProperty={settingName as "blue_behavior_settings" | "red_behavior_settings"}
						echelonSettingsProperty={
							(settingName as string).includes("blue") ? "blue_echelon_settings" : "red_echelon_settings"
						}
						inputData={inputData}
						setSetting={
							setSetting as (settingName: string, value: AssessmentScenarioData[keyof AssessmentScenarioData]) => void
						}
					/>
				) : (
					<Text>There is no such force package</Text>
				),
				"probability-table": (
					<ProbabilityTable
						key={key}
						value={
							(settings[settingName] as { range: number; probability: number }[]) ||
							(defaultSettings[settingName] as { range: number; probability: number }[]) ||
							[]
						}
						maxRange={
							(settings as Optional<SensorData, "id">)?.sensing_performance?.sort(
								(a, b) => b.detect_range - a.detect_range
							)[0].detect_range || 100
						}
						editable={isSuperuser}
						onBlur={(value) => {
							if (!(settingConfig as SystemSettingsObjectType).disabled) setSetting(settingName, value as T[keyof T]);
						}}
					></ProbabilityTable>
				),
				none: undefined,
			};

			return shouldRenderComponent(
				(settingConfig as SystemSettingsObjectType).conditionalRenderProperty,
				(settingConfig as SystemSettingsObjectType).conditionalRenderValues
			)
				? (components[typeof settingConfig === "string" ? settingConfig : settingConfig.type] as JSX.Element)
				: getNotRenderedInfo((settingConfig as SystemSettingsObjectType).conditionalRenderProperty);
		},
		[
			inputData,
			classes.inputComponent,
			setSetting,
			settings,
			isSuperuser,
			defaultSettings,
			forcePackageFiltersData,
			shouldRenderComponent,
		]
	);

	const handleNotRenderedAction = useCallback(
		(settingName: string) => {
			if (!_.isEqual(settings[settingName as keyof Optional<T, "id">], defaultSettings[settingName as keyof Optional<T, "id">])) {
				setSetting(settingName as keyof T, defaultSettings[settingName as keyof Optional<T, "id">] as T[keyof T]);
			}
		},
		[settings, defaultSettings, setSetting]
	);

	useEffect(() => {
		// iterate over sections and set default values to records that dont meet conditional render requirements
		Object.keys(systemSettingsTemplate.sections).forEach((section) => {
			Object.keys(systemSettingsTemplate.sections[section as keyof SystemSettingsTemplate<T>]).forEach((settingName) => {
				const settingKey = settingName as keyof Optional<T, "id">;
				const settingConfig = systemSettingsTemplate.sections[section as keyof SystemSettingsTemplate<T>][settingKey] as
					| SystemSettingsBaseTypes
					| SystemSettingsObjectType;
				if (
					!shouldRenderComponent(
						(settingConfig as SystemSettingsObjectType).conditionalRenderProperty,
						(settingConfig as SystemSettingsObjectType).conditionalRenderValues
					)
				) {
					handleNotRenderedAction(settingName);
				}
			});
		});
	}, [settings, defaultSettings, shouldRenderComponent, systemSettingsTemplate, handleNotRenderedAction]);

	return (
		<Panel>
			{!settingsLoading ? (
				<>
					{settings.name || settings.specific_name ? (
						<h3 className={classes.settingsTitle}>{`${settings.name || settings.specific_name || ""}`}</h3>
					) : null}

					{/* Render each section */}
					{Object.keys(systemSettingsTemplate.sections).map((section) => {
						return (
							<div key={section}>
								<h4>{section}</h4>
								{Object.keys(systemSettingsTemplate.sections[section as keyof SystemSettingsTemplate<T>]).map((setting) => {
									const settingKey = setting as keyof Optional<T, "id">;
									const settingConfig = systemSettingsTemplate.sections[section as keyof SystemSettingsTemplate<T>][
										settingKey
									] as SystemSettingsBaseTypes | SystemSettingsObjectType;

									// Get the appropriate input component for the setting type
									const component = getComponent(settingKey, settingConfig);
									const type = typeof settingConfig === "string" ? settingConfig : settingConfig.type;
									const optionalLabel = typeof settingConfig === "string" ? false : settingConfig.label;

									return (
										<Flex className={classes.settingContainer} key={setting}>
											{!settingsWithoutLabel.includes(type) && type !== "none" ? (
												<Text className={classes.settingText}>
													{!optionalLabel
														? setting
																.split("_")
																.map((word) => {
																	word = word[0].toUpperCase() + word.slice(1).toLowerCase();
																	return word;
																})
																.join(" ")
														: optionalLabel}
												</Text>
											) : null}
											{component}
										</Flex>
									);
								})}
							</div>
						);
					})}

					{/* Render the save/add and cancel buttons */}
					{isSuperuser ? (
						<Flex justify={"flex-end"} mt={"1rem"}>
							<Button
								disabled={isAddingSystem || filtersLoading}
								mr={"1rem"}
								onClick={handleSaveButton}
								loading={isAddingSystem || filtersLoading}
								loaderPosition="right"
							>
								{isSystemNew ? "Add" : "Save"}
							</Button>
							<Button onClick={() => removeSettings()}>Cancel</Button>
						</Flex>
					) : null}
				</>
			) : (
				<Preloader></Preloader>
			)}
		</Panel>
	);
};

const useStyles = createStyles((theme) => ({
	container: {
		overflowY: "auto",
	},

	settingContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3],
		margin: "1rem 0",
	},

	settingText: {
		fontSize: 14,
		width: "30%",
		maxWidth: "30%",
	},

	settingsTitle: {
		textAlign: "center",
	},

	inputComponent: {
		width: "70%",
		justifySelf: "flex-start",
	},
}));

export default SettingsPanel;
