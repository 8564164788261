import * as Cesium from "cesium";

export const getCentroid = (coordinates: Cesium.Cartesian3[]) => {
	const newPosition = coordinates.reduce(
		(acc, val) => {
			return {
				x: acc.x + val.x / coordinates.length,
				y: acc.y + val.y / coordinates.length,
				z: acc.z + val.z / coordinates.length,
			};
		},
		{ x: 0, y: 0, z: 0 }
	);
	return newPosition;
};
