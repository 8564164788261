import * as Cesium from "cesium";

const centerMapToPolygons = (viewer: Cesium.Viewer, allPolygonPositions: any) => {
	var rect = Cesium.Rectangle.fromCartesianArray(allPolygonPositions);
	viewer.camera.setView({
		destination: rect,
		orientation: {
			pitch: Cesium.Math.toRadians(-90),
		},
	});
	viewer.camera.moveBackward(100000);
};

export default centerMapToPolygons;
