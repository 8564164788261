import styled from "@emotion/styled/macro";
import { Echelon, ForcePackageData, Optional, ParentEchelon } from "../../../../types/filters";
import { ActionIcon, Flex, Text } from "@mantine/core";
import { IconCopy, IconTrash } from "@tabler/icons-react";
import { useContext } from "react";
import { AppInfoContext } from "../../../../components/contexts/AppInfoContext";
import { findHighestAgentId } from "../../functions/findHighestAgentId";

interface ISystemElement {
	color: string;
	systemId: number;
	agentId: number;
	settings: Optional<ForcePackageData, "id">;
	isCommander: boolean;
	echelon: ParentEchelon | Echelon;
	setSetting: (value: Echelon | null, duplicate?: boolean, changeTopLevelName?: boolean) => void;
}

const SystemElement: React.FC<ISystemElement> = ({ color, systemId, agentId, settings, isCommander, echelon, setSetting }) => {
	const { isSuperuser } = useContext(AppInfoContext);

	return (
		<SystemElementPanel $isCommander={isCommander}>
			<Text color={color}>{settings.systems[systemId].name}</Text>
			{isSuperuser ? (
				<Flex>
					<ActionIcon
						color={"gray"}
						title="Duplicate"
						onClick={(e) => {
							e.stopPropagation();
							const { systems, ...rest } = echelon as Echelon;
							setSetting(
								{
									systems: [...systems, { agent_id: findHighestAgentId(settings.fp.echelons), system_id: systemId }],
									...rest,
								},
								false
							);
						}}
					>
						<IconCopy size="1.1rem"></IconCopy>
					</ActionIcon>
					<ActionIcon
						color={"gray"}
						title="Remove"
						onClick={async () => {
							const { systems, ...rest } = echelon as Echelon;
							systems.splice(
								systems.findIndex((element) => element.agent_id === agentId),
								1
							);
							setSetting({ systems: [...systems], ...rest }, false);
						}}
					>
						<IconTrash size="1.1rem"></IconTrash>
					</ActionIcon>
				</Flex>
			) : null}
		</SystemElementPanel>
	);
};

export default SystemElement;

const SystemElementPanel = styled.div<{ $isCommander: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.light[1])};
	border: 2px solid ${(props) => (props.$isCommander ? props.theme.colors.accentRed[8] : "transparent")};
	padding: 0.5rem;
	margin-top: 1rem;
`;
