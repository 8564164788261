import { ActionIcon, Flex, createStyles, keyframes, useMantineTheme, Text, Popover } from "@mantine/core";
import { IconEdit, IconCopy, IconTrash, IconGraph, IconInfoSquare, IconChartBar } from "@tabler/icons-react";
import React, { AnimationEvent, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Popup from "../common/Popup";
import { AppInfoContext } from "../contexts/AppInfoContext";
import { IconsList } from "../../types/common";
import Preloader from "../common/Preloader";

interface ListElementProps {
	name: string;
	id: number;
	side: number;
	additionalClasses?: string;
	iconsList?: IconsList;
	getElementSettings: () => void;
	deleteSystem: (settingsId: number) => Promise<any>;
	duplicateSystem: (settingsId: number) => Promise<any>;
	incrementOngoingAnimations: (animationName: CSSNumberish | null) => CSSNumberish | null;
	getSystemUsageInfo?: (settingsId: number) => Promise<any>;
}

const ListElement: React.FC<ListElementProps> = ({
	name,
	id,
	side,
	additionalClasses,
	iconsList = ["edit", "duplicate", "delete"],
	getElementSettings,
	deleteSystem,
	duplicateSystem,
	incrementOngoingAnimations,
	getSystemUsageInfo,
}) => {
	const [isBeingDeleted, setIsBeingDeleted] = useState<boolean>(false);
	const { classes } = useStyles();
	const { isSuperuser } = useContext(AppInfoContext);
	const theme = useMantineTheme();
	const location = useLocation();
	const [usageInfo, setUsageInfo] = useState<string[]>([]);
	const [isUsageInfoLoading, setIsUsageInfoLoading] = useState<boolean>(false);

	const handleAnimationStart = (e: AnimationEvent<HTMLDivElement>) => {
		const animation: CSSAnimation = (e.target as HTMLDivElement).getAnimations().filter((animation) => {
			return (animation as CSSAnimation).animationName === e.animationName;
		})[0] as CSSAnimation;

		if (animation) {
			const startTime = incrementOngoingAnimations(animation.startTime);
			if (startTime) {
				animation.startTime = startTime;
			}
		}
	};

	useEffect(() => {
		setIsUsageInfoLoading(false);
	}, [usageInfo]);

	return (
		<Flex
			className={`${classes.element} ${isBeingDeleted ? classes.pulsingElement : ""} ${additionalClasses}`}
			style={{
				// Intended use of '==' instead of '===' cause it may be number/string
				// eslint-disable-next-line eqeqeq
				color: side == 0 ? theme.colors.accentBlue[0] : side == 1 ? theme.colors.accentRed[5] : theme.colors.dark[3],
				opacity: isBeingDeleted ? 0.3 : 1,
			}}
			onAnimationStart={(e) => handleAnimationStart(e)}
		>
			<Text className={classes.nameText}>{name}</Text>
			<Flex className={classes.buttonsContainer}>
				{iconsList.includes("usage-info") && getSystemUsageInfo ? (
					<Popover
						onOpen={async () => {
							setIsUsageInfoLoading(true);
							const info = await getSystemUsageInfo(id);
							setUsageInfo(info);
						}}
						onClose={() => {
							setUsageInfo([]);
						}}
					>
						<Popover.Target>
							<ActionIcon color={"gray"} title="Usage info" disabled={isBeingDeleted}>
								<IconInfoSquare size="1.1rem"></IconInfoSquare>
							</ActionIcon>
						</Popover.Target>
						<Popover.Dropdown>
							{usageInfo.length === 0 && !isUsageInfoLoading ? <Text color="gray">Not used anywhere</Text> : null}
							{usageInfo.length === 0 && isUsageInfoLoading ? (
								<Preloader customStyles="transform: scale(0.8);"></Preloader>
							) : null}
							{usageInfo.length > 0 ? (
								<>
									<Text
										weight={"bold"}
										color={theme.colorScheme === "dark" ? theme.colors.light[0] : theme.colors.dark[0]}
									>
										Used in:
									</Text>
									{usageInfo.map((info, ix) => (
										<Text key={ix}>- {info}</Text>
									))}
								</>
							) : null}
						</Popover.Dropdown>
					</Popover>
				) : null}

				{iconsList.includes("edit") ? (
					<ActionIcon color={"gray"} title="Edit" onClick={() => getElementSettings()} disabled={isBeingDeleted}>
						<IconEdit size="1.1rem"></IconEdit>
					</ActionIcon>
				) : null}

				{iconsList.includes("graph") ? (
					<ActionIcon
						color={"gray"}
						title="Graph"
						onClick={() => window.open(`${location.pathname}/${id}`, "_blank")}
						disabled={isBeingDeleted}
					>
						<IconGraph size="1.1rem"></IconGraph>
					</ActionIcon>
				) : null}

				{iconsList.includes("chart") ? (
					<ActionIcon
						color={"gray"}
						title="Graph"
						onClick={() => {
							window.open(`${window.location.origin}/reporter/${id}`, "_blank");
						}}
						disabled={isBeingDeleted}
					>
						<IconChartBar size="1.1rem"></IconChartBar>
					</ActionIcon>
				) : null}

				{iconsList.includes("duplicate") && isSuperuser ? (
					<ActionIcon
						color={"gray"}
						title="Duplicate"
						disabled={isBeingDeleted}
						onClick={() => {
							duplicateSystem(id);
						}}
					>
						<IconCopy size="1.1rem"></IconCopy>
					</ActionIcon>
				) : null}

				{iconsList.includes("delete") && isSuperuser ? (
					<Popup
						title={"Confirm action"}
						modalButtonText={"Delete"}
						handleSubmit={() => {
							setIsBeingDeleted(true);
							deleteSystem(id).then((res) => {
								setIsBeingDeleted(false);
							});
							return true;
						}}
						customButton={
							<ActionIcon color={"gray"} title="Remove" disabled={isBeingDeleted}>
								<IconTrash size="1.1rem"></IconTrash>
							</ActionIcon>
						}
						disabled={isBeingDeleted}
					>
						<Text color={theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3]}>
							Do you want to delete this element?
						</Text>
					</Popup>
				) : null}
			</Flex>
		</Flex>
	);
};

const pulse = keyframes({
	"0%": { opacity: 1 },
	"50%": { opacity: 0.3 },
	"100%": { opacity: 1 },
});

const useStyles = createStyles((theme) => ({
	element: {
		fontSize: "0.875rem",
		justifyContent: "space-between",
	},

	buttonsContainer: {
		flexDirection: "row",
		overflow: "hidden",
	},

	pulsingElement: {
		animation: `${pulse} 1s infinite`,
	},

	nameText: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		width: "70%",

		[`@media (max-width: 768px)`]: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			width: "50%",
		},
	},
}));

export default ListElement;
