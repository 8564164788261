import { Accordion, createStyles, Spoiler, Text, useMantineTheme } from "@mantine/core";
import {
	AssessmentScenarioData,
	BehaviorParameter,
	BehaviorParameterTemplate,
	Echelon,
	InputData,
	Optional,
	ParentEchelon,
} from "../../../../../types/filters";
import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import BehaviorNestedAccordionControl from "./BehaviorNestedAccordionControl";
import BehaviorSystemElement from "./BehaviorSystemElement";
import BehaviorPanel from "./BehaviorPanel";

interface IBehaviorNestedAccordions {
	forcePackage: ParentEchelon;
	scenarioSettings: Optional<AssessmentScenarioData, "id">;
	pathToElement: number[];
	inputData: InputData;
	isTopLevel?: boolean;
	behaviorSettingsProperty: "blue_behavior_settings" | "red_behavior_settings";
	echelonSettingsProperty: "blue_echelon_settings" | "red_echelon_settings";
	topLevelSettings?: BehaviorParameterTemplate[];
	currentEchelon?: Echelon;
	openedAccordionsNames: string[]; // to maintain the state of opened accordions after re-render (reopening drawer with accordions for example)
	setSetting: (settingName: string, value: AssessmentScenarioData[keyof AssessmentScenarioData]) => void;
	updateSelectedSymbolId: (id: number | null) => void;
	updateSelectedEchelonId: (id: number | null) => void;
	setOpenedAccordionsNames: (name: string[]) => void;
}

const BehaviorNestedAccordions: React.FC<IBehaviorNestedAccordions> = ({
	forcePackage,
	scenarioSettings,
	isTopLevel = false,
	pathToElement,
	inputData,
	behaviorSettingsProperty,
	echelonSettingsProperty,
	topLevelSettings,
	currentEchelon,
	openedAccordionsNames,
	setSetting,
	updateSelectedSymbolId,
	updateSelectedEchelonId,
	setOpenedAccordionsNames,
}) => {
	const theme = useMantineTheme();
	const { classes } = useStyles();
	const transitionDuration = useMemo(() => 500, []);
	const [value, setValue] = useState<string[]>([]);
	const [currentForcePackage, setCurrentForcePackage] = useState<ParentEchelon>(currentEchelon || forcePackage);

	// After closing the item there would be jump because of conditional rendering immediately removing the echelon name from the value state
	// so we setTimeout for setting itemsRenderPanelValues corresponding echelon name property to false for the transition duration to prevent this
	const [itemsRenderPanelValues, setItemsRenderPanelValues] = useState<Record<string, boolean>>(
		currentForcePackage.echelons.reduce((acc, echelon, ix) => {
			acc[`${echelon.name}${ix}`] = isTopLevel ? true : false;
			return acc;
		}, {} as Record<string, boolean>)
	);

	useEffect(() => {
		if (openedAccordionsNames.length > 0) setValue(openedAccordionsNames);
	}, []);

	useEffect(() => {
		if (currentEchelon) setCurrentForcePackage(currentEchelon);
	}, [currentEchelon]);

	useEffect(() => {
		if (currentForcePackage) {
			isTopLevel && setValue([currentForcePackage.name + "0"]);
		}
	}, [currentForcePackage, isTopLevel]);

	const setEchelonSetting = (value: Record<number, BehaviorParameter[]> | null, propertyName: string) => {
		if (value) setSetting(propertyName, value);
	};

	const handleOnChange = (val: string[]) => {
		if (isTopLevel) {
			if (val.filter((name) => !name.includes("system")).length === 0) {
				return;
			}
		}
		if (val.length < value.length) {
			const name = value.filter((name) => !val.includes(name))[0];
			setTimeout(() => {
				setItemsRenderPanelValues((current) => {
					const newItemsRenderPanelValues = { ...current };
					newItemsRenderPanelValues[name] = false;
					return newItemsRenderPanelValues;
				});
			}, transitionDuration);
		} else {
			const name = val.filter((name) => !value.includes(name))[0];
			setItemsRenderPanelValues((current) => {
				const newItemsRenderPanelValues = { ...current };
				newItemsRenderPanelValues[name] = true;
				return newItemsRenderPanelValues;
			});
		}
		setOpenedAccordionsNames(val);
		setValue(val);
	};

	return (
		<Accordion
			className={classes.accordionContainer}
			defaultValue={value}
			value={value}
			mt={isTopLevel ? "0" : "1rem"}
			chevronSize={isTopLevel ? "0" : "1rem"}
			transitionDuration={transitionDuration}
			multiple
			onChange={handleOnChange}
		>
			{currentForcePackage
				? currentForcePackage.echelons.map((echelon, echelonIx) => {
						return (
							<AccordionItem
								key={echelonIx}
								value={`${echelon.name}${echelonIx}`}
								className={isTopLevel ? classes.topLevelAccordionItem : ""}
							>
								<BehaviorNestedAccordionControl
									parentEchelon={currentForcePackage.echelons[0]}
									echelon={echelon}
									isTopLevel={isTopLevel}
									settings={scenarioSettings}
									inputData={inputData}
									behaviorSettingsProperty={behaviorSettingsProperty}
									updateSelectedEchelonId={updateSelectedEchelonId}
									setSetting={(value, duplicate?, changeTopLevelName?) =>
										setEchelonSetting(value, behaviorSettingsProperty)
									}
								/>

								<AccordionPanel>
									{/* Conditional panel rendering to prevent from loading all contents at once */}
									{value.includes(`${echelon.name}${echelonIx}`) ||
									itemsRenderPanelValues[`${echelon.name}${echelonIx}`] ? (
										<>
											<Spoiler
												maxHeight={0}
												showLabel="Show echelon settings"
												hideLabel="Hide"
												style={{ paddingTop: "1rem" }}
											>
												<BehaviorPanel
													entityId={echelon.id}
													behaviorSettings={scenarioSettings[echelonSettingsProperty]}
													inputData={inputData}
													scenarioSettings={scenarioSettings}
													setSetting={(value, duplicate?, changeTopLevelName?) =>
														setEchelonSetting(value, echelonSettingsProperty)
													}
													systemId={0}
													topLevelSettings={topLevelSettings}
													isEditionModeOn={false}
													isEchelonLevel
													preview
												></BehaviorPanel>
											</Spoiler>

											<BehaviorNestedAccordions
												forcePackage={forcePackage}
												scenarioSettings={scenarioSettings}
												isTopLevel={false}
												setSetting={setSetting}
												pathToElement={[...pathToElement, echelonIx]}
												inputData={inputData}
												behaviorSettingsProperty={behaviorSettingsProperty}
												echelonSettingsProperty={
													behaviorSettingsProperty.replace("behavior_settings", "echelon_settings") as
														| "blue_echelon_settings"
														| "red_echelon_settings"
												}
												currentEchelon={echelon as Echelon}
												updateSelectedSymbolId={updateSelectedSymbolId}
												updateSelectedEchelonId={updateSelectedEchelonId}
												openedAccordionsNames={openedAccordionsNames}
												setOpenedAccordionsNames={setOpenedAccordionsNames}
												topLevelSettings={topLevelSettings}
											></BehaviorNestedAccordions>

											{echelon.type !== 1 && echelon.systems.length > 0 && (
												<Text className={classes.commanderText}>
													Commanders<hr></hr>
												</Text>
											)}

											<SystemsAccordion multiple defaultValue={value} value={value} onChange={handleOnChange}>
												{echelon.systems.map((system, systemIx) => {
													const isDark = theme.colorScheme === "dark";
													const color =
														(scenarioSettings.systems[system.system_id].side as number) === 0
															? isDark
																? theme.colors.blue[4]
																: theme.colors.blue[8]
															: isDark
															? theme.colors.accentRed[4]
															: theme.colors.accentRed[5];

													return (
														<BehaviorSystemElement
															key={systemIx}
															systemIx={systemIx}
															color={color}
															inputData={inputData}
															systemId={system.system_id}
															agentId={system.agent_id}
															scenarioSettings={scenarioSettings}
															behaviorSettings={scenarioSettings[behaviorSettingsProperty]}
															isCommander={echelon.type !== 1}
															echelon={echelon}
															setSetting={(value, duplicate?, changeTopLevelName?) =>
																setEchelonSetting(value, behaviorSettingsProperty)
															}
															updateSelectedSymbolId={updateSelectedSymbolId}
														/>
													);
												})}
											</SystemsAccordion>
										</>
									) : null}
								</AccordionPanel>
							</AccordionItem>
						);
				  })
				: null}
		</Accordion>
	);
};

export default BehaviorNestedAccordions;

const useStyles = createStyles((theme) => ({
	accordionContainer: {
		width: "100%",
		borderBottom: "none",

		"& > :not(:first-of-type)": {
			marginTop: "1rem",
		},
	},

	topLevelAccordionItem: {
		backgroundColor: theme.colors.appMainColor[5],
	},

	commanderText: {
		textAlign: "center",
		marginTop: "1rem",
		color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.dark[3],

		"& > hr": {
			margin: "0.5rem 0",
			backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[5],
			border: "none",
			padding: "1px",
		},
	},
}));

const SystemsAccordion = styled(Accordion)``;

const AccordionItem = styled(Accordion.Item)`
	border: ${({ theme }) => `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[5]}`};
`;

const AccordionPanel = styled(Accordion.Panel)`
	& .mantine-Accordion-content {
		padding: 0 1rem 1rem 1rem;
	}
`;
