import { useCallback, useEffect } from "react";
import * as Cesium from "cesium";
import Symbol from "milsymbol";
import { Echelon, ParentEchelon } from "../../../../../types/filters";
import { getCentroid } from "../functions/getCentroid";
import { checkCartesianIfZero } from "../functions/checkCartesianIfZero";

const ECHELON_TYPES: Record<number, string> = {
	1: "SFGPU------D", // 'Platoon',
	2: "SFGPU------E", // 'Company',
	3: "SFGPU------F", // 'Battalion',
	4: "SFGPU------H", // 'Brigade',
};

interface IEchelonsLayer {
	viewer: Cesium.Viewer;
	forcePackage: ParentEchelon;
	side: 0 | 1;
	visible: boolean;
}

export const EchelonsLayer: React.FC<IEchelonsLayer> = ({ viewer, forcePackage, side, visible }) => {
	const replaceAt = (input: string, index: number, replacement: string) => {
		return input.substring(0, index) + replacement + input.substring(index + replacement.length);
	};

	const createEchelonDescription = (properties: Record<string, string | number>) => {
		let html = "";

		for (const key of Object.keys(properties)) {
			if (properties.hasOwnProperty(key)) {
				const value = properties[key];
				if (value !== undefined) {
					html += `<tr><th>${key}</th><td>${value}</td></tr>`;
				}
			}
		}

		if (html.length > 0) {
			html = `<style>
                        .cesium-infoBox-defaultTable tr:nth-child(even) {
                            background-color: rgba(25, 25, 25, 0.85) !important;
                        }
                        .cesium-infoBox-defaultTable tr:nth-child(odd) {
                            background-color: rgba(60, 60, 60, 0.8) !important;
                        }
                        .cesium-infoBox-description, cesium-infoBox-description * {
                            padding: 0;
                            margin: 0;
                        }
                        .cesium-infoBox-defaultTable{
                            border-spacing: 0 !important;
                        }
                        </style>
                        <table class="cesium-infoBox-defaultTable"><tbody>${html}</tbody></table>`;
		}

		return html;
	};

	const drawEchelons = useCallback(
		(echelon: Echelon, side: 0 | 1, parentEchelon: Echelon | null) => {
			if (echelon.systems) {
				const exists = viewer.entities.getById(`echelon${echelon.id}`);
				if (exists) {
					viewer.entities.remove(exists);
				}

				// draw echelon symbol and lines connected to systems
				viewer.entities.add({
					id: `echelon${echelon.id}`,
					position: new Cesium.ConstantPositionProperty(
						(() => {
							let systemsPositions = echelon.systems
								.map((system) => {
									const entity = viewer.entities.getById("symbol" + system.agent_id);
									if (entity && entity.position) {
										return entity.position.getValue(new Cesium.JulianDate(1, 1));
									}
									return null;
								})
								.filter((pos) => pos);

							systemsPositions.filter((pos) => pos);
							const newPosition = getCentroid(systemsPositions as Cesium.Cartesian3[]);

							return new Cesium.Cartesian3(newPosition.x, newPosition.y, newPosition.z);
						})()
					),
					billboard: {
						image: new Symbol.Symbol(replaceAt(ECHELON_TYPES[echelon.type], 1, side === 0 ? "F" : "H"), {
							size: 36,
							fillOpacity: 0.9,
							colorMode: "Light",
							iconColor: {
								Hostile: "white",
								Friend: "white",
								Neutral: "white",
								Unknown: "white",
								Civilian: "white",
							},
						}).asCanvas(),
						eyeOffset: new Cesium.Cartesian3(0, 0, -201),
						show: echelon.systems.filter((system) => viewer.entities.getById("symbol" + system.agent_id)).length > 0,
					},
					properties: {
						echelon_id: echelon.id,
						side: side,
					},
					show: true,
					description: createEchelonDescription({
						echelon_id: echelon.id,
						side: side,
					}),
				});

				// draw line to parentEchelon
				if (parentEchelon) {
					const exists = viewer.entities.getById(`parentEchelon-${parentEchelon.id}-${echelon.id}`);
					if (exists) {
						viewer.entities.remove(exists);
					}

					viewer.entities.add({
						id: `parentEchelon-${parentEchelon.id}-${echelon.id}`,
						polyline: {
							positions: new Cesium.CallbackProperty((time) => {
								const parentEntity = viewer.entities.getById(`echelon${parentEchelon.id}`);
								const currentEntity = viewer.entities.getById(`echelon${echelon.id}`);

								if (parentEntity && currentEntity && parentEntity.position && currentEntity.position) {
									if (
										checkCartesianIfZero(parentEntity.position.getValue(time)) ||
										checkCartesianIfZero(currentEntity.position.getValue(time))
									) {
										return [];
									} else {
										return [
											((parentEntity as Cesium.Entity).position as Cesium.ConstantPositionProperty).getValue(time),
											((currentEntity as Cesium.Entity).position as Cesium.ConstantPositionProperty).getValue(time),
										];
									}
								}
								return [];
							}, false),
							material: new Cesium.PolylineArrowMaterialProperty(Cesium.Color.YELLOW),
							width: 20,
							show: true,
						},
					});
				}

				// draw lines to systems
				echelon.systems.forEach((system) => {
					const entity = viewer.entities.getById("symbol" + system.agent_id);

					if (entity) {
						const exists = viewer.entities.getById(`echelon${echelon.id}-${system.agent_id}`);
						if (exists) {
							viewer.entities.remove(exists);
						}

						viewer.entities.add({
							id: `echelon${echelon.id}-${system.agent_id}`,
							polyline: {
								positions: (() => {
									const echelonEntity = viewer.entities.getById(`echelon${echelon.id}`);
									if (entity && entity.position && echelonEntity && echelonEntity.position) {
										return [
											echelonEntity.position.getValue(new Cesium.JulianDate(1, 1)) as Cesium.Cartesian3,
											entity.position.getValue(new Cesium.JulianDate(1, 1)) as Cesium.Cartesian3,
										];
									} else {
										return [];
									}
								})(),
								material: new Cesium.PolylineDashMaterialProperty({
									color: (() => {
										return entity.properties?.is_commander.getValue(new Cesium.JulianDate(1, 1))
											? Cesium.Color.MAGENTA
											: Cesium.Color.YELLOW;
									})(),
								}),
								width: 2,
								show: true,
							},
						});
					}
				});
			}

			// recursively draw child echelons
			if (echelon.echelons) {
				echelon.echelons.forEach((childEchelon) => {
					drawEchelons(childEchelon, side, echelon);
				});
			}
		},
		[viewer]
	);

	useEffect(() => {
		if (forcePackage && viewer) {
			viewer.entities.suspendEvents();
			if (visible) drawEchelons(forcePackage.echelons[0], side, null);
			else {
				const entitiesToRemove: Cesium.Entity[] = []; // cant remove entity directly in forEach cause it will mutate the collection which we are iterating over
				viewer.entities.values.forEach((entity) => {
					if (entity.id.includes("echelon") || entity.id.includes("parentEchelon")) {
						entitiesToRemove.push(entity);
					}
				});
				entitiesToRemove.forEach((entity, i) => {
					viewer.entities.remove(entity);
				});
			}
			viewer.entities.resumeEvents();
		}
	}, [viewer, forcePackage, visible, side, drawEchelons]);

	return null;
};

export default EchelonsLayer;
