import { Echelon } from "../../../types/filters";

export const findHighestAgentId = (echelons: Echelon[]): number => {
	let highestAgentId = 0;
	echelons.forEach((echelon) => {
		if (echelon.systems) {
			echelon.systems.forEach((system) => {
				if (system.agent_id > highestAgentId) {
					highestAgentId = system.agent_id;
				}
			});
		}
		if (echelon.echelons) {
			const highestAgentIdInEchelon = findHighestAgentId(echelon.echelons);
			if (highestAgentIdInEchelon > highestAgentId) {
				highestAgentId = highestAgentIdInEchelon;
			}
		}
	});
	return highestAgentId;
};
