export const handleOnlyNumberKeyDown = (e: React.KeyboardEvent<HTMLElement>, additionalAllowedKeys: string[] = []) => {
	const allowedEditionKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Control", "Meta", "Shift", ...additionalAllowedKeys];

	if (e.ctrlKey || e.metaKey) {
		return;
	}

	if (!isFinite(parseInt(e.key)) && !allowedEditionKeys.includes(e.key)) {
		e.preventDefault();
	}

	if (e.key === "Enter") {
		e.currentTarget.blur();
	}
};
