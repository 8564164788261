import * as Cesium from "cesium";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import makeRequest from "../../../../../common/makeRequest";
import { BehaviorParameter } from "../../../../../types/filters";
import { createAreas } from "../functions/createAreas";
import { createPolygons } from "../functions/createPolygons";

interface IBaseLayer {
	viewer: Cesium.Viewer;
	geography: string;
	resolution: string;
	side: 0 | 1;
	voronoisDataSource: Cesium.GeoJsonDataSource | null | undefined;
	elementOnWhichInputDepends?: BehaviorParameter;
	setIsDataLoading: (isLoading: boolean) => void;
	setIsBaseLayerLoaded: (isLoaded: boolean) => void;
}

const BaseLayer: React.FC<IBaseLayer> = ({
	viewer,
	geography,
	resolution,
	side,
	voronoisDataSource,
	setIsDataLoading,
	setIsBaseLayerLoaded,
}) => {
	const [areasDataSource, setAreasDataSource] = useState<Cesium.GeoJsonDataSource | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			setIsDataLoading(true);

			const downloadAreas = async () => {
				try {
					const response = await makeRequest(
						`areas/get?geography=${geography.toLowerCase()}&resolution=${resolution.toLowerCase()}`,
						"GET"
					);

					if (response.ok) {
						const data = await response.json();

						if (data["features"].length === 0) {
							toast.error("No areas found for given geography and resolution");
						}

						data.features = data.features.filter((feature: any) => {
							return feature.properties.owner.toLowerCase().includes(side === 1 ? "red" : "blue");
						});

						const areasDataSource = await Cesium.GeoJsonDataSource.load(data);
						setAreasDataSource(areasDataSource);
					} else {
						toast.error("No areas found for given geography and resolution");
					}
				} catch (e) {
					console.log(e);
				} finally {
					return Promise.resolve();
				}
			};

			await downloadAreas();
			setIsDataLoading(false);
		};

		if (viewer && viewer.dataSources && geography && resolution) {
			fetchData();
		}

		// We dont need to update this effect when setIsDataLoading changes as it's always the same function
		// eslint-disable-next-line
	}, [viewer, geography, resolution]);

	useEffect(() => {
		if (areasDataSource && voronoisDataSource) {
			createPolygons(viewer, voronoisDataSource);
			createAreas(viewer, areasDataSource);
			setIsBaseLayerLoaded(true);
		}
		// eslint-disable-next-line
	}, [viewer, voronoisDataSource, areasDataSource]);

	return null;
};

export default BaseLayer;
