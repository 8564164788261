import { Anchor, Breadcrumbs, Flex } from "@mantine/core";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AppInfoContext } from "../contexts/AppInfoContext";
import { useLocation, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import styled from "@emotion/styled/macro";
import { downloadForcePackageSettings } from "../../pages/ForcePackages/downloadForcePackageSettings";

const BreadcrumbsNavigation: React.FC = () => {
	const [anchors, setAnchors] = useState<(JSX.Element | null)[]>([]);
	const { projects, currentPid } = useContext(AppInfoContext);
	const location = useLocation();
	const locations = useMemo(() => new Set(location.pathname.split("/").map((item) => `/${item}`)), [location]);
	const navigate = useNavigate();

	const getTitle = useCallback(
		async (item: string, previousPart: string) => {
			if (item === "") {
				return "Landing";
			}

			if (item === "project" && currentPid) {
				// eslint-disable-next-line eqeqeq
				const projectName = projects?.find((project) => project.id === currentPid)?.name;
				if (currentPid === 0) navigate("/");
				return `Project: ${projectName || currentPid}`;
			}

			if (typeof item === "string" && !isNaN(parseInt(item))) {
				if (previousPart === "force-packages") {
					const forcePackage = await downloadForcePackageSettings(parseInt(item));
					return forcePackage?.fp.name;
				}
			}

			const capitalizedItem = item
				.split("-")
				.map((word) => {
					return word.charAt(0).toUpperCase() + word.slice(1);
				})
				.join(" ");

			return capitalizedItem;
		},
		[navigate, projects, currentPid]
	);

	const setupAnchors = useCallback(async () => {
		const titles = new Set(
			await Promise.all(
				location.pathname.split("/").map(async (item): Promise<any> => {
					const previousPart = location.pathname.split("/")[location.pathname.split("/").indexOf(item) - 1];
					const title = await getTitle(item, previousPart);
					return title;
				})
			)
		);

		const newAnchors = Array.from(locations).map((item, index) => {
			if (index > 1 && Array.from(locations)[index - 1] === "/project") return null;

			let href =
				item !== "/"
					? Array.from(locations)
							.slice(1, index + 1)
							.join("")
					: item;

			if (href === "/project") {
				// Take project id into account
				href = Array.from(locations)
					.slice(1, index + 2)
					.join("");
			}

			return (
				<Anchor href={href} key={index} fw={"normal"}>
					{Array.from(titles)[index]}
				</Anchor>
			);
		});

		setAnchors(newAnchors.filter((anchor) => anchor !== null));
	}, [getTitle, location.pathname, locations]);

	useEffect(() => {
		if (projects) {
			setupAnchors();
		}
	}, [projects, setupAnchors]);

	return anchors.length > 1 ? (
		<BreadcrumbsPanel p={"1rem"}>
			<Breadcrumbs separator="|">{anchors}</Breadcrumbs>
		</BreadcrumbsPanel>
	) : Array.from(locations).length > 1 ? (
		<BreadcrumbsPanel>
			<Preloader customStyles="transform: scale(0.5);"></Preloader>
		</BreadcrumbsPanel>
	) : null;
};

const BreadcrumbsPanel = styled(Flex)`
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0])};
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	overflow-x: auto;
	height: 3rem;
	min-height: 3rem;
	color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.dark[9])};
`;

export default BreadcrumbsNavigation;
