import Popup from "../Popup";
import { NativeSelect } from "@mantine/core";
import { useState } from "react";
import { BaseData, Optional } from "../../../types/filters";
import { ISystemModal, InputWrapper, PopupBody } from "../tables/SettingStaticTable";
import { toast } from "react-toastify";

const EmitterModal = <T extends Optional<BaseData, "id">>({
	inputData,
	settingName,
	modalButtonText,
	tableData,
	mode,
	setSetting,
}: ISystemModal<T>) => {
	const [modalSelectedType, setModalSelectedType] = useState<string>();

	const handleSubmit = () => {
		if (modalSelectedType !== undefined)
			setSetting(settingName as keyof T, [...tableData, parseInt(modalSelectedType)] as T[keyof T]);
		else {
			toast.info("Please select emitter");
			return false;
		}

		return true;
	};

	return inputData.emitters ? (
		<Popup title="" modalButtonText={modalButtonText} handleSubmit={handleSubmit}>
			<PopupBody>
				<InputWrapper>
					<h4>Emitter</h4>
					<NativeSelect
						required
						w={"70%"}
						placeholder="Select emitter"
						data={[
							{ value: "-1", label: "Select emitter" },
							...inputData.emitters.map((emitter, i) => ({
								value: i.toString(),
								label: emitter.info ? emitter.info.name : emitter.id.toString(),
							})),
						]}
						onChange={(e) => {
							e.target.value !== "-1" && inputData.emitters
								? setModalSelectedType(inputData.emitters[e.target.value as unknown as number].id.toString())
								: setModalSelectedType(undefined);
						}}
						defaultChecked={false}
					/>
				</InputWrapper>
			</PopupBody>
		</Popup>
	) : null;
};

export default EmitterModal;
