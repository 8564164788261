const fadeInOut = `
    .show {
        visibility: show;
        opacity: 1;
        transition: opacity .5s ease-in-out;
    }

    .hide {
        visibility: hidden;
        opacity: 0;
        transition: opacity .5s ease-in-out;
    }
`;

export default fadeInOut;
