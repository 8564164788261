// footer component
import React from "react";
import styled from "@emotion/styled/macro";
import { ReactComponent as ScenseiLogo } from "../../assets/scensei.svg";

const Footer: React.FC = () => {
	return (
		<FooterContainer>
			<LogoContainer>
				<FooterText>Powered by </FooterText>
				<ScenseiLogo className="scensei-logo" />
			</LogoContainer>
		</FooterContainer>
	);
};

const FooterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: space-between;
	height: var(--footer-height);
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.light[0])};
	// border-top: 1px solid #e5e5e5;
`;

const FooterText = styled.div`
	font-size: 0.875rem;
	color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])};
	padding-right: 0.5rem;
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	margin-right: 1rem;

	.scensei-logo {
		width: 112px;
		height: 30px;

		& .st0 {
			fill: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])} !important;
		}
	}
`;

export default Footer;
