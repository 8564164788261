import { Echelon } from "../../../types/filters";

export const findHighestEchelonId = (echelons: Echelon[]): number => {
	let highestEchelonId = 1;
	echelons.forEach((echelon) => {
		if (echelon.id > highestEchelonId) {
			highestEchelonId = echelon.id;
		}
		if (echelon.echelons) {
			const highestEchelonIdInEchelon = findHighestEchelonId(echelon.echelons);
			if (highestEchelonIdInEchelon > highestEchelonId) {
				highestEchelonId = highestEchelonIdInEchelon;
			}
		}
	});
	return highestEchelonId;
};
