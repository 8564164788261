import React, { useCallback, useContext, useEffect, useState } from "react";
import makeRequest from "../../../common/makeRequest";
import Preloader from "../../../components/common/Preloader";
import SystemPage from "../../../components/system-page/SystemPage";
import { PlatformData, FiltersData, Optional, ListForcePackageData, Archetype, InputData, PlatformSubtype } from "../../../types/filters";
import { defaultSettings, platformsSettingsTemplate } from "./defaultSettings";
import { toast } from "react-toastify";
import { AppInfoContext } from "../../../components/contexts/AppInfoContext";
import parse from "html-react-parser";

const Platforms: React.FC = () => {
	const [filters, setFilters] = useState<FiltersData>();
	const [inputData, setInputData] = useState<InputData>();
	const { currentPid } = useContext(AppInfoContext);

	const initialize = useCallback(async (pid: number, fp: number | string, side: number | string) => {
		const filters = await downloadFilters(pid, fp, side);
		const input = await downloadInputData();
		setFilters(filters);
		setInputData(input);
		return true;
	}, []);

	const downloadFilters = async (pid: number, fp: number | string, side: number | string) => {
		const response = await makeRequest("platforms/get", "GET", {}, { pid: pid, fp: fp, side: side });
		const json = await response.json();
		return json as FiltersData;
	};

	const downloadSystemSettings = async (systemId: number) => {
		const response = await makeRequest(`platforms/${systemId}/get`, "GET");
		const json = await response.json();
		return json as PlatformData;
	};

	const downloadInputData = async () => {
		const response = await makeRequest("platforms/get_input_data", "GET");
		const json = await response.json();
		return json as InputData;
	};

	const addPlatform = async (settings: Optional<PlatformData, "id">) => {
		const res = await makeRequest("platforms/add", "POST", settings);
		if (res.ok) {
			toast.success("Platform added successfully!");
			const json = await res.json();
			return json.platform as PlatformData;
		}
		toast.error("Error adding platform!");
		return null;
	};

	const deletePlatform = async (settingsId: number) => {
		const res = await makeRequest(`platforms/${settingsId}/delete`, "POST", {});
		if (res.ok) {
			const json = await res.json();
			if (json.message) toast.error(<div>{parse(json.message)}</div>);
			else toast.success("Platform deleted successfully!");
			return json;
		}
		toast.error("Error deleting platform!");
		return null;
	};

	const savePlatform = async (settings: Optional<PlatformData, "id">) => {
		const res = await makeRequest(`platforms/${settings.id}/save`, "POST", settings);
		if (res.ok) {
			toast.success("Platform saved successfully!");
			const json = await res.json();
			return json as PlatformData;
		}
		toast.error("Error saving platform!");
		return null;
	};

	const duplicatePlatform = async (settingsId: number) => {
		const res = await makeRequest(`platforms/${settingsId}/duplicate`, "POST", {});
		if (res.ok) {
			toast.success("Platform duplicated successfully!");
			const json = await res.json();
			return json as PlatformData;
		}
		toast.error("Error duplicating platform!");
		return null;
	};

	useEffect(() => {
		if (inputData && inputData.archetypes && platformsSettingsTemplate) {
			platformsSettingsTemplate.sections["Categories"].mobility = {
				type: "select",
				data: (inputData.archetypes as Archetype[]).map((archetype: Archetype) => {
					return { value: archetype.id.toString(), label: archetype.name || archetype.id.toString() };
				}),
			};
			platformsSettingsTemplate.sections["Categories"].subtype = {
				type: "select",
				data: (inputData.platform_subtypes as { id: number; name: string }[]).map((subtype: PlatformSubtype) => {
					return { value: subtype.id.toString(), label: subtype.name };
				}),
			};
		}
	}, [inputData]);

	useEffect(() => {
		initialize(currentPid, "", "");
	}, [initialize, currentPid]);

	return filters && inputData ? (
		<SystemPage
			forcePackageData={filters.fps as ListForcePackageData[]}
			systemsData={filters.platforms as PlatformData[]}
			inputData={inputData}
			initializeFilters={initialize}
			downloadSystemSettings={downloadSystemSettings}
			systemSettingsTemplate={platformsSettingsTemplate}
			defaultSettings={defaultSettings}
			addSystem={addPlatform}
			saveSystem={savePlatform}
			deleteSystem={deletePlatform}
			duplicateSystem={duplicatePlatform}
		></SystemPage>
	) : (
		<Preloader></Preloader>
	);
};

export default Platforms;
