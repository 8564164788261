import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import makeRequest from "../../common/makeRequest";
import Preloader from "../../components/common/Preloader";
import { AppInfoContext } from "../../components/contexts/AppInfoContext";
import {
	AssessmentData,
	AssessmentScenarioData,
	BaseData,
	FiltersData,
	InputData,
	ListForcePackageData,
	Optional,
	SystemSettingsObjectType,
} from "../../types/filters";
import AssessmentPage from "./assessments-page/AssessmentsPage";
import { scenariosSettingsTemplate, assessmentsSettingsTemplate, defaultScenarioSettings, defaultSettings } from "./defaultSettings";

const Assessments: React.FC = () => {
	const [filters, setFilters] = useState<FiltersData>();
	const [inputData, setInputData] = useState<InputData>();
	const { currentPid } = useContext(AppInfoContext);
	const [scenariosData, setScenariosData] = useState<BaseData[] | undefined>();

	const initialize = useCallback(async () => {
		const downloadInputData = async () => {
			const response = await makeRequest("programming_scenarios/get_input_data", "GET", {}, { pid: currentPid });
			const json = await response.json();
			return json as InputData;
		};

		const filters = await downloadFilters(currentPid);
		const inpData = await downloadInputData();
		setFilters(filters);
		setInputData(inpData);
		return true;
	}, [currentPid]);

	const downloadFilters = async (pid: number) => {
		const response = await makeRequest("assessments/get", "GET", {}, { pid: pid });
		const json = await response.json();
		return json as FiltersData;
	};

	const downloadAssessmentSettings = async (assessmentId: number) => {
		const response = await makeRequest(`assessments/${assessmentId}/get`, "GET");
		if (response.ok) {
			const json = await response.json();
			setScenariosData(json.programming_scenarios);
			return json as AssessmentData;
		}
		return null;
	};

	const downloadScenarioSettings = async (scenarioId: number) => {
		const response = await makeRequest(`programming_scenarios/${scenarioId}/get`, "GET");
		const json = await response.json();
		return json as AssessmentScenarioData;
	};

	const addAssessment = async (settings: Optional<AssessmentData, "id">) => {
		const res = await makeRequest("assessments/add", "POST", settings, { pid: currentPid });
		if (res.ok) {
			toast.success("Assessment added successfully!");
			const json = await res.json();
			return json.assessment as AssessmentData;
		}
		toast.error("Error adding assessment!");
		return null;
	};

	const addScenario = async (settings: Optional<AssessmentData, "id">, silent?: boolean) => {
		const { id, ...formattedSettings } = settings;
		const res = await makeRequest(`assessment/${id}/add_programming_scenario`, "POST", formattedSettings, {
			pid: currentPid,
		});
		if (res.ok) {
			!silent && toast.success("Scenario added successfully!");
			const json = await res.json();
			return json.ps as AssessmentScenarioData;
		}
		toast.error("Error adding Scenario!");
		return null;
	};

	const deleteAssessment = async (assessmentId: number) => {
		const res = await makeRequest(`assessments/${assessmentId}/delete`, "POST", { pid: currentPid });
		if (res.ok) {
			toast.success("Assessment deleted successfully!");
			const json = await res.json();
			return json;
		}
		toast.error("Error deleting assessment!");
		return null;
	};

	const deleteScenario = async (scenarioId: number) => {
		const res = await makeRequest(`programming_scenarios/${scenarioId}/delete`, "POST", { pid: currentPid });
		if (res.ok) {
			toast.success("Scenario deleted successfully!");
			const json = await res.json();
			return json;
		}
		toast.error("Error deleting Scenario!");
		return null;
	};

	const saveAssessment = async (settings: Optional<AssessmentData, "id">) => {
		const res = await makeRequest(`assessments/${settings.id}/save`, "POST", settings);
		if (res.ok) {
			toast.success("Assessment saved successfully!");
			const json = await res.json();
			return json as AssessmentData;
		}
		toast.error("Error saving assessment!");
		return null;
	};

	const saveScenario = async (settings: Optional<AssessmentScenarioData, "id">) => {
		const { id, ...formattedSettings } = settings;
		const res = await makeRequest(`programming_scenarios/${id}/save`, "POST", formattedSettings, { pid: currentPid });
		if (res.ok) {
			toast.success("Scenario saved successfully!");
			const json = await res.json();
			return json as AssessmentScenarioData;
		}
		toast.error("Error saving Scenario!");
		return null;
	};

	const duplicateAssessment = async (assessmentId: number) => {
		const duplicateAllScenarios = async (scenariosData: BaseData[], assessmentId: number) => {
			const resList = [];
			for (let scenario of scenariosData) {
				const scenarioSettings = await downloadScenarioSettings(scenario.id);
				const json = await addScenario({ ...scenarioSettings, id: assessmentId }, true);
				if (scenarioSettings && json) resList.push(json);
			}
			return resList;
		};

		const res = await makeRequest(`assessments/${assessmentId}/duplicate`, "POST", {});
		if (res.ok) {
			const json = await res.json();
			const newAssessmentId = json.id;
			const toastId = toast.info("Assessment is being duplicated...", { autoClose: false });
			let list = undefined;

			if (!scenariosData) {
				const assessmentSettings = await downloadAssessmentSettings(assessmentId);
				if (assessmentSettings)
					list = assessmentSettings.programming_scenarios && (await duplicateAllScenarios(assessmentSettings.programming_scenarios, newAssessmentId));
			} else {
				list = await duplicateAllScenarios(scenariosData, newAssessmentId);
			}

			if (list) {
				toast.dismiss(toastId);
				toast.success("Assessment duplicated successfully!");
				return json as AssessmentData;
			} else {
				toast.dismiss(toastId);
				toast.error("Error duplicating assessment!");
				return null;
			}
		}
		toast.error("Error duplicating assessment!");
		return null;
	};

	const duplicateScenario = async (scenarioId: number) => {
		const res = await makeRequest(`programming_scenarios/${scenarioId}/duplicate`, "POST", {}, { pid: currentPid });
		if (res.ok) {
			toast.success("Scenario duplicated successfully!");
			const json = await res.json();
			return json as AssessmentData;
		}
		toast.error("Error duplicating scenario!");
		return null;
	};

	useEffect(() => {
		if (inputData && inputData.force_packages && inputData.geographies && scenariosSettingsTemplate) {
			scenariosSettingsTemplate.sections["Configuration"].blue_force_package = {
				...(scenariosSettingsTemplate.sections["Configuration"].blue_force_package as SystemSettingsObjectType),
				type: "select",
				data: (inputData.force_packages as ListForcePackageData[]).map((data: ListForcePackageData) => {
					return { value: data.id.toString(), label: data.name || data.id.toString() };
				}),
			};
			scenariosSettingsTemplate.sections["Configuration"].red_force_package = {
				...(scenariosSettingsTemplate.sections["Configuration"].red_force_package as SystemSettingsObjectType),
				type: "select",
				data: (inputData.force_packages as ListForcePackageData[]).map((data: ListForcePackageData) => {
					return { value: data.id.toString(), label: data.name || data.id.toString() };
				}),
			};
			scenariosSettingsTemplate.sections["Configuration"].geography = {
				type: "select",
				data: (inputData.geographies as string[]).map((data: string) => {
					return { value: data, label: data };
				}),
			};

			if (inputData.force_packages.length > 1) {
				defaultScenarioSettings.blue_force_package = (inputData.force_packages as ListForcePackageData[])[0].id;
				defaultScenarioSettings.red_force_package = (inputData.force_packages as ListForcePackageData[])[1].id;
				defaultScenarioSettings.geography = (inputData.geographies as string[])[0];
			}
		}
	}, [inputData]);

	useEffect(() => {
		initialize();
	}, [initialize]);

	return filters && inputData ? (
		<AssessmentPage
			forcePackageData={filters.fps as ListForcePackageData[]}
			assessmentsData={filters.assessments as AssessmentData[]}
			inputData={inputData}
			initializeFilters={initialize}
			downloadAssessmentSettings={downloadAssessmentSettings}
			downloadScenarioSettings={downloadScenarioSettings}
			assessmentSettingsTemplate={assessmentsSettingsTemplate}
			scenarioSettingsTemplate={scenariosSettingsTemplate}
			defaultSettings={defaultSettings}
			addAssessment={addAssessment}
			addScenario={addScenario}
			saveAssessment={saveAssessment}
			saveScenario={saveScenario}
			deleteAssessment={deleteAssessment}
			deleteScenario={deleteScenario}
			duplicateAssessment={duplicateAssessment}
			duplicateScenario={duplicateScenario}
		></AssessmentPage>
	) : (
		<Preloader></Preloader>
	);
};

export default Assessments;
