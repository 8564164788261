import { ActionIcon, Button, Flex, TextInput, useMantineTheme } from "@mantine/core";
import styled from "@emotion/styled/macro";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { handleOnlyNumberKeyDown } from "../../../common/handleOnlyNumberKeyDown";
import { DataTable } from "mantine-datatable";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Plot from "react-plotly.js";
import { IconTrash } from "@tabler/icons-react";

interface IProbabilityTable {
	value: { range: number; probability: number }[];
	maxRange: number; // in km
	editable?: boolean;
	onBlur: (value: { range: number; probability: number }[]) => void;
}

const ProbabilityTable: React.FC<IProbabilityTable> = ({ value, editable, maxRange, onBlur }) => {
	const theme = useMantineTheme();
	const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();
	const [records, setRecords] = useState<{ id: string; range: number; probability: number }[]>(
		value.map((row) => ({ id: crypto.randomUUID(), ...row }))
	);

	const addRow = () => {
		const newRecords = [...records, { id: crypto.randomUUID(), range: -1, probability: 0 }];
		setRecords(newRecords);
		onBlur(newRecords.map((row) => ({ range: row.range, probability: row.probability })));
	};

	const modifyRow = (index: number, range: number, probability: number) => {
		// records have additionally id field, cause of the DataTable component
		const newRecords = records
			.map((row, i) => {
				if (i === index) {
					return {
						id: records[i].id,
						range: range,
						probability: probability,
					};
				}
				return row;
			})
			.sort((a, b) => a.range - b.range);

		setRecords(newRecords);
		onBlur(newRecords.map((row) => ({ range: row.range, probability: row.probability })));
	};

	const handleRangeModification = (
		e: React.FocusEvent<HTMLInputElement, Element>,
		ix: number,
		item: {
			id: string;
			range: number;
			probability: number;
		}
	) => {
		if (e.target.value === "") {
			return;
		}

		const range = parseFloat(e.target.value);

		// move to some validate function
		if (records.some((row) => row.range === range && row.id !== item.id)) {
			e.target.value = item.range.toString();
			toast.error("Range value already exists.");
		} else if (range < 0 || range > maxRange) {
			e.target.value = item.range.toString();
			toast.error("Range value out of allowed bounds.");
		} else {
			e.target.value = e.target.value.replace(",", ".");
			modifyRow(ix, parseFloat(e.target.value), item.probability);
		}
	};

	const handleProbabilityModification = (
		e: React.FocusEvent<HTMLInputElement, Element>,
		ix: number,
		item: {
			id: string;
			range: number;
			probability: number;
		}
	) => {
		const probability = parseFloat(e.target.value);

		if (probability < 0 || probability > 1) {
			e.target.value = item.probability.toString();
			toast.error("Invalid probability value");
			return;
		} else {
			e.target.value = e.target.value.replace(",", ".");
			modifyRow(ix, item.range, parseFloat(e.target.value));
		}
	};

	const cellSx = useMemo(() => {
		return {
			"& .mantine-Input-wrapper": {
				backgroundColor: "transparent",
			},
			"& input": {
				lineHeight: "normal",
				height: "auto",
				minHeight: "auto",
				backgroundColor: "transparent !important",
			},
		};
	}, []);

	return (
		<TableContainer>
			<Plot
				style={{ backgroundColor: "transparent", marginBottom: "1rem" }}
				data={[
					{
						x: records.filter((record) => record.range !== -1).map((row) => row.range as number),
						y: records.filter((record) => record.range !== -1).map((row) => (row.probability as number) * 100),
						type: "scatter",
						marker: { color: theme.colors.appMainColor[5] },
					},
				]}
				layout={{
					paper_bgcolor: "transparent",
					plot_bgcolor: "transparent",
					font: {
						color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3],
					},
					margin: {
						l: 50,
						r: 0,
						b: 80,
						t: 20,
						pad: 4,
					},
					xaxis: {
						showgrid: false,
						title: {
							text: "Range / km",
						},
					},
					yaxis: {
						showgrid: false,
						title: {
							text: "Detection probability / %",
						},
					},
					autosize: true,
					modebar: { bgcolor: "transparent" },
				}}
				config={{ displayModeBar: false }}
			></Plot>

			<DataTable
				style={{ width: "100%" }}
				sx={{
					"& tr": { backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[1] },
				}}
				withBorder
				borderRadius="sm"
				withColumnBorders
				striped
				emptyState={<></>}
				columns={[
					{
						accessor: "range",
						title: "Range (km)",
						width: "48%",
						titleStyle: { color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3] },

						render: (item, ix) => {
							return (
								<CellInput
									key={ix}
									disabled={!editable}
									placeholder={"Enter range"}
									variant="unstyled"
									sx={cellSx}
									defaultValue={item.range === -1 ? "" : item.range}
									onBlur={(e) => handleRangeModification(e, ix, item)}
									onKeyDown={(e) => handleOnlyNumberKeyDown(e, [".", ","])}
								></CellInput>
							);
						},
					},
					{
						accessor: "probability",
						title: "Probability (0 - 1)",
						width: "48%",
						titleStyle: { color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3] },

						render: (item, ix) => {
							return (
								<CellInput
									key={ix}
									disabled={!editable}
									placeholder={"Enter probability"}
									variant="unstyled"
									sx={cellSx}
									defaultValue={item.probability}
									onBlur={(e) => handleProbabilityModification(e, ix, item)}
									onKeyDown={(e) => handleOnlyNumberKeyDown(e, [".", ","])}
								></CellInput>
							);
						},
					},
					{
						accessor: "actions",
						title: "Actions",
						titleStyle: { color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3] },
						cellsStyle: { display: "flex", justifyContent: "center" },

						render: (item, ix) => {
							return (
								<ActionIcon
									size="sm"
									variant="subtle"
									color="red"
									onClick={() => {
										const newRecords = records.filter((row, i) => i !== ix);
										setRecords(newRecords);
										onBlur(newRecords.map((row) => ({ range: row.range, probability: row.probability })));
									}}
								>
									<IconTrash size={16} />
								</ActionIcon>
							);
						},
					},
				]}
				records={records}
				bodyRef={bodyRef}
			/>

			{editable ? (
				<InputsContainer>
					<Button ml={".5rem"} onClick={() => addRow()}>
						Add record
					</Button>
				</InputsContainer>
			) : null}
		</TableContainer>
	);
};

const TableContainer = styled(Flex)`
	flex-direction: column;
	width: 100%;
`;

const CellInput = styled(TextInput)`
	background-color: transparent;

	& input {
		color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])} !important;
	}
`;

const InputsContainer = styled(Flex)`
	flex-direction: row;
	width: 100%;
	justify-content: center;
	margin: 1rem 0;
`;

export default ProbabilityTable;
