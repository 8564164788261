import { Echelon } from "../../../types/filters";

export const updateEchelonIds = (echelon: Echelon, highestEchelonId: number): [Echelon, number] => {
	echelon.id = highestEchelonId + 1;
	highestEchelonId++;
	if (echelon.echelons) {
		for (let i = 0; i < echelon.echelons.length; i++) {
			let [newEchelon, newHighestId] = updateEchelonIds(echelon.echelons[i], highestEchelonId);
			echelon.echelons[i] = newEchelon;
			highestEchelonId = newHighestId;
		}
	}
	return [echelon, highestEchelonId];
};
