import React, { useEffect, useState } from "react";
import { ActionIcon, Button, createStyles, useMantineColorScheme } from "@mantine/core";
import styled from "@emotion/styled/macro";
import { IconSun, IconMoonStars } from "@tabler/icons-react";
import { ReactComponent as OptimaLogo } from "../../assets/optima.svg";
import { useNavigate } from "react-router-dom";
import { getAPIEndpoint } from "../../common/getAPIEndpoint";
import parse from "html-react-parser";

const Header: React.FC = () => {
	const { classes } = useStyles();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const dark = colorScheme === "dark";
	const navigate = useNavigate();
	const [logo, setLogo] = useState<string>();

	useEffect(() => {
		getLogo();
	}, []);

	const getLogo = async () => {
		const response = await fetch(`${getAPIEndpoint()}site_media/logo.svg`);
		const logo = await response.text();
		setLogo(logo);
	};

	return (
		<HeaderContainer>
			<LogoContainer>
				{logo ? (
					<div className="main-logo" onClick={() => navigate("/")}>
						{parse(logo)}
					</div>
				) : null}
				<OptimaLogo className="optima-logo" onClick={() => navigate("/")} />
			</LogoContainer>
			<ActionIcon mr={"1rem"} variant="outline" color={dark ? "yellow" : "blue"} onClick={() => toggleColorScheme()} title="Toggle color scheme">
				{dark ? <IconSun size="1.1rem" /> : <IconMoonStars size="1.1rem" />}
			</ActionIcon>
			<Button
				className={classes.logoutBtn}
				onClick={() => {
					localStorage.removeItem("token");
					window.location.reload();
				}}
			>
				Logout
			</Button>
		</HeaderContainer>
	);
};

const useStyles = createStyles((theme) => ({
	logoutBtn: {
		backgroundColor: "#9a9a9a",

		"&:hover": {
			backgroundColor: theme.colors.dark[3],
		},
	},
}));

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1rem;
	height: var(--header-height));
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark : theme.colors.light)};
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
	width: 100%;
	position: sticky;
	top: 0;
	max-width: 100vw;
	transition: background-color 0.3s ease-in-out;
	z-index: 2;

	@media (max-width: 768px) {
		height: var(--mobile-header-height);
	}
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-right: 1rem;

	.main-logo {
		width: 386px;
		height: 60px;
		display: flex;
		align-items: center;

		&:hover {
			cursor: pointer;
		}

		& * {
			fill: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])};
		}
	}

	.optima-logo {
		width: 120px;
		height: 60px;
		margin-bottom: 0.25rem;

		&:hover {
			cursor: pointer;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}

	@media (max-width: 768px) {
		.main-logo {
			width: 200px;
			height: 31px;
		}
	}
`;

export default Header;
